import React from "react";
import "../assets/style/style.css";

const Faqpage = () => {
  return (
    <>
      <div className="info-page">
        <div className="content-container">
          <div className="title-container">
            <h1 className="title">FAQ's</h1>
            <span className="divider" style={{ marginBottom: "5rem" }} />
            <ul
              className="tabs-ul nav nav-tabs border-0"
              id="myTab"
              role="tablist"
            >
              <li
                class="nav-item active"
                data-bs-toggle="tab"
                data-bs-target="#general"
                type="button"
                role="tab"
                aria-selected="true"
              >
                General
              </li>
              <li
                class="nav-item"
                data-bs-toggle="tab"
                data-bs-target="#buy"
                type="button"
                role="tab"
                aria-selected="false"
              >
                Buy
              </li>
              <li
                class="nav-item"
                data-bs-toggle="tab"
                data-bs-target="#lease"
                type="button"
                role="tab"
                aria-selected="false"
              >
                Lease
              </li>
              <li
                class="nav-item"
                data-bs-toggle="tab"
                data-bs-target="#sell"
                type="button"
                role="tab"
                aria-selected="false"
              >
                Sell
              </li>
              <li
                class="nav-item"
                data-bs-toggle="tab"
                data-bs-target="#sip"
                type="button"
                role="tab"
                aria-selected="false"
              >
                SIP
              </li>
              <li
                class="nav-item"
                data-bs-toggle="tab"
                data-bs-target="#delivery"
                type="button"
                role="tab"
                aria-selected="false"
              >
                Delivery
              </li>
            </ul>
          </div>

          <div className="information-container">
            <div className="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="general">
                <ul className="general">
                  {/* 1st */}
                  <li>
                    <h2 className="subheading">
                      What is the eligibility criteria for MkGold?
                    </h2>
                    <p>
                      Any resident Indian with a valid PAN Card/Form 60/ Aadhar
                      Card and a Bank Account in their name can enroll for
                      MkGold.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Do you charge for storage of my gold?
                    </h2>
                    <p>
                      MkGold offers free storage for up to 5 years, after
                      which we reserve the right to charge a yearly nominal fee
                      for the storage of your gold, which would be in the range
                      of around 0.3 - 0.4% (per annum) of your gold balance.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Is it mandatory to upload my PAN card?
                    </h2>
                    <p>
                      It is not mandatory to upload your PAN card, however, you
                      may be prompted to do so if you wish to purchase gold that
                      is worth more than ₹ 500.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">Why is KYC needed?</h2>
                    <p>
                      The safety and security of your gold is always our highest
                      priority, and we are continuously working to improve these
                      aspects. We have upgraded the MkGold experience to
                      prevent the misuse of your account or unauthorized access
                      to your gold. This will ensure a much safer experience for
                      you, while also allowing you to buy or sell more gold than
                      before.{" "}
                    </p>
                  </li>
                  {/* 2nd */}
                  <li>
                    <h2 className="subheading">
                      What is the Purity of Gold offered under MkGold?
                    </h2>
                    <p>
                      MkGold offers 24 Karat Gold of 9999 purity (99.99%
                      pure).
                    </p>
                  </li>
                  {/* 3rd */}
                  <li>
                    <h2 className="subheading">
                      What are the various tenures available under MkGold?
                    </h2>
                    <p>
                      Customer is not obligated to make any fixed or periodic
                      payments unless enrolled in a Gold Savings Plan.
                    </p>
                  </li>
                  {/* 4th */}
                  <li>
                    <h2 className="subheading">
                      What is the minimum &amp; maximum amount needed to buy
                      gold on MkGold?
                    </h2>
                    <p>
                      The minimum amount of purchase is{" "}
                      <i className="fa fa-inr" />
                      10. There is no limit on the maximum amount as long as KYC
                      requirements are complied with. In case of Gold Savings
                      Plan, a customer has to invest in at least{" "}
                      <i className="fa fa-inr" />
                      100 worth of gold.
                    </p>
                  </li>
                  {/* 5th */}
                  <li>
                    <h2 className="subheading">
                      How can I get my holding statement?
                    </h2>
                    <p>
                      An online statement will be available at all times which
                      you can view online by clicking on Transaction History
                      link. A quarterly balance statement will also be sent to
                      customers through email.
                    </p>
                  </li>
                  {/*6th */}
                  <li>
                    <h2 className="subheading">
                      Can I have a joint account for this plan?
                    </h2>
                    <p>No, you cannot have a joint account for MkGold.</p>
                  </li>
                  {/*7th */}
                  <li>
                    <h2 className="subheading">
                      Is nomination mandatory for MkGold?
                    </h2>
                    <p>
                      Nomination norms will be followed as per the distributor’s
                      standards for their product.
                    </p>
                  </li>
                  {/*8th */}
                  <li>
                    <h2 className="subheading">
                      At what price is gold sold/ bought from customers?
                    </h2>
                    <p>
                      The Company will publish a live buy-sell quote for
                      customers which will be derived from the wholesale bullion
                      market plus a commission to cover operating costs. The
                      actual purchase price paid by the customer will always be
                      transparently published so the customer has a chance to
                      compare with all other sources before entering into a
                      transaction.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Why is the Buy Price different from the Sell Price?
                    </h2>
                    <p>
                      MkGold buys international grade bullion from
                      institutional suppliers and sells this to consumers. When
                      customers sell gold back to us, we sell it back to
                      institutional participants in the wholesale bullion
                      market, where the buyback rate is lower than the selling
                      rate – this is referred to as the buy-sell spread. <br />
                      In addition to the buy-sell spread in the commercial
                      bullion market, GST is added to the buy price, but it is
                      not levied on any sell transactions. Further, there are
                      charges incurred on the payment gateway, trustee,
                      insurance and custodian fees which also contribute to the
                      difference in buy and sell price.
                    </p>
                  </li>
                  {/*9th */}
                  <li>
                    <h2 className="subheading">
                      Is the gold associated with my accumulations kept in safe
                      custody?
                    </h2>
                    <p>
                      Yes, the gold associated with the accumulations credited
                      in your account is kept in safe custody with a Custodian
                      in accordance with the terms of the offer mentioned in the
                      product brochure. Only reputed firms such as Sequel
                      Logistics and Brinks will be used as a custodian.
                    </p>
                  </li>
                  {/*10th */}
                  <li>
                    <h2 className="subheading">
                      Who will insure the physical vault?
                    </h2>
                    <p>
                      MkGold has partnered with the global market leader in
                      secure logistics and vault services- Brink’s, to store
                      your gold. As part of their duty, the secure storage
                      provider takes out a comprehensive insurance policy, which
                      includes the cover on your gold. Over and above that,
                      MkGold has insurance cover for the period where gold is
                      in transit, when the gold leaves the vault. We review our
                      risk &amp; insurance policies regularly, to ensure that we
                      are covered for additional or specific risks.
                    </p>
                  </li>
                  {/*11th */}
                  <li>
                    <h2 className="subheading">
                      Who will manage consumer complaints?
                    </h2>
                    <p>
                      Distributors will provide the first level of customer
                      support. The Company will be available to satisfy any
                      issues/ questions that may be escalated. You may write to
                      us at{" "}
                      <a href="mailto:care@MkGold.in">care@MkGold.in</a> or
                      call our Customer Service team at{" "}
                      <a href="tel:8881000800">8881000800</a>.
                    </p>
                  </li>
                  {/*12th */}
                  <li>
                    <h2 className="subheading">
                      Is there any regulator or Grievance redressal mechanism?
                    </h2>
                    <p>
                      MkGold is neither a Collective Investment Scheme nor a
                      Deposit. Therefore we do not fall under the purview of
                      SEBI or RBI. However, to ensure that the customer's
                      interest is always prioritized, a Security Trustee has
                      been appointed for MkGold. For any grievances the
                      customer can approach the Security Trustee.
                    </p>
                  </li>
                  {/*13th */}
                  {/* <li>
      <h2 class="subheading">What is the role of the “Security Trustee”?</h2>
      <p>The Security Trustee is there to protect your interests, by acting on your behalf when it comes to matters concerning your gold. This includes periodic checks of the vault to ensure that there is always more than adequate inventory of gold and providing confirmations each time the gold leaves the vault, including for deliveries.</p>
    </li> */}
                  {/*14th */}
                  <li>
                    <h2 className="subheading">
                      What will happen to the gold associated with accumulations
                      in my account, in the unlikely event of the Company going
                      into liquidation?
                    </h2>
                    <p>
                      The gold associated with accumulations in your account is
                      separate from the other assets of the Company. An
                      independent trustee appointed by the Company will act for
                      and on behalf of the customers. The gold purchased by the
                      Company associated with the accumulation in customer
                      accounts is transferred to the Custodian appointed by the
                      Company for the product. When the customer asks for
                      delivery, the requisite quantity of gold will be removed
                      from the Custodian and delivered to the customer through a
                      reputed courier service. Since the Security Trustee has a
                      charge over the gold over other commercial creditors till
                      the time of fulfillment and the gold is separate from the
                      other assets of the Company, any unlikely adverse event
                      happening to Company should not affect the gold associated
                      with the accumulation in your account. Further, the title
                      of the gold clearly rests with the customer and the
                      physical gold stored with the Custodian on account of
                      MkGold customers is not an asset of the Company in any
                      way.
                    </p>
                  </li>
                  {/*15th */}
                  <li>
                    <h2 className="subheading">
                      Which are the agencies which certify Gold in India?
                    </h2>
                    <p>
                      The Government of India has identified the Bureau of
                      Indian Standard as the sole agency in India to operate the
                      BIS Hallmarking Scheme. The BIS Hallmarking Scheme has
                      been aligned with International criteria on hallmarking
                      (Vienna Convention 1972). A Hallmark, consists of five
                      components i.e. BIS Mark, the Fineness number
                      (corresponding to given carratage), Assaying and
                      Hallmarking Centre's Mark, Jeweller's identification Mark
                      and year of Marking denoted by a code letter and decided
                      by BIS (e.g. code letter `A' was approved by BIS for year
                      2000, `B' being used for the year 2001 and `C' for 2002
                      and 'J' for 2008). The marking is done either using
                      punches or laser marking machine. Some manufacturers also
                      issue Assay Certification which can be obtained through
                      Assay Offices. Assay offices are institutions set up to
                      assay (test the purity of) precious metals, in order to
                      protect consumers. All coins and jewellery offered
                      MkGold are assay certified.
                    </p>
                  </li>
                  {/*16th */}
                  <li>
                    <h2 className="subheading">
                      Is there any lock-in period for this plan?
                    </h2>
                    <p>
                      No, there is no lock-in period, for security reasons, you
                      need to verify your Aadhar details for selling gold if you
                      intend to sell it within the 3 to 7 days of purchase date.
                      When it comes to Gold Savings Plans, you can decide the
                      period and frequency you’d like to opt for.
                    </p>
                  </li>
                  {/*17th */}
                  <li>
                    <h2 className="subheading">
                      What is the benchmark for the Price of MkGold? Can MCX
                      be taken as a benchmark?
                    </h2>
                    <p>
                      There is no benchmark for comparing the price of MkGold.
                      MCX cannot be considered as a benchmark, since there are
                      many differences between MkGold and MCX. MCX gives the
                      prices of a futures contract which doesn’t give the landed
                      prices by factoring in cost of logistics, insurance,
                      safekeeping, etc. MCX also does not include pan India
                      delivery, and based on the contract it gives delivery at
                      only a few locations like Mumbai, Ahmedabad, Delhi, etc.
                      MkGold gives delivery across the country, and includes
                      the cost of storage, insurance, trustee fees and gives you
                      the landed, complete price of Gold. Also the gold price
                      quoted in newspapers and on various websites is the
                      wholesale price in multiples of 1kg of gold. MkGold
                      price is valid for fractional grams and therefore is not
                      directly comparable.
                    </p>
                  </li>
                  {/*18th */}
                  <li>
                    <h2 className="subheading">
                      Who is Digital Gold India Private Limited?
                    </h2>
                    <p>
                      Digital Gold India Private Limited (Company) is an
                      institutionally backed company, primarily owned by Invent
                      Advisors Private Limited (Invent). The World Gold Council
                      (India) Private Limited, as well as various other investor
                      funds, hold minority stakes in the Company. MkGold is
                      the retail brand of the Company.
                    </p>
                  </li>
                  {/*19th */}
                  <li>
                    <h2 className="subheading">
                      Will the customer need to show any identity proof at the
                      time of taking the physical gold coins?
                    </h2>
                    <p>
                      No, there is no identity proof required to be shown at the
                      time of delivery.
                    </p>
                  </li>
                  {/*20th */}
                  <li>
                    <h2 className="subheading">
                      Can someone else redeem the physical gold coins on my
                      behalf?
                    </h2>
                    <p>
                      Only in case of death of the applicant, the legal heir
                      will be able to collect the gold coins by showing the
                      proof of identity of along with the original / notarized
                      copy of the death certificate and proof that the person is
                      the legal heir of the applicant.
                    </p>
                  </li>
                  {/*21st */}
                  <li>
                    <h2 className="subheading">
                      What if the customer dies during the tenure of the plan?
                    </h2>
                    <p>
                      The legal heir of the customer can claim the customer’s
                      account by following the process detailed above.
                      Thereafter, they can access the customer’s account,
                      including selling the gold balance or requesting for
                      delivery of the gold.
                    </p>
                  </li>
                  {/*22nd */}
                  <li>
                    <h2 className="subheading">
                      How can one change their registered mobile number on
                      MkGold?
                    </h2>
                    <p>
                      If you would like to change your registered mobile number
                      on MkGold, you will need to submit your KYC details on
                      our customer care email id at{" "}
                      <a href="mailto:care@MkGold.in">care@MkGold.in</a> and
                      we will update you.
                    </p>
                  </li>
                  {/*23rd */}
                  {/*24th */}
                  <li>
                    <h2 className="subheading">
                      What happens when payment is deducted through one’s credit
                      card/debit card but the gold is not credited in one’s
                      MkGold balance?
                    </h2>
                    <p>
                      If the payment has been debited from your debit/credit
                      card and the gold in your MkGold balance has not been
                      credited, you will need to contact our customer care team
                      at <a href="mailto:care@MkGold.in">care@MkGold.in</a>{" "}
                      and we will resolve your issue.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="tab-pane fade" id="buy">
                <ul className="buy active-faq">
                  <li>
                    <h2 className="subheading">
                      How can one buy Gold on MkGold?
                    </h2>
                    <p>
                      Customers can buy any amount of gold as per his needs. The
                      customer has complete freedom to seek physical delivery of
                      gold or bars anytime and on as many occasions as (s)he
                      wants. Since it is not physically possible to deliver a
                      fractional quantity of gold, minimum quantity for which
                      the delivery is proposed to be made has been kept as low
                      as 0.5 gram. The gold on behalf of the customer would be
                      purchased including GST as and when his/her clear funds
                      are available. Gold in grams up to 4 decimal places shall
                      be credited to the customer's account on receipt of funds.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Where can I find the invoice for each order?
                    </h2>
                    <p>
                      An invoice is sent to you by email/ SMS after each
                      transaction. It is also available in the Transaction
                      History’ section on the customer dashboard.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Where is the gold stored when you buy gold?
                    </h2>
                    <p>
                      The gold purchased on behalf of the customer shall be
                      stored with a reputed custodian on a consolidated basis
                      i.e. daily purchase across all the customers. An
                      independent auditor/ trustee will confirm the balances
                      with the custodian each quarter and reconcile the amounts
                      with customer balances.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      I’m trying to complete my KYC, but I’m facing issues, what
                      should I do?
                    </h2>
                    <p>
                      In case you are facing any issues with your KYC documents,
                      please reach out to our support team on{" "}
                      <a href="mailTo:care@MkGold.in">care@MkGold.in</a> or
                      call us on <a href="tel:8881000800">8881000800</a>.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Are there any KYC requirements while buying gold?
                    </h2>
                    <p>
                      Your PAN is required once your cumulative buy transaction
                      value crosses Rs. 500. You will not be able to buy more
                      gold once your cumulative buy transaction value crosses
                      Rs. 500 if you have not provided your PAN. Once you have
                      provided your PAN, you will have to wait 7 days before you
                      can sell any gold that you buy. If you want to reduce this
                      wait, you can opt to complete the Aadhaar KYC verification
                      process. On completing the Aadhaar KYC verification
                      process, you will be able to sell 3 days after buying any
                      gold.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="tab-pane fade" id="lease">
                <ul className="lease">
                  <span className="section-heading">Product Description</span>
                  <h2 className="subheading">What is Gold leasing?</h2>
                  <p className="mb-4">
                    Gold leasing is a method by which you can lease your gold
                    metal (held through digital platforms), to jewellers and
                    ornament manufacturers (Lessee), who will utilise the gold
                    metal as part of their working capital. After a fixed term,
                    the Lessee will return your metal along with a lease rental
                    payment. In concept, this is not very different from you
                    owning an asset like a house or a car and renting it out to
                    another user who pays a rental for using your asset.
                  </p>
                  <p>
                    All parts of the lease are denominated and carried out in
                    grams of physical gold.
                  </p>
                  <h2 className="subheading">
                    How does it work? What are the steps involved?
                  </h2>
                  <p>
                    MkGold facilitates the lease of your physical gold metal
                    to the jeweller of your choice after they have been properly
                    vetted, onboarded and have provided security for the lease.
                    We also coordinate with the jeweller to ensure that the gold
                    metal is physically added back to the vault along with the
                    yield, and accessible to you once again, at the end of the
                    lease tenure.
                  </p>
                  <h2 className="subheading">What are the steps involved?</h2>
                  <p className="mb-4">The steps involved are:</p>
                  <p></p>
                  <ol>
                    <li>Choose the jeweller from the listed options</li>
                    <li>
                      Purchase gold worth 0.5 gm or more to commit to the lease
                      (you can also utilise your existing MkGold balance for
                      this)
                    </li>
                    <li>
                      Complete your PAN verification (in case you haven’t
                      already)
                    </li>
                    <li>
                      Confirm the lease (you will be shown the jeweller, tenure
                      and amount of gold you are committing)
                    </li>
                    <li>That’s it- you’re set!</li>
                  </ol>
                  <p />
                  <h2 className="subheading">
                    How do I participate in a gold lease?
                  </h2>
                  <p>
                    In order to lease your gold, you’ll need to have a MkGold
                    account, and complete the steps above.{" "}
                  </p>
                  <h2 className="subheading">
                    How and when will I earn a yield on my gold?
                  </h2>
                  <p>
                    Once you’ve committed your gold for a specific lease, the
                    yield will be paid out to your MkGold account (in the form
                    of gold grams) every month. At the end of the lease, the
                    last payment to your account will have both the principal
                    gold amount as well as the yield added.{" "}
                  </p>
                  <h2 className="subheading">
                    Who is MkGold? What role does MkGold play in the lease?
                  </h2>
                  <p className="mb-4">
                    MkGold is India’s leading digital gold platform, with
                    partnerships across 60+ platforms, and over 20 million
                    customers. We are India’s largest ecosystem of digital gold
                    services, which includes buying and selling gold at live
                    market rates, gold savings, and converting your digital gold
                    to physical forms like gold jewellery, coins and bars. Read
                    more about us on our{" "}
                    <a href="https://www.MkGold.com/" title="MkGold">
                      here.
                    </a>
                  </p>
                  <p>
                    MkGold facilitates the leasing of your gold savings in
                    terms of onboarding &amp; vetting the jewellers listed for
                    lease options, including the security provided as collateral
                    for the lease. We also physically provide the gold metal to
                    the jeweller for their use. We ensure that yield payments
                    are added to your account every month, and at the end of the
                    lease tenure, add back the committed gold and yield to your
                    account once the jeweller has closed the lease.
                  </p>
                  <h2 className="subheading">
                    Is the yield paid out in rupees or grams of gold? If in
                    gold, how do I convert it to rupees?
                  </h2>
                  <p>
                    The yield is paid out in the form of grams of gold, which
                    accrue to your MkGold account. You can sell this gold at
                    any time as per the prevalent rate, and the funds will be
                    transferred to your bank account.
                  </p>
                  <h2 className="subheading">
                    I have physical gold in my house, can you lease that for me?
                  </h2>
                  <p>
                    At the moment, this product is only available for customers
                    with digital gold purchased from MkGold. We are working on
                    enabling customers to lease out their physical gold lying at
                    home soon!
                  </p>
                  <h2 className="subheading">
                    Who am I leasing out the gold to? Why do they lease my gold?
                  </h2>
                  <p className="mb-4">
                    You are leasing out your gold to India’s community of retail
                    jewellers - i.e. the very same folks from whom your family
                    might have been buying jewellery for generations. But we
                    don’t just allow anyone to list on the MkGold platform -
                    we provide a vetted list of jewellers with whom we have deep
                    relationships, whose credibility and credit worthiness are
                    established.
                  </p>
                  <p>
                    Jewellers need gold metal in the same way that any business
                    needs working capital. For a jeweller, it makes more sense
                    to lease gold metal directly, because otherwise they could
                    be affected by price fluctuations if they utilise a rupee
                    loan to purchase gold and then have to repay in rupees once
                    the gold price goes up.
                  </p>
                  <h2 className="subheading">
                    How do I choose between the lessees (jewellers) where I can
                    lease my gold?
                  </h2>
                  <p>
                    You can choose between lease options based on details such
                    as tenure and yield provided by different jewellers on the
                    listing page.
                  </p>
                  <h2 className="subheading">How is the yield rate decided?</h2>
                  <p>
                    The yield rate is offered by the jeweller and shown to you.
                    MkGold may provide higher yields for returning customers
                    or as a temporary offer from time to time, in addition to
                    the yield offered by the jeweller.
                  </p>
                  <h2 className="subheading">
                    When is the lease process complete? Do I start earning
                    returns from the day I lease my gold?
                  </h2>
                  <p>
                    The lease shall continue during the tenure mentioned in the
                    listing page, unless the jeweller chooses to close the lease
                    at an earlier date, by settling the gold metal. You will
                    earn a yield from the time that your gold is committed on a
                    daily basis, which will accrue on a monthly basis to your
                    digital gold account.
                  </p>
                  <h2 className="subheading">How do I exit a lease?</h2>
                  <p>
                    You will be able to exit a lease at the end of the tenure
                    mentioned on the listing page or at an earlier date, if the
                    jeweller chooses to close out the lease earlier. The
                    typically tenure for a lease is 3-6 months.
                  </p>
                  <h2 className="subheading">
                    Are there any global examples of this product?
                  </h2>
                  <p>
                    This is a completely innovative product, offered for the
                    first time in the world by MkGold. We are proud to offer
                    this product in India first, given the rich tradition of
                    gold as an investment class and one of the largest
                    industries in the country.
                  </p>
                  <h2 className="subheading">
                    Is a gold lease part of the gold monetisation scheme?
                  </h2>
                  <p>
                    No, the gold lease is not part of the gold monetisation
                    scheme.
                  </p>
                  <h2 className="subheading">
                    Can there be circumstances in which the lease gets
                    cancelled?
                  </h2>
                  <p>
                    Yes, a jeweller can close out a lease earlier, in which case
                    your gold, along with the applicable yield calculated up to
                    the date on which the lease is closed, shall be added back
                    to yout account.
                  </p>
                  <h2 className="subheading">
                    Can I invest in more than one lease?
                  </h2>
                  <p>
                    Yes! There is no upper limit on the number of leases in
                    which you can invest.
                  </p>
                  <h2 className="subheading">
                    Can I view the lease agreement before investing in my gold?
                  </h2>
                  <p>
                    You can view the terms and conditions governing the lease{" "}
                    <a
                      href="https://MkGoldstatic.s3.ap-south-1.amazonaws.com/IOG-TnC.pdf"
                      target="_blank"
                    >
                      here
                    </a>
                  </p>
                  <h2 className="subheading">
                    How will I stay updated on the performance of my lease?
                  </h2>
                  <p>
                    You will receive monthly statements from MkGold, updating
                    you on the yield that has been added to your digital gold
                    account.
                  </p>
                  <h2 className="subheading">
                    Can I sell my gold while it is leased out?
                  </h2>
                  <p>
                    No, you will not be able to sell your gold while it has been
                    committed to a lease, as it would have been removed from the
                    vault and physically given to the jeweller.
                  </p>
                  <h2 className="subheading">
                    How can I calculate my returns in rupees rather than gms of
                    gold?
                  </h2>
                  <p>
                    MkGold will send you a statement at the time of the
                    leasing closing, which will contain details on the final
                    yield in grams as well as rupees (calculated at the time of
                    the statement being sent out).
                  </p>
                  <span className="section-heading">
                    Eligibility and Liquidity
                  </span>
                  <h2 className="subheading">
                    What are the minimum and maximum amounts that can be
                    committed?
                  </h2>
                  <p>
                    The minimum commitment required is 0.5 gms and the maximum
                    limit is 20 gms.
                  </p>
                  <h2 className="subheading">
                    Are there any KYC requirements while leasing gold?
                  </h2>
                  <p>
                    Your PAN is required for you to participate in the gold
                    leasing offering. You will not be able to participate in the
                    lease until you provide your PAN and complete your PAN
                    verification process.
                  </p>
                  <h2 className="subheading">
                    Can an NRI participate in a gold lease?
                  </h2>
                  <p>
                    Subject to the use of an Indian mobile number, bank account
                    and completion of PAN and bank verification checks, an NRI
                    can participate in a gold lease.
                  </p>
                  <h2 className="subheading">
                    Can minors participate in a gold lease?
                  </h2>
                  <p>No, minors cannot participate in gold leases.</p>
                  <h2 className="subheading">
                    Can I have a nominee? What happens in the event of my death?
                  </h2>
                  <p>
                    MkGold does not have a nomination facility at present.
                    However, in the event of your death, your heirs can get in
                    touch with our customer support team to access your account.
                    They will be required to submit documentation such as an
                    affidavit (format will be provided), death certificate and
                    proof of their status as your heir.
                  </p>
                  <span className="section-heading">Risks and Mitigants</span>
                  <h2 className="subheading">Is this product legal?</h2>
                  <p>
                    Yes, the gold metal lease is a legal product - MkGold
                    would not offer any products which are not legal.
                  </p>
                  <h2 className="subheading">
                    How can you offer this product without an NBFC license?
                  </h2>
                  <p>
                    The gold metal lease is not denominated in rupees, nor does
                    it repaid in the form of rupees. All transactions take place
                    in the form of gold grams. Hence, this product does not
                    require an NBFC license.
                  </p>
                  <h2 className="subheading">
                    What are the risks that I am exposed to when committing my
                    gold?
                  </h2>
                  <p>
                    The key risks associated with the product can be viewed{" "}
                    <a
                      href="https://MkGoldstatic.s3.ap-south-1.amazonaws.com/IOG-KeyRiskFactors.pdf"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </p>
                  <h2 className="subheading">
                    What happens if the price of gold falls or if there’s a
                    default? Will you guarantee my returns?
                  </h2>
                  <p>
                    MkGold operates a platform which connects customers like
                    you who wish to lease out your gold metal, to Jewellers who
                    wish to enter into such a lease. While the Company will
                    endeavour to protect your metal by enforcing collateral in
                    an event of default by the jeweller, the Company does not
                    guarantee your capital or any returns and there is no
                    recourse available on the Company to you.
                  </p>
                  <h2 className="subheading">Is my gold safe?</h2>
                  <p>
                    MkGold follows various practices to help keep your gold
                    safe, including:
                  </p>
                  <p></p>
                  <ol>
                    <li>
                      Vetting the credit worthiness of the jewellers listed on
                      the platform
                    </li>
                    <li>
                      Obtaining security (in the form of collateral upto the
                      specified collateral coverage)
                    </li>
                    <li>
                      Requiring additional collateral in the event of an
                      increase in the price of gold
                    </li>
                    <li>
                      Enforcing the security collateral in the event of any
                      default
                    </li>
                  </ol>
                  <p />
                  <h2 className="subheading">
                    How can I trust the the jeweller who is leasing the gold?
                  </h2>
                  <p className="mb-4">
                    We take your trust in our platform very seriously, and are
                    extremely selective in which jewellers we allow to list on
                    our platform. These are usually jewellers with whom we have
                    deep relationships, where we have a higher confidence in
                    their credibility.
                  </p>
                  <p>
                    In addition to this, the jewellers taking the lease provide
                    a security for the lease, demonstrating their
                    creditworthiness and commitment to repayment.
                  </p>
                  <h2 className="subheading">
                    Is there any security over lease repayments? Do you have a
                    contingency plan?
                  </h2>
                  <p className="mb-4">
                    Jewellers provide a specified collateral coverage of the
                    value of gold they are leasing, which acts as a security for
                    the lease.
                  </p>
                  <p>
                    In the event of default, MkGold is authorised to enforce
                    the bank guarantee, to ensure that funds are recovered to
                    the greatest extent possible against the lease.
                  </p>
                  <h2 className="subheading">What is a bank guarantee?</h2>
                  <p>
                    A bank guarantee is an instrument through which a lending
                    institution (such as a bank) promises to cover a loss if a
                    leasee (such as a jeweller) defaults on a lease. This is
                    usually enforceable by the lessor (eg. the one offering the
                    metal to be leased) or an institution acting on behalf of
                    the lessor.
                  </p>
                  <h2 className="subheading">
                    What does a margin of a specified range such as 100-109%
                    mean?
                  </h2>
                  <p>
                    A margin of 100-109% means that the security provided by the
                    Lessor (i.e. Jeweller) is equivalent to a value within the
                    range of 100-109% of the gold metal taken on lease
                    (calculated as per the price on the date of the lease, and
                    thereafter updated based on daily pricing).
                  </p>
                  <h2 className="subheading">
                    How are you keeping my personal details safe?
                  </h2>
                  <p>
                    MkGold has stringent security measures in place to protect
                    the loss, misuse, and alteration of the information under
                    our control. Whenever you change or access your account
                    information, we offer the use of a secure server. Once your
                    information is in our possession we adhere to strict
                    security guidelines, protecting it against unauthorized
                    access.
                  </p>
                  <h2 className="subheading">What if my account is hacked?</h2>
                  <p className="mb-4">
                    MkGold uses OTP verification to authenticate access to
                    your account. In addition, the bank account to which
                    proceeds from the sale of gold to your account is sent is
                    verified against the PAN card provided by you, to prevent
                    the misuse or fraudulent use of your account.
                  </p>
                  <p>
                    Nevertheless, if you feel that your account has been
                    compromised or improperly accessed, we request you to to
                    inform us immediately by writing to{" "}
                    <a href="mailTo:care@MkGold.in">care@MkGold.in</a> or
                    call us on <a href="tel:8881000800">8881000800</a>.
                  </p>
                  <h2 className="subheading">
                    Is gold leasing a regulated product?
                  </h2>
                  <p>No, gold leasing is not a regulated product.</p>
                  <h2 className="subheading">
                    Who can I complain to if I have a complaint? Will SEBI/ RBI
                    help with grievance redressal?
                  </h2>
                  <p>
                    Gold Metal Leasing is not a regulated product and you do not
                    have any regulatory recourse in the event you suffer any
                    losses
                  </p>
                  <span className="section-heading">Taxation</span>
                  <h2 className="subheading">
                    What is my tax liability for gold lease yields?
                  </h2>
                  <p>
                    Taxation for digital gold is treated in the same manner as
                    physical gold - i.e. as a capital asset, on which long term
                    or short term capital gains tax is applicable, depending on
                    when you sell the product. You will be able to see the
                    transaction value for each yield paid into your account in
                    your transaction history section
                  </p>
                  <h2 className="subheading">
                    Are there any tax deductions on my gold lease yields?
                  </h2>
                  <p>
                    No, there is no TDS or other tax deduction on your gold
                    yields.
                  </p>
                  <h2 className="subheading">
                    Does MkGold report my lease yields to the tax authorities?
                    Why do you need my PAN details?
                  </h2>
                  <p>
                    MkGold collects customer PAN details as part of the
                    identity verification process, and as part of the security
                    process to ensure that the bank account to which sell funds
                    are sent, matches the name on your PAN card. We are not
                    required to report your yields or gold purchases to the tax
                    authorities.
                  </p>
                  <h2 className="subheading">Can I withdraw from my lease?</h2>
                  <p>
                    You can request us for a lease withdrawal/cancellation.
                    Lease withdrawals are normally processed within 2 days but
                    withdrawal is subject to market demand and could take
                    significantly longer. We do not guarantee that a lease
                    withdrawal will be completed within any stipulated period of
                    time.
                  </p>
                </ul>
              </div>
              <div class="tab-pane fade" id="sell">
                <ul className="sell">
                  <li>
                    <h2 className="subheading">How do I sell my gold?</h2>
                    <p>
                      MkGold provides a sell price quote on its website.
                      Customers can choose to sell any amount starting with a
                      minimum of ₹ 10 to a maximum of the amount of gold that
                      they own. Proceeds will be sent within 4 working days to
                      the customer's bank account after deducting any applicable
                      transaction charges (which will be disclosed in advance).
                      The sell quote may be temporarily unavailable in the
                      unlikely event of a disruption in the bullion market.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Where do I get the proceeds when I sell my gold?
                    </h2>
                    <p>
                      When you sell your gold, you’ll receive funds against it
                      in a verified bank account in your own name. You can add
                      your account details prior to the sale, which will be
                      verified by us. Once the verification is complete, you’ll
                      receive the proceeds post the sale of your gold, within 4
                      working days.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      When will the sales proceeds be credited to my account?
                    </h2>
                    <p>
                      It takes 4 working days to get money credited to your
                      account once you have sold your gold. If the sell
                      transaction has been made on Friday/Saturday or Sunday,
                      then you will receive your sales proceeds by Tuesday of
                      the following week.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      What happens if the sales proceeds are not credited to me
                      within 4 days?
                    </h2>
                    <p>
                      If the sales proceeds have not been received within 4
                      working days of the transaction, please email our customer
                      service team at{" "}
                      <a href="mailto:care@MkGold.in">care@MkGold.in</a>{" "}
                      with your related transaction details, and we shall
                      resolve your issue.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      I’m facing issues with bank account verification, what
                      should I do?
                    </h2>
                    <p>
                      In case you are facing any issues with Bank account
                      verification, please reach out to our support team with a
                      screenshot of the issue on{" "}
                      <a href="mailto:care@MkGold.in">care@MkGold.in</a>.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Why does it take upto 4 days to receive the sell amount in
                      my bank account?
                    </h2>
                    <p>
                      We send funds to your account the following day after your
                      sell transaction has been completed, and are subject to
                      verification of your bank account details, if necessary.
                      Due to the operational hours for banking transactions, as
                      well as the time taken to transfer funds between banks,
                      your funds may take up to 4 days to reach your bank
                      account.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Are there any KYC requirements while selling gold?
                    </h2>
                    <p>
                      PAN is required when buying or sell gold worth more than
                      Rs. 500. However, a 7 day wait period is applicable even
                      after PAN has been provided. This 7 day wait period can be
                      reduced to 3 days by completing the Aadhaar KYC
                      verification.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Why would I have to wait 7 days before I can sell any gold
                      that I buy
                    </h2>
                    <p>
                      To prevent fraudulent activity and to safeguard customers,
                      any gold that is bought must be held for 7 days before it
                      can be sold. Completing the Aadhaar KYC verification will
                      reduce this 7 day wait period to 3 days for bought gold.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      What is Aadhaar KYC verification?
                    </h2>
                    <p>
                      Aadhaar KYC verification is the process of securing your
                      account by authenticating your Aadhaar. It involves
                      triggering an OTP to your Aadhaar-linked mobile number and
                      clicking a selfie that will be matched against the photo
                      on your Aadhaar.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Why is Aadhar KYC required for selling gold?
                    </h2>
                    <p>
                      Aadhaar KYC is required only for those customers who wish
                      to sell gold between 3 to 7 days of buying it.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Can I reduce the 7-day wait period by doing Aadhaar KYC?
                    </h2>
                    <p>
                      Yes, by doing Aadhaar KYC, you can reduce the 7-day wait
                      period to 3 days.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      What happens if my PAN and Aadhaar details do not match?
                    </h2>
                    <p>
                      If your PAN and Aadhaar details do not match, you will
                      need to provide an upload of your PAN card and do Aadhaar
                      KYC. Our support team will evaluate your case &amp; reply
                      within 3 business days.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      What if my PAN already exists in the system?
                    </h2>
                    <p>
                      If your PAN already exists in the system, you can dispute
                      it by providing a PAN upload and doing Aadhaar KYC. If
                      verified, your PAN will be shifted to your profile along
                      with Aadhaar.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Is it mandatory to do Aadhaar KYC for buying or selling
                      gold?
                    </h2>
                    <p>
                      No, Aadhaar KYC is not mandatory, if your lifetime sell
                      amount is less than Rs. 500, you can provide your bank
                      account details/UPI VPA and the amount will be credited
                      after 48 hours. However, if your cumulative sell is above
                      Rs. 500, then providing PAN and a 7-day wait period will
                      be mandatory.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Can I reduce the wait period without doing Aadhaar KYC?{" "}
                    </h2>
                    <p>
                      No, you cannot reduce the wait period without doing
                      Aadhaar KYC. However, you can choose to wait for the 7-day
                      period to end or do Aadhaar KYC to reduce the wait period
                      to 3 days.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      What happens if my Aadhaar details do not match my
                      existing bank accounts?
                    </h2>
                    <p>
                      If your Aadhaar details do not match your existing bank
                      accounts, those bank accounts will not be used, they will
                      be disassociated with your profile and you will have to
                      add new bank accounts/UPI VPA where the account name
                      matches your Aadhaar name.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      What happens if I have done Aadhaar KYC and I already have
                      an existing profile in the system?
                    </h2>
                    <p>
                      If you have done Aadhaar KYC and you already have an
                      existing profile in the system, you can contact the
                      customer support team. They will conduct a video call to
                      confirm your identity and once verified, they will
                      associate your Aadhar with your profile. The video call
                      will be recorded by the customer support team.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Can I add a bank account with a different name than my
                      Aadhar name?{" "}
                    </h2>
                    <p>
                      No, you cannot add a bank account with a different name
                      than your Aadhar name.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      How long does it take for the amount to be credited if the
                      lifetime sell amount is less than 500?{" "}
                    </h2>
                    <p>
                      If the lifetime sell amount is less than 500, the user can
                      provide any bank account details/UPI VPA and the amount
                      will be credited after 48 hours.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      What is the wait period for cumulative purchases above Rs.
                      500?
                    </h2>
                    <p>
                      If the cumulative purchase is above Rs. 500, a 7-day wait
                      period is applied on any gold bought.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Can a user dispute a PAN that already exists in the
                      system?
                    </h2>
                    <p>
                      Yes, a user can dispute a PAN that already exists in the
                      system by providing a PAN upload and doing Aadhar KYC. CS
                      will verify and approve after verification, and the PAN
                      will be shifted to the user’s profile along with Aadhar.
                      Any gold leased on the previous profile will also be
                      shifted to the new profile.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="tab-pane fade" id="sip">
                <ul className="sip">
                  <li>
                    <h2 className="subheading">What is a Gold Savings Plan?</h2>
                    <p>
                      A Gold Savings Plan is a systematic savings tool that
                      allows customers to accumulate gold by choosing a fixed
                      instalment amount on a weekly/monthly basis for a selected
                      duration. Customers can accumulate gold and later use it
                      to exchange gold for jewellery on MkGold.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Can you choose multiple Gold Savings Plans?
                    </h2>
                    <p>
                      {" "}
                      Yes, you can choose more than 1 Gold Savings Plan by
                      adding individual SIP details for each.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Can you cancel a Gold Savings Plan?
                    </h2>
                    <p>
                      Yes, you can cancel a Gold Savings Plan, post which you
                      will not be charged for the next instalment.
                    </p>
                  </li>
                  {/* <li>
    <h2 class="subheading">Can you pause a Gold Savings Plan?</h2>
    <p>If you are unsure of being able to meet the monthly or weekly instalment for any reason, you can choose to “pause” your savings plan, and resume it at a later date. This can be done through the Plan Details page. You will receive a prompt to continue with the savings plan at the next installment date or after the paused period has elapsed.</p>
  </li> */}
                  <li>
                    <h2 className="subheading">
                      Can you make changes to an existing Gold Savings Plan?
                    </h2>
                    <p>
                      Yes, you can make changes to your Gold Savings Plan in two
                      ways – either increase the instalment amount or increase
                      the duration of the plan. However, you cannot decrease the
                      instalment amount, or change the frequency of the
                      instalment.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Is there a minimum amount for the instalment one can make
                      for a Gold Savings Plan?
                    </h2>
                    <p>
                      You can choose a minimum amount of{" "}
                      <i className="fa fa-inr" />
                      100 or higher for the Gold Savings Plan.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Which are the different methods of payments for a Gold
                      Savings Plan?
                    </h2>
                    <p>
                      You can opt for an e-mandate from the accepted banks list
                      to pay SIP amount.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      How does one know when the e-mandate has been verified?
                    </h2>
                    <p>
                      {" "}
                      It takes up to 5 working days for the bank to verify your
                      e-mandate. Once an e-mandate is verified we will send you
                      a SMS to intimate you that it is confirmed and the savings
                      plan is now active.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      What will happen if my bank account has insufficient funds
                      when my SIP payment is due?
                    </h2>
                    <p>
                      For an SIP (Weekly or Monthly) our system triggers a
                      payment on the SIP date. In case the transaction fails,
                      including due to insufficient balance in the account, we
                      will make two consecutive retries in the following days.
                      After 3 failed attempts, the SIP payment for that
                      particular week or month is skipped. MkGold does not
                      charge users for failed transactions, but we urge users to
                      maintain sufficient balance in their bank account to avoid
                      bank bounce charges.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="tab-pane fade" id="delivery">
                <ul className="delivery">
                  <li>
                    <h2 className="subheading">
                      How does the gold get delivered to me?
                    </h2>
                    <p style={{ paddingLeft: "2%" }}></p>
                    <ol>
                      <li>
                        You can request for all or part of your gold holding,
                        from 0.5 gm (and multiples thereof).
                      </li>
                      <li>
                        You can select the denomination of the gold you wish to
                        withdraw from the available options of coins.
                      </li>
                      <li>
                        Each coin will have different making charges, based on
                        the weight and design of the coin.
                      </li>
                      <li>
                        You must confirm the transaction by paying the
                        additional making and delivery charge.
                      </li>
                      <li>
                        Your gold product will be delivered at your notified
                        address by a logistics provider arranged by the Company,
                        and you will receive a confirmation on your registered
                        mobile number.
                      </li>
                      <li>
                        You will receive a dispatch confirmation and the waybill
                        number of the courier company which will allow you track
                        your package online.
                      </li>
                    </ol>
                    <p />
                  </li>
                  <li>
                    <h2 className="subheading">
                      Is it possible to store my withdrawn gold coins in safe
                      custody and have them delivered later?
                    </h2>
                    <p>
                      Once you place a delivery request, you have to accept the
                      delivery made to you. It cannot be made at a later date.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Can I change my delivery address?
                    </h2>
                    <p>
                      Yes, you can opt to have your gold delivered at an address
                      other than that mentioned at the time of registration.
                      However, this may be subject to verification of address
                      change, as per the norms established by the distributor.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Where is delivery of Gold available?
                    </h2>
                    <p>
                      MkGold delivers across India, through partnerships with
                      secure logistics providers. We maintain a list of
                      serviceable pin codes, and prior to making any purchase
                      you will be informed whether you pin code is serviceable
                      for delivery. Depending on the Distributor platform
                      however, you can still choose to have your gold delivered
                      to an address that is different from the one entered at
                      the time of registration. This may be subject to
                      verification by our Partners.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      Are there any charges for delivery of gold?
                    </h2>
                    <p>
                      Yes, you are required to pay a minting charge at the time
                      of requesting for delivery. We deliver your gold to you in
                      the form of assay certified coins, in tamper evident
                      packaging, using secure logistics providers. The charge
                      covers the cost of minting, packaging, insurance, assay
                      certification and delivery to you.
                    </p>
                  </li>
                  <li>
                    <h2 className="subheading">
                      How does MkGold ensure secured delivery of gold?
                    </h2>
                    <p>
                      The delivery of your gold coins and bars is fully insured.
                      We've partnered with the best delivery partners in the
                      country to ensure your gold is in safe hands right from
                      the locker to your home. To ensure the quality of the gold
                      coin and bars delivered to you - we have our products
                      independently assessed and assay certified to ensure zero
                      negative weight tolerance and promised purity.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqpage;
