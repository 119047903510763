import React, { useState } from "react";
import "../assets/style/style.css";
import Signin from "./Signin";

const Investing = () => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(true);
  };
  return (
    <>
      <div className="container-fluid">
        <section className="row start-investing-section homepage-section-9">
          <div className="col col-12 col-lg-7 column-1">
            <h3>Start investing in gold today</h3>
          </div>
          <div
            className="col col-12 col-lg-5 column-2 ng-scope"
            ng-controller="loaderCtrl"
          >
            <a
              className="button"
              data-toggle="modal"
              data-target="#loginModal"
              data-backdrop="static"
              data-keyboard="false"
              type="button"
              ng-click="isFooterLogin = true"
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              <span
                ng-if="!isFooterLogin"
                style={{ color: "#bd8c2b" }}
                data-toggle="modal"
                data-target="#loginModal"
                data-backdrop="static"
                data-keyboard="false"
                className="ng-scope"
              >
                Sign up
              </span>
            </a>
            {show && <Signin show={show} setShow={setShow}/>}
          </div>
        </section>
      </div>
    </>
  );
};

export default Investing;
