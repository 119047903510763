import React from "react";
import "../assets/style/style.css";

const Betterthanshaving = () => {
  return (
    <section className="row better-than-savings-section homepage-section-4">
      <div className="row section-outer">
        <div className="col col-12 col-lg-6 column-1">
          <div className="content-desktop">
            <h1 className="comparison-animation-1">
              Better than a savings account
            </h1>
            <p className="comparison-animation-2">
              A ₹100 investment in gold <span id="invYear">2 years</span> ago
              would be:
            </p>
            <ul className="graph-data comparison-animation-3">
              <li>
                <div className="gold" />
                <div className="info">
                  <span className="label">Gold</span>
                  <span className="price" id="gold-price">
                    ₹145
                  </span>
                </div>
              </li>
              <li>
                <div className="grey" />
                <div className="info">
                  <span className="label">Savings Account</span>
                  <span className="price" id="savings-price">
                    ₹106
                  </span>
                </div>
              </li>
            </ul>
            <div className="grow-wealth-smarter grow-wealth-smarter-desktop comparison-animation-4">
              <h2>Grow your wealth smarter</h2>
              <p>Start an SIP to invest in gold every month.</p>
              <a
                className="button"
                ng-click="postBuyData('savings-plan'); isLoginModal = true;"
                ng-class='{"no-arrow":isLoginModal==true}'
              >
                
                <span ng-if="!isLoginModal" className="ng-scope">
                  Start investing
                </span>
                
              </a>
            </div>
          </div>
        </div>
        <div className="col col-12 col-lg-6 column-2 comparison-animation-5">
          <div className="chart-container">
            <div className="chart image-container">
              <img
                className="one-year"
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/1-year-gold-vs-savings-new.svg"
                alt="Chart"
              />
              <img
                className="active two-year"
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/1-year-gold-vs-savings-new.svg"
                alt="Chart"
              />
              <img
                className="five-year"
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/1-year-gold-vs-savings-new.svg"
                alt="Chart"
              />
            </div>
            <div className="year-tabs-container">
              <span>Show growth for:</span>
              <ul className="year-tabs" id="year-tabs">
                <li className="disabled-button one-year-tab" id="1y-button">
                  1 Year
                </li>
                <li
                  className="active disabled-button two-year-tab"
                  id="2y-button"
                >
                  2 Years
                </li>
                <li className="disabled-button five-year-tab" id="5y-button">
                  5 Years
                </li>
              </ul>
            </div>
          </div>
          <div className="grow-wealth-smarter grow-wealth-smarter-mobile">
            <h2>Grow your wealth smarter</h2>
            <p>Start an SIP to invest in gold every month.</p>
            <a className="button" ng-click="postBuyData('savings-plan')">
              Start investing
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Betterthanshaving;
