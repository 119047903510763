import React, { useEffect } from "react";
import Aboutpage from "../components/Aboutpage";

const About = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])
    return <>
        <Aboutpage/>
    </>
}

export default About;