import React from "react";
import "../assets/style/style.css";

const Safegoldg = () => {
  return (
    <>
      <div
        className="delivery-banner desktop ng-scope"
        ng-if="windowWidth > 768"
      >
        <div>
          <img
            src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/shield-1.png"
            style={{ float: "left", height: 70 }}
            alt
          />
          <p>
            MkGold <br />
            Guarantee
          </p>
        </div>
        <div>
          <img
            src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/shield.png"
            alt
          />
          24K Guaranteed
          <br /> Quality Certified
        </div>
        <div>
          <img
            src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/hand.png"
            alt
          />
          Free Insurance on <br /> Delivery
        </div>
        <div>
          <img
            src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/gold-bar.png"
            alt
          />
          Zero negative <br /> weight tolerance
        </div>
        <div>
          <img
            src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/d-truck.png"
            alt
          />
          Order Tracking &amp; <br />
          Support
        </div>
      </div>
    </>
  );
};

export default Safegoldg;
