import React, { useState } from "react";
import "../assets/style/style.css";
import Signin from "./Signin";

const Banner = () => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(true);
  };
  return (
    <>
      <section className="banner row">
        <div className="col col-12 col-lg-6 column-1 header-animation-4 about-cs-header">
          <div className="image-container about-img">
            <img
              src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/about-us/banner-image.svg"
              alt="Accumulation Flows"
            />
          </div>
        </div>
        <div className="col col-12 col-lg-6 column-2">
          <p className="goal header-animation-1 about-t1">
            We want to make gold simpler and more transparent, so that you can
            get the most out of your savings.
          </p>
          <p className="header-animation-2">
            MkGold is a digital platform that allows customers to buy, sell
            and receive vaulted gold at low ticket sizes, around the clock, with
            the tap of a button.
          </p>
          <button
            className="primary-button header-animation-3 ng-scope"
            data-toggle="modal"
            data-target="#loginModal"
            data-backdrop="static"
            data-keyboard="false"
            ng-click="isSignUpModal=true"
            ng-controller="loaderCtrl"
            onClick={handleClick}
          >
        
            <span
              data-toggle="modal"
              data-target="#loginModal"
              data-backdrop="static"
              data-keyboard="false"
              ng-if="!isSignUpModal"
              className="ng-scope"
            >
              Signup Now
            </span>
          </button>
          {show && <Signin show={show} setShow={setShow}/>}
        </div>
      </section>
    </>
  );
};

export default Banner;
