import React, { useEffect } from "react";
import '../assets/style/style.css';

const Refundpolicypage = () => {
    
    return (
      <>
        <div className="info-page">
          <div className="content-container">
            <div className="title-container">
              <h1 className="title">Refund Policy</h1>
              <span className="divider" />
            </div>
            <div className="information-container" style={{lineHeight: '2.3rem'}}>
              <p>
                Please note, once an order for purchase or sale has been
                confirmed by the customer, refunds or cancellation is not
                possible. All orders are final upon customer confirmation
              </p>
              <p>
                Deliveries are made only to the registered address of the
                customer. Please keep proof of identity ready at the time of
                delivery as it may be required for verification by the courier
                partner. If no one is available at the registered address to
                take delivery, delivery will be attempted at least once more
                after which the package will be returned to the vault and the
                customer's balance will be credited back with the corresponding
                grams of gold. Shipping charges will be payable again as and
                when the customer asks for delivery.
              </p>
              <p>
                Customers are required to verify that the package they are
                accepting has not been tampered with and is of acceptable
                quality. Once delivery has been taken by the customer, returns
                and refunds are not possible. All of our products are 100%
                certified and there should never be any problems with quality.
                In the unlikely event of any issues, please contact
                care@MkGold.in, we will investigate the matter to resolve any
                concerns or issues.
              </p>
            </div>
          </div>
        </div>
      </>
    );
}

export default Refundpolicypage;