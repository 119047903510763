import React, { useEffect } from "react";
import Partnerpages from "../components/Partnerpages";
import Footer from "../components/Footer";
import News from "../components/News";
import Insights from "../components/Insights";

const Partnerpage = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])
    return <>
        <Partnerpages/>
        <Insights/>
        <div style={{marginBottom: '80px'}}></div>
        <Footer/>
    </>
}

export default Partnerpage;