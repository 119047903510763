import React from "react";
import "../assets/style/style.css";
import Header from "../components/Header";
import Banner from "./Banner";
import Supportpartner from "./Supportpartner";
import Footer from './Footer';
import Goldsafety from "./Goldsafety";
import Trustedpartner from "./Trustedpartner";
import Ourteammedia from "./Ourteammedia";
import News from "./News";
import Investing from "./Investing";

const Aboutpage = () => {
  return (
    <>
      <div className="about-us">
        <div className="container-fluid">
          <Header />
          <Banner />
          <Goldsafety/>
          <Supportpartner/>
          <Trustedpartner/>
          <Ourteammedia/>
          <News/>
          <Investing/>
          <Footer/>
        </div>
      </div>
    </>
  );
};

export default Aboutpage;
