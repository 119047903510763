import React from "react";
import Header from "./Header";
import '../assets/style/style.css';
import logo from '../assets/images/logo/mk-gold.jpg'

const Buycoinpage = () => {
  return (
    <>
      <div className="delivery-bg">
        <div className="container-fluid">
          {/*header*/}
          <Header/>
          <section className="delivery-section-1 m-t-5 row">
            <div className="delivery-container-1 col-sm-12 col-md-6">
              <h1>Get 24K Gold delivered to your doorstep</h1>
              <div className="sub-1 m-b sub__2">
                <div>
                  <img
                    src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/certificate.png"
                    alt
                  />
                  24K Pure Gold Quality Guaranteed
                </div>
                <div>
                  <img
                    src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/truck.png"
                    alt
                  />
                  100% Secure with Delivery Insurance
                </div>
                <div>
                  <img
                    className="price-img"
                    src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/price-tag.png"
                    alt
                  />
                  Best Prices in the market
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-6 gold-coin-bar">
              <div className="image-container coins-img">
                <img
                  data-src="/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/coins-tab-img.png"
                  alt
                  className=" lazyloaded"
                  srcSet="https://d2swkjwe31rb4i.cloudfront.net/images/coins-tab-img.png, https://d2swkjwe31rb4i.cloudfront.net/images/coins-tab-img.webp"
                  src="/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/coins-tab-img.png"
                />
              </div>
            </div>
          </section>
          
        </div>
        {/* <Footer/> */}
      </div>
    </>
  );
};

export default Buycoinpage;
