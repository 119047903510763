import React, { useEffect } from "react";
import Header from "../components/Header";
import Leasepage from "../components/Leasepage";

const Lease = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])
    return <>
    {/* <Header/> */}
    <Leasepage/>
    </>
}

export default Lease;