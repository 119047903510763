import React, { useEffect } from "react";
import Buycoinpage from "../components/Buycoinpage";
import Deliverysec from "../components/Deliverysec";
import Productlist from "../components/Productlist";
import Footer from "../components/Footer";
import Faqquestion from "../components/Faqquestion";
import Safegoldg from "../components/Safegoldg";

const Buycoin = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])
    return <>
        <Buycoinpage/>
        <Deliverysec/>
        <Productlist/>
        <Faqquestion/>
        <Footer/>
    </>
}

export default Buycoin;