import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Faqpage from "../components/Faqpage";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Header />
      <Faqpage/>
      <Footer/>
    </>
  );
};

export default Faq;
