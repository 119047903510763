import React from "react";
import "../assets/style/style.css";

const Review = () => {
  return (
    <>
      <section className="delivery-section-1 m-t-5 row">
        <div className="delivery-container-1 col-sm-12 col-md-12">
          <div className>
          
            <div
              className="review-header ng-scope"
              ng-if="customerReview.length == 0"
            >
              <p>
                <span>Reviews</span>
                <br />
                <br />
                No reviews yet
              </p>
            </div>
            <hr/>
          </div>
        </div>
      </section>
    </>
  );
};

export default Review;
