import React from "react";
import "../assets/style/style.css";
import Header from "./Header";
import Jewellerysec3 from "./Jewellerysec3";

const Jewelleryexmain = () => {
  return (
    <>
      <div className="public-pages">
        <div className="partners-index">
          <Header />
          {/* Section 1 */}
          <section className="row hero-section homepage-section-1 section-1">
            <div className="col col-12 col-lg-6 column-1">
              <div className="content-container">
                <h1>Exchange for Jewellery</h1>
                <div className="button-video-container">
                  <p>
                    You can Exchange your Digital Gold for Jewellery at our
                    Partner’s website online and offline!
                  </p>

                  <a
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    data-target="#loginModal"
                    className="banner-cta ng-scope"
                    ng-click="isLoginModal=true"
                    ng-controller="loaderCtrl"
                  >
                    <button className="primary-button know-more-button">
                      
                      <span
                        ng-if="!isLoginModal"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        data-target="#loginModal"
                        className="ng-scope"
                      >
                        Signup to buy Jewellery
                      </span>
                      
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-6 column-2">
              <div className="image-container desktop-version">
                <img
                  width={"100%"}
                  height={"339px"}
                  className="sg-carousel-video"
                  src="https://pbs.twimg.com/media/DBjBHj7U0AAdoe5.jpg"
                />
              </div>
            </div>
          </section>

          {/* {section 2} */}
          <section className="row grid-section homepage-section-2">
            <div>
              <p>
                A unique and exclusive offering for MkGold users only that
                lets you exchange your digital gold savings for jewellery pieces
                as per your liking from some of the best jewellery brands in
                India!
              </p>
            </div>
          </section>
          {/* {section 3} */}
          <Jewellerysec3/>
        </div>
      </div>
    </>
  );
};

export default Jewelleryexmain;
