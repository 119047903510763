import React from "react";

const Trustedpartner = () => {
  return (
    <>
      <section className="trusted-partners partners-desktop">
        <p className="section-subtitle partners-animation-1">
          Trusted by the ones you trust
        </p>
        <div className="logos-container row">
          <div className="image-container col col-2 partners-animation-3">
            <div className="tanishq" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="phonepe" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="amazon-pay" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="flipkart" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="bharatpe" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="axis-bank" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="freecharge" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="caratlane" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="candere" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="mobikwik" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="bajaj-markets" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="airtel-payments" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="jarlogo" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="five-paisa" />
          </div>
          <div className="image-container col col-2 partners-animation-3">
            <div className="iifl" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Trustedpartner;
