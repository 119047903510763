import React from "react";
import '../assets/style/style.css';

const Grievancepage = () => {
  return (
    <>
      <div className="info-page">
        <div className="content-container">
          <div className="title-container">
            <h1 className="title">Grievance</h1>
            <span className="divider" />
          </div>
          <div className="information-container" style={{lineHeight: '2.5rem'}}>
            <p>
              MkGold strongly believes in a sincere and transparent approach
              to its users. You trust and love us and we would never put growth
              before trust. We aim to minimize instances of customer complaints,
              grievances and disappointments via channelized approach, review
              and redressal. However, we recognise that there are times when our
              product or service has not been up to the mark. If you’re
              disappointed, then at some point in time we’ve not done a great
              enough job.
            </p>
            <ul>
              <li>Our users are to be treated fairly at all times.</li>
              <li>
                Complaints or concerns raised by users are dealt with an open
                mind, with courtesy and in a timely manner.
              </li>
              <li>
                Users are informed about the channels to escalate their
                complaints, concerns and grievances within MkGold and their
                rights if they are not satisfied with the resolution of their
                complaints.
              </li>
              <li>
                MkGold will take care of all complaints efficiently and fairly
                as they value each user.
              </li>
              <li>
                All employees at MkGold must work in good faith and without
                prejudice to the interests of the users.
              </li>
            </ul>
            <p>
              Regardless of the reason behind the customer complaint, our
              customers have complete authority to file a complaint/ share
              feedback if they are disappointed by services rendered by
              MkGold. They can give their complaint/ feedback in writing,
              email, or on MkGold.com.
            </p>
            <p>
              If a customer’s dilemma is not resolved within the given time or
              if they are not satisfied with the solution provided by MkGold,
              they can approach our tiered redressal system with their complaint
              or other legal avenues available for grievance redressal.
            </p>
            <h2 className="subheading">
              Sensitizing staff on handling complaints
            </h2>
            <p>
              Our staff undergoes regular training to ensure that consumers
              queries and grievances are handled properly. They are encouraged
              to work in a manner which helps us in building the consumer trust
              and confidence. This reflects in both the operations as well as
              the customer communications. Top reasons behind the consumer
              queries are analysed and worked upon in a way which aims at
              removal of these reasons from the root. This helps in improving
              the overall quality of the service levels gradually.
            </p>
            <h2 className="subheading">Customer Care Escalation Matrix</h2>
            <p>
              <span>Level 1</span> - You can call us at +91 888 1000 800 to
              discuss any unresolved issues. If you are not satisfied with our
              response, please ask to speak with customer service supervisor and
              you will be transferred immediately or receive a call back within
              1 working day.
            </p>
            <p>
              <span>Level 2</span> - You can reach us by sending an email to
              care@MkGold.in. A ticket number will be issued to you at the
              time of logging your complaint. We will aim to respond to queries
              or complaints within 2 working days.
            </p>
            <p>
              <span>Level 3</span> - In case the issue remains unresolved, you
              can write to our grievance officer, grievanceofficer@MkGold.in,
              mentioning the specific ticket number allotted to you at the time
              of the original complaint.
            </p>
            {/* <p>
        <span>Level 4</span> - For unresolved matters, you can further
        escalate the complaint/ issue to our Security Trustee at the
        following address:<br />
        Mr. Subrat Udgata<br />
        IDBI Trusteeship Services Limited<br />
        Ground Floor, Asian Building<br />
        17, R Kamani Rd<br />
        Ballard Estate, Fortescue<br />
        Mumbai - 400001
      </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Grievancepage;
