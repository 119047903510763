import React from "react";
import "../assets/style/style.css";

const Faqquestion = () => {
  return (
    <>
      <section className="public-faq-section">
        <div className="delivery-container-1">
          <div id="online-accordion" className="accordion">
            <div className="card d-card">
              <div
                style={{ marginTop: "4rem" }}
                className="card-header"
                id="heading-1"
              >
                <h5 className="mb-0">
                  <a
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Frequently asked questions
                  </a>
                </h5>
              </div>
              <div className="collapse" id="collapseExample">
                <div className="card-body">
                  <div id="online-accordion-1" className="accordion-1">
                    <div className="card">
                      <div className="card-header" id="heading-1-1">
                        <h5 className="mb-0">
                          <a
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseExample1"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            How does the gold get delivered to me?{" "}
                            <i className="arrow right" />
                          </a>
                        </h5>
                      </div>
                      <div class="collapse" id="collapseExample1" style={{}}>
                        <div className="card-body">
                          <p> </p>
                          <li>
                            Select a gold coin or bar of your choice from the
                            options and denominations available
                          </li>
                          <li>
                            Place your order by adding your contact information
                            and address and complete the payment for the
                            selected product online.
                          </li>
                          <li>
                            Your gold product will be securely delivered at your
                            notified address by a logistics provider arranged by
                            us, and you will receive updates on your registered
                            mobile number
                          </li>
                          <li>
                            You will receive an “Order dispatched” confirmation
                            and the waybill number of the courier company which
                            will allow you to track your package online.
                          </li>
                          <p />
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading-1-2">
                        <h5 className="mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-target="#collapse-1-2"
                            aria-expanded="false"
                            aria-controls="collapse-1-2"
                          >
                            Is it possible to store my withdrawn gold coins in
                            safe custody and have them delivered later?{" "}
                            <i className="arrow right" />
                          </a>
                        </h5>
                      </div>
                      <div
                        id="collapse-1-2"
                        className="collapse"
                        data-parent="#online-accordion-1"
                        aria-labelledby="heading-1-2"
                      >
                        <div className="card-body">
                          <p>
                            Once you have chosen to get your coin delivered to
                            you, you cannot return it to MkGold’s secure
                            custody. In case you wish to purchase gold and keep
                            it in our world-class storage vaults, you can choose
                            to purchase gold digitally, by signing up and buying
                            the gold you need{" "}
                            <a href="https://www.MkGold.com/?sgdl=buy">
                              {" "}
                              here
                            </a>
                            . <br />
                            <br />
                            When you purchase digital gold, you own the weight
                            of gold you purchased, and we store the equivalent
                            amount for you in our lockers. Whenever you're ready
                            to get the coin delivered, you can do so by paying a
                            small minting and delivery charge and we’ll deliver
                            it for you.
                            <br />
                            <br />
                            However, if you place a delivery request right now,
                            you will have to accept the delivery made to you and
                            we won’t be able to hold it for you for a later
                            date.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading-1-3">
                        <h5 className="mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-target="#collapse-1-3"
                            aria-expanded="false"
                            aria-controls="collapse-1-3"
                          >
                            Can I change my delivery address?{" "}
                            <i className="arrow right" />
                          </a>
                        </h5>
                      </div>
                      <div
                        id="collapse-1-3"
                        className="collapse"
                        data-parent="#online-accordion-1"
                        aria-labelledby="heading-1-3"
                      >
                        <div className="card-body">
                          <p>
                            Yes, you can opt to have your gold delivered at an
                            address other than that mentioned at the time of
                            placing the order, by contacting us on{" "}
                            <a href="mailTo:care@MkGold.in">
                              care@MkGold.in
                            </a>
                            . However, this may be subject to verification of
                            address change for security.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading-1-4">
                        <h5 className="mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-target="#collapse-1-4"
                            aria-expanded="false"
                            aria-controls="collapse-1-4"
                          >
                            Where is delivery of Gold available?{" "}
                            <i className="arrow right" />
                          </a>
                        </h5>
                      </div>
                      <div
                        id="collapse-1-4"
                        className="collapse"
                        data-parent="#online-accordion-1"
                        aria-labelledby="heading-1-4"
                      >
                        <div className="card-body">
                          <p>
                            MkGold delivers across India, through partnerships
                            with secure logistics providers. You can use the
                            Check Availability option at the top to see if your
                            Pin code is serviceable, and prior to making any
                            purchase, you will be informed whether your pin code
                            is serviceable or not as well.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading-1-5">
                        <h5 className="mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-target="#collapse-1-5"
                            aria-expanded="false"
                            aria-controls="collapse-1-5"
                          >
                            Are there any charges for delivery of gold?{" "}
                            <i className="arrow right" />
                          </a>
                        </h5>
                      </div>
                      <div
                        id="collapse-1-5"
                        className="collapse"
                        data-parent="#online-accordion-1"
                        aria-labelledby="heading-1-5"
                      >
                        <div className="card-body">
                          <p>
                            There is no additional delivery charge over and
                            above the cost of the product! We deliver your gold
                            to you in the form of 99.99% pure coins that are
                            tested in an NABL accredited laboratory, in
                            tamper-evident packaging, using secure logistics
                            providers. The total price covers the cost of
                            minting, packaging, insurance, assay certification
                            to ensure the best quality of our gold products.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading-1-6">
                        <h5 className="mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-target="#collapse-1-6"
                            aria-expanded="false"
                            aria-controls="collapse-1-6"
                          >
                            How does MkGold ensure secured delivery of gold?{" "}
                            <i className="arrow right" />
                          </a>
                        </h5>
                      </div>
                      <div
                        id="collapse-1-6"
                        className="collapse"
                        data-parent="#online-accordion-1"
                        aria-labelledby="heading-1-6"
                      >
                        <div className="card-body">
                          <p>
                            The delivery of your gold coins and bars is fully
                            insured. We've partnered with the best delivery
                            partners in the country to ensure your gold is in
                            safe hands right from the locker to your home. To
                            ensure the quality of the gold coin and bars
                            delivered to you - we have our products
                            independently assessed and assay certified to ensure
                            zero negative weight tolerance and promised purity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqquestion;
