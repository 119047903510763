import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Grievancepage from "../components/Grievancepage";

const Grievance = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Header />
      <Grievancepage/>
      <Footer/>
    </>
  );
};

export default Grievance;
