import React from "react";
import "../assets/style/style.css";

const News = () => {
  return (
    <>
      <section className="news">
        <h1 className="itn-animation-1">In the news</h1>
        <ul>
          <li className="image-container itn-animation-2">
            <img
              data-src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/forbes-logo.png"
              alt
              className=" ls-is-cached lazyloaded"
              src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/forbes-logo.png"
            />
          </li>
          <li className="image-container itn-animation-2 firstpost-li">
            <img
              
              alt
              className="firstpostImg lazyloaded"
              src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/logo/firstpost.png"
            />
          </li>
          <li className="image-container itn-animation-2">
            <img
              src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/et-logo.svg"
              className=" ls-is-cached lazyloaded"
              alt
            />
          </li>
          <li className="image-container itn-animation-2">
            <img
              data-src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/business-standard.svg"
              alt
              className=" lazyloaded"
              src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/business-standard.svg"
            />
          </li>
          <li className="image-container itn-animation-2">
            <img
              data-src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/moneycontrol-logo.svg"
              alt
              className=" lazyloaded"
              src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/moneycontrol-logo.svg"
            />
          </li>
          <li className="image-container itn-animation-2">
            <img
              data-src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/livemint-logo.svg"
              alt
              className=" lazyloaded"
              src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/livemint-logo.svg"
            />
          </li>
        </ul>
      </section>
    </>
  );
};

export default News;
