import React, { useEffect, useRef } from "react";
import Privacy from "../components/Privacy";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Privacypolicy = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Header />
      <Privacy />
      <Footer/>
    </>
  );
};

export default Privacypolicy;
