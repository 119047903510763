import React, { useEffect } from "react";
import Header from "../components/Header";
import Jewelleryexmain from "../components/Jewelleryexmain";
import Faqquestion from "../components/Faqquestion";
import Footer from "../components/Footer";

const Jewelleryex = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])
    return <>
        <Jewelleryexmain/>
        {/* <Faqquestion/> */}
        <Footer/>
    </>
}

export default Jewelleryex;