import React from "react";
import "../assets/style/style.css";

const Ourteammedia = () => {
  return (
    <>
      <section className="our-team row" style={{ marginTop: 55 }}>
        <div className="work-with-us col col-12 col-lg-5 m-auto">
          <h2>From the media?</h2>
          <p>
            If you are looking to get in touch with MkGold for quotes, stories
            or news features, do write to us at
            <a href="mailto:media@MkGold.in" className="mail">
              {" "}
              <span className="career-email">media@mkgold.in</span>
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default Ourteammedia;
