import React from "react";
import Header from "../components/Header";

const Partnerpages = () => {
  return (
    <>
      <main className="partners-index">
        <div className="container-fluid">
          <Header />
          {/* section-1 */}
          <section className="row hero-section homepage-section-1 section-1">
            <div className="col col-12 col-lg-6 column-1">
              <div className="content-container">
                <h1
                  className="header-animation-3"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  Digital Gold solutions for every need
                </h1>
                <div
                  className="button-video-container header-animation-4"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <a href="/schedule-demo">
                    <button className="primary-button know-more-button">
                      Request a Demo
                    </button>
                  </a>
                  <a href="#product-overview">
                    <button className="secondary-button learn-more-button">
                      Product Overview
                      <img
                        src="https://www.safegold.com/assets/images/partnerships/hero-green-arrow.svg"
                        alt
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-6 column-2">
              <div className="image-container">
                <img
                  src="https://www.safegold.com/assets/images/partnerships/homepage-desktop.svg"
                  alt
                  className="desktop-image header-animation-1"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                />
                <img
                  src="https://www.safegold.com/assets/images/partnerships/homepage-mobile.svg"
                  alt
                  className="mobile-image header-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                />
              </div>
            </div>
          </section>

          {/* section-2 */}
          <section
            className="row grid-section homepage-section-2"
            id="product-overview"
          >
            <div
              className="col col-12 grid-section__heading ts-animation-1"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              Think Gold — Choose MkGold
            </div>
            {/* Card 1 */}
            <div
              className="col col-12 col-md-4 grid-section__item ts-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/savings.svg"
                    alt
                  />
                </div>
                <h3>Digital Gold Savings</h3>
                <p>
                  Enable seamless, one-tap gold purchase through our
                  comprehensive API and PWA solutions.
                </p>
                <a
                  href="/digital-gold-savings"
                  className="link-button learn-more-button"
                >
                  Learn more
                </a>
              </div>
            </div>
            {/* Card 2 */}
            <div
              className="col col-12 col-md-4 grid-section__item ts-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/giftbox.svg"
                    alt
                  />
                </div>
                <h3>Corporate Gifting Program</h3>
                <p>
                  Combine gold with technology to unlock gifts that delight.
                </p>
                <a
                  href="/corporate-gifting"
                  className="link-button learn-more-button"
                >
                  Learn more
                </a>
              </div>
            </div>
            {/* Card 3 */}
            <div
              className="col col-12 col-md-4 grid-section__item ts-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/ring.svg"
                    alt
                  />
                </div>
                <h3>Digital Gold For Jewellers</h3>
                <p>
                  Acquire customers for your jewellery brand through seamless
                  exchange and redemption.
                </p>
                <a
                  href="/exchange-for-jewellery"
                  className="link-button learn-more-button"
                >
                  Learn more
                </a>
              </div>
            </div>
          </section>

          {/* section-3 */}
          <section className="row grid-section homepage-section-3">
            <div
              className="col col-12 grid-section__heading benefits-animation-1"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              Benefits
            </div>
            {/* Card 1 */}
            <div
              className="col col-12 col-md-4 grid-section__item benefits-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/bank-index.svg"
                    alt
                  />
                </div>
                <h3>Partner with India’s largest digital gold platform</h3>
                <p>
                  Trusted by leading mobile wallets, banks, NBFCs, and financial
                  institutions.
                </p>
              </div>
            </div>
            {/* Card 2 */}
            <div
              className="col col-12 col-md-4 grid-section__item benefits-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/developer.svg"
                    alt
                  />
                </div>
                <h3>Super-fast integration options</h3>
                <p>
                  Use our app-within-app solution customised to your brand for a
                  fast go-to-market, or comprehensive APIs for deeper
                  integration.
                </p>
              </div>
            </div>
            {/* Card 3 */}
            <div
              className="col col-12 col-md-4 grid-section__item benefits-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/shield.svg"
                    alt
                  />
                </div>
                <h3>Zero counter-party risk</h3>
                <p>
                  Our unique Security Trustee &amp; Independent Custodian setup
                  protects customer interests and your reputation.
                </p>
              </div>
            </div>
            {/* Card 4 */}
            <div
              className="col col-12 col-md-4 grid-section__item benefits-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/megaphone.svg"
                    alt
                  />
                </div>
                <h3>Customer Acquisition</h3>
                <p>
                  Acquire high-value gold savings customers for your jewellery
                  brand.
                </p>
              </div>
            </div>
            {/* Card 5 */}
            <div
              className="col col-12 col-md-4 grid-section__item benefits-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/telemarketer.svg"
                    alt
                  />
                </div>
                <h3>Stellar integration support</h3>
                <p>
                  Our support team is accessible to help you with forward
                  integration and infrastructure.
                </p>
              </div>
            </div>
            {/* Card 6 */}
            <div
              className="col col-12 col-md-4 grid-section__item benefits-animation-2"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="content-container">
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/images/partnerships/necklace.svg"
                    alt
                  />
                </div>
                <h3>Offer customers the most popular savings asset</h3>
                <p>
                  200 million Indians buy gold each year; tap into the world’s
                  largest gold market with a digital solution.
                </p>
              </div>
            </div>
          </section>

          {/* section-4 */}

          {/* section-5 */}
          <section className="row partners-section homepage-section-4">
            <div className="col col-12 col-md-4 column-1">
              <h2
                className="partners-animation-1"
                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
              >
                Our Partners
              </h2>
            </div>
            <div className="col col-12 col-md-8 column-2 partners-section-desktop">
              <div className="row partner-logos-container">
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container tanishq"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container phonepe"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container amazon-pay"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container mobikwik"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container freecharge"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container five-paisa"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container bajaj-markets"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container caratlane"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container candere"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container axis-bank"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container airtel-payments"></div>
                </div>
                {/* <div class="col col-3 partners-animation-2">
        <div class="image-container fisdom">
        </div>
      </div> */}
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container dbs-bank"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container iifl"></div>
                </div>
                {/* <div class="col col-3 partners-animation-2">
        <div class="image-container mipay">
        </div>
      </div> */}
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container flipkart"></div>
                </div>
                <div
                  className="col col-3 partners-animation-2"
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  <div className="image-container jar-logo"></div>
                </div>
                {/* <div class="col col-3 partners-animation-2">
        <div class="image-container galaxy-card">
        </div>
      </div>
      <div class="col col-3 partners-animation-2">
        <div class="image-container b2c">
        </div>
      </div>
      <div class="col col-3 partners-animation-2">
        <div class="image-container jarlogo">
        </div>
      </div> */}
              </div>
            </div>
            <div
              className="col col-12 partners-section-mobile partners-animation-3"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="owl-carousel owl-one owl-loaded owl-drag">
                {/* 1 */}
                {/* 2 */}
                {/* 3 */}
                {/* 4 */}
                {/* 5 */}
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                      transform: "translate3d(0px, 0px, 0px)",
                      transition: "all 0s ease 0s",
                    }}
                  >
                    <div className="owl-item">
                      <div className="item">
                        <div className="logos-container row">
                          <div className="image-container col col-6">
                            <div className="tanishq" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="phonepe" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="amazon-pay" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="mobikwik" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="owl-item">
                      <div className="item">
                        <div className="logos-container row">
                          <div className="image-container col col-6">
                            <div className="freecharge" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="five-paisa" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="bajaj-markets" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="caratlane" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="owl-item">
                      <div className="item">
                        <div className="logos-container row">
                          <div className="image-container col col-6">
                            <div className="candere" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="axis-bank" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="airtel-payments" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="owl-item">
                      <div className="item">
                        <div className="logos-container row">
                          {/* <div class="image-container col col-6">
            <div class="fisdom"></div>
          </div> */}
                          <div className="image-container col col-6">
                            <div className="dbs-bank"></div>
                          </div>
                          <div className="image-container col col-6">
                            <div className="iifl" />
                          </div>
                          {/* <div class="image-container col col-6">
            <div class="mipay"></div>
          </div> */}
                          <div className="image-container col col-6">
                            <div className="flipkart" />
                          </div>
                          <div className="image-container col col-6">
                            <div className="jar-logo" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-nav disabled">
                  <button
                    type="button"
                    role="presentation"
                    className="owl-prev"
                  >
                    <span aria-label="Previous">‹</span>
                  </button>
                  <button
                    type="button"
                    role="presentation"
                    className="owl-next"
                  >
                    <span aria-label="Next">›</span>
                  </button>
                </div>
                <div className="owl-dots disabled" />
              </div>
            </div>
          </section>

          <section className="row counter-section homepage-section-5">
            <div className="col col-12 col-md-4 column-1">
              <h2>Proven Track Record</h2>
            </div>
            <div className="col col-12 col-md-8 column-2 content-container">
              <div
                className="partner-counter partners-animation-4"
                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
              >
                <div className="counter">40+</div>
                <p className="counter-name">Partners</p>
              </div>
              <div
                className="transactions-counter partners-animation-4"
                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
              >
                <div className="counter">50M+</div>
                <p className="counter-name">Transactions</p>
              </div>
              <div
                className="tps-counter partners-animation-4"
                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
              >
                <div className="counter">750 tps</div>
                <p className="counter-name">Peak Throughput Capacity</p>
              </div>
            </div>
            <img
              src="https://www.safegold.com/assets/images/partnerships/bg-mandala.svg"
              alt
              className="bg-white-mandala"
              style={{ width: "340px" }}
            />
          </section>

          {/* section-6 */}
          <section className="testimonials-section testimonials-section-desktop homepage-section-6">
            <h1
              className="testimonials-animation-1"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              Testimonials
            </h1>
            <div className="testimonials-container row">
              {/* Card 1 */}
              <div
                className="card-item col col-12 col-lg-6 testimonials-animation-2"
                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
              >
                <div className="inner-item">
                  <div className="bank image-container">
                    <img
                      src="https://www.safegold.com/assets/images/partnerships/federal-bank-logo.svg"
                      alt
                    />
                  </div>
                  <div className="quote image-container">
                    <img src="https://www.safegold.com/assets/images/partnerships/quote.svg" alt />
                  </div>
                  <div>
                    <p>
                      Thanks to the entire MkGold team for supporting us in
                      going live. It has been a very pleasant experience working
                      with you and looking forward to a long journey with
                      continued support.
                    </p>
                    <div className="author">
                      <div>
                        <p className="author-name">Jithesh P V</p>
                        <p className="author-position">
                          Dy. Vice President &amp; Head Digital Centre of
                          Excellence, Federal Bank
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card 2 */}
              <div
                className="card-item col col-12 col-lg-6 testimonials-animation-2"
                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
              >
                <div className="inner-item">
                  <div className="bank image-container">
                    <img
                      src="https://www.safegold.com/assets/images/partnerships/fisdom-logo.svg"
                      alt
                    />
                  </div>
                  <div className="quote image-container">
                    <img src="https://www.safegold.com/assets/images/partnerships/quote.svg" alt />
                  </div>
                  <div>
                    <p>
                      The concept of digital gold sits wonderfully between
                      physical gold and gold etf or bonds and with MkGold we
                      have an amazing product to offer gold as an asset class to
                      our customers. Integration with APIs is seamless and we
                      have a partner whose product, tech and people support are
                      superb.
                    </p>
                    <div className="author">
                      <div>
                        <p className="author-name">Mayank Lavania</p>
                        <p className="author-position">
                          Product Manager, Fisdom
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card 3 */}
              {/* <div class="card-item col col-12 col-lg-4 testimonials-animation-2">
        <div class="inner-item">
          <div class="bank image-container">
            <img src="/assets/images/partnerships/federal-bank-logo.svg" alt="" />
          </div>
          <div class="quote image-container">
            <img src="/assets/images/partnerships/quote.svg" alt="" />
          </div>
          <div>
            <p>
              Thanks to the entire SafeGold team
              for supporting us in going live. It
              has been a very pleasant experience
              working with you and looking forward
              to a long journey with continued
              support.
            </p>
            <div class="author">
              <div>
                <p class="author-name">
                  Jithesh P V
                </p>
                <p class="author-position">
                  Dy. Vice President & Head
                  Digital Centre of
                  Excellence, Federal Bank
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Partnerpages;
