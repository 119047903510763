import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Refundpolicypage from "../components/Refundpolicypage";

const Refundpolicy = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Header />
      <Refundpolicypage/>
      <Footer/>
    </>
  );
};

export default Refundpolicy;
