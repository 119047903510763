import React from "react";
import "../assets/style/style.css";
import logo from "../assets/images/logo/mk-gold.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Signin from "./Signin";

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(true);
  };

  return (
    <>
      <nav
        className="navbar ng-scope"
        ng-controller="NavCtrl"
        ng-init="fetchData()"
      >
        <div className="nav-toggle-logo-container">
          <span
            className="navbar-toggle navbar-toggle-left"
            id="js-navbar-open"
          >
            <img
              src="/assets/sg-ui-v2/new-landingpage/images/pages/partners/menu.svg"
              alt=""
            />
          </span>
          <Link to={"/"} className="logo">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <button
          className="primary-button login-button-mobile close-float-bar"
          data-toggle="modal"
          data-target="#loginModal"
          data-backdrop="static"
          data-keyboard="false"
        >
          Login
        </button>
        <ul className="main-nav" id="js-menu">
          <li>
            <Link to={"/jewellery-ex"} className="nav-links">
              Jewellery Exchange
            </Link>
          </li>
          <li>
            <Link to={"/"} className="nav-links active">
              For Customers
            </Link>
          </li>
          <li>
            <Link
              to={"/gold-delivery"}
              className="nav-links"
              ng-class="{active:activePblickDeliveryLink}"
            >
              Buy Coins
            </Link>
          </li>
          <li>
            <Link
              to={"/lease"}
              className="nav-links text-shine"
              ng-class="{active:activeGoldLendingLink}"
            >
              Lease
            </Link>
          </li>

          <li>
            <Link to={"/partner-with-us"} className="nav-links">
              Partner With Us
            </Link>
          </li>

          <li>
            <Link
              to={"/about-us"}
              className="nav-links"
              ng-class="{active:activeAboutUsLink}"
            >
              About Us
            </Link>
          </li>
          <li>
            <a
              href
              type="button"
              className="nav-links"
              data-toggle="modal"
              data-target="#loginModal"
              data-backdrop="static"
              data-keyboard="false"
              onClick={handleClick}
            >
              Login
            </a>
          </li>
          <li>
            <a href className="nav-links sign-up-button">
              <button
                data-toggle="modal"
                data-target="#loginModal"
                data-backdrop="static"
                data-keyboard="false"
                onClick={handleClick}
              >
                Signup
              </button>
              {show && <Signin show={show} setShow={setShow}/>}
            </a>
          </li>
        </ul>
        <div className="mobile-nav-container">
          <div className="logo-button-container">
            <a href="/" className="logo"></a>
            <span className="navbar-toggle" id="js-navbar-close">
              <img
                src="/assets/sg-ui-v2/new-landingpage/images/components/navbar/menu.svg"
                alt=""
              />
            </span>
          </div>
          <div className="mobile-logo-container">
            <a href>
              <img
                src="/assets/sg-ui-v2/new-landingpage/images/components/navbar/logo-mobile.svg"
                alt=""
              />
            </a>
          </div>
          <ul className="main-nav-mobile">
            <li className="js-content">
              <a href="/jewellery-exchange">Jewellery Exchange</a>
            </li>
            <li className="js-content">
              <a href="/" ng-class="{active:activeHomeLink}" className="active">
                For Customers
              </a>
            </li>
            <li className="js-content">
              <a
                href="/gold-delivery"
                ng-class="{active:activePblickDeliveryLink}"
              >
                Buy Coins
              </a>
            </li>
            <li className="js-content">
              <a
                className="text-shine"
                style={{ textAlign: "center" }}
                href="/gains"
                ng-class="{active:activeGoldLendingLink}"
              >
                Lease
              </a>
            </li>

            <li className="js-content">
              <a href="/partnerships">Partner With Us</a>
            </li>
            <li className="js-content">
              <a
                href="/about-us"
                className
                ng-class="{active:activeAboutUsLink}"
              >
                About Us
              </a>
            </li>
            <li className="js-content">
              <a href="https://blog.safegold.com/" className target="_blank">
                Blog
              </a>
            </li>
            <li className="js-content">
              <a
                href="#contact__us"
                className="contact-us-button close-float-bar"
                data-toggle="modal"
                data-target="#loginModal"
                data-backdrop="static"
                data-keyboard="false"
              >
                Login
              </a>
            </li>
            <li className="js-content">
              <a href className="sign-up-button">
                <button
                  className="btn close-float-bar"
                  data-toggle="modal"
                  data-target="#loginModal"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  Signup
                </button>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
