import React from "react";
import "../assets/style/style.css";
import logo from '../assets/images/logo/mk-gold.jpg'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="light">
        <div className="footer-outer">
          <div className="footer-inner row">
            <div className="col col-12 col-md-3 column-2">
              <div className="logo-container image-container">
                <img height={'36px'}
                  className=" ls-is-cached lazyloaded"
                  alt
                  src={logo}
                />
              </div>
              <p>
                MkGold is an organised and transparent method of buying and
                accumulating 24K physical gold in compliance with all applicable
                laws and regulations.
              </p>
              <div className="other-logos-container">
                <div className="image-container logo-1">
                  <img
                    src="https://www.safegold.com/assets/sg-ui-v2/images/trustee-logos/vistra.svg"
                    alt="Vistra"
                    className="vistra ls-is-cached lazyloaded"
                  />
                </div>
                <div className="image-container logo-2">
                  <img
                    data-src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/footer-2.svg"
                    alt
                    className=" lazyloaded"
                    src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/footer-2.svg"
                  />
                </div>
              </div>
              <div className="copyright-text">
                ©
                <span className="year">
                  2023
                  <noscript>2019</noscript>
                </span>
                MkGold.com
              </div>
            </div>
            <div className="col col-5 col-md-2 column-3">
              <Link to={"/about-us"}>
                About Us
              </Link>
              <Link to={"/faq"}>
                FAQ
              </Link>
              <Link to={"/term-of-use"}>
                Terms Of Use
              </Link>
            </div>
            <div className="col col-7 col-md-3 column-4">
              <Link to={'/privacy-policy'}>
                Privacy Policy
              </Link>
              <Link to={'/refund-policy'} >
                Refund &amp; Cancellation Policy
              </Link>
              <Link to={'/grievance'}>
                Grievance
              </Link>
            </div>
            <div className="col col-12 col-md-4 column-5">
              <div className="address-item">
                <div className="image-container">
                  <img
                    data-src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/blue-footer-1.svg"
                    alt
                    className=" ls-is-cached lazyloaded"
                    src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/blue-footer-1.svg"
                  />
                </div>
                <div className="content-container">888 1000 800</div>
              </div>
              <div className="address-item">
                <div className="image-container">
                  <img
                    data-src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/blue-footer-2.svg"
                    alt
                    className=" ls-is-cached lazyloaded"
                    src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/blue-footer-2.svg"
                  />
                </div>
                <div className="content-container">care@mkgold.in</div>
              </div>
              <div className="address-item">
                <div className="image-container">
                  <img
                    data-src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/blue-footer-3.svg"
                    alt
                    className=" ls-is-cached lazyloaded"
                    src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/blue-footer-3.svg"
                  />
                </div>
                <div className="content-container">
                   New Delhi
                </div>
              </div>
              <div className="social-media-links">
                <a
                  href="https://www.facebook.com/SafeGoldIndia/"
                  target="_blank"
                >
                  <div className="icon-container">
                    <img
                      src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/facebook-blue.svg"
                      alt
                    />
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/company/safegold-india/"
                  target="_blank"
                >
                  <div className="icon-container">
                    <img
                      data-src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/linkedin-blue.svg"
                      alt
                      className=" lazyloaded"
                      src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/linkedin-blue.svg"
                    />
                  </div>
                </a>
                <a href="https://www.instagram.com/_safegold_/" target="_blank">
                  <div className="icon-container">
                    <img
                      data-src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/instagram-blue.svg"
                      alt
                      className=" lazyloaded"
                      src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/instagram-blue.svg"
                    />
                  </div>
                </a>
                <a href="https://twitter.com/safegold_india" target="_blank">
                  <div className="icon-container">
                    <img
                      data-src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/twitter-blue.svg"
                      alt2023
                      className=" lazyloaded"
                      src="https://d2swkjwe31rb4i.cloudfront.net/images/components/footer/twitter-blue.svg"
                    />
                  </div>
                </a>
              </div>
            </div>
            <div className="col col-12 column-6">
              <div className="copyright-text">
                ©
                <span className="year">
                  2023
                  <noscript>2019</noscript>
                </span>
                MkGold.com
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
