import React from "react";
import "../assets/style/style.css";

const Privacy = () => {
  return (
    <>
      <div className="info-page">
        <div className="content-container">
          <div className="title-container">
            <h1 className="title">Privacy Policy</h1>
            <span className="divider" />
          </div>
          <div className="information-container">
            <p>
              We Digital Gold India Private Ltd., with corporate office at Birla
              Centurion, Century Mills, P B Marg, Worli, Mumbai - 400030
              hereinafter referred to as MkGold. At MkGold, we value your
              trust &amp; respect your privacy. This Privacy Policy provides you
              with details about the manner in which your data is collected,
              stored &amp; used by us. You are advised to read this Privacy
              Policy carefully. By visiting MkGold’s website/WAP
              site/applications you expressly give us consent to use &amp;
              disclose your personal information in accordance with this Privacy
              Policy. If you do not agree to the terms of the policy, please do
              not use or access MkGold website, WAP site or mobile
              applications. Note: Our privacy policy may change at any time
              without prior notification. To make sure that you are aware of any
              changes, kindly review the policy periodically. This Privacy
              Policy shall apply uniformly to MkGold desktop website, MkGold
              mobile WAP site &amp;MkGold mobile applications
            </p>
            <h2 className="subheading">General</h2>
            <p>
              We will not sell, share or rent your personal information to any
              3rd party or use your email address/mobile number for unsolicited
              emails and/or SMS. Any emails and/or SMS sent by MkGold will
              only be in connection with the provision of agreed services &amp;
              products and this Privacy Policy. Periodically, we may reveal
              general statistical information about MkGold&amp; its users,
              such as number of visitors, number and type of goods and services
              purchased, etc. We reserve the right to communicate your personal
              information to any third party that makes a legally-compliant
              request for its disclosure.
            </p>
            <h2 className="subheading">Personal Information</h2>
            <p>
              Personal Information means and includes all information that can
              be linked to a specific individual or to identify any individual,
              such as name, address, mailing address, telephone number, email
              ID, credit card number, cardholder name, card expiration date,
              information about your mobile phone, DTH service, data card,
              electricity connection, Smart Tags and any details that may have
              been voluntarily provide by the user in connection with availing
              any of the services on MkGold When you browse through MkGold,
              we may collect information regarding the domain and host from
              which you access the internet, the Internet Protocol [IP] address
              of the computer or Internet service provider [ISP] you are using,
              and anonymous site statistical data.
            </p>
            <h2 className="subheading">Use of Personal Information</h2>
            <p>
              We use personal information to provide you with services &amp;
              products you explicitly requested for, to resolve disputes,
              troubleshoot concerns, help promote safe services, collect money,
              measure consumer interest in our services, inform you about
              offers, products, services, updates, customize your experience,
              detect &amp; protect us against error, fraud and other criminal
              activity, enforce our terms and conditions, etc. We also use your
              contact information to send you offers based on your previous
              orders and interests. We may occasionally ask you to complete
              optional online surveys. These surveys may ask you for contact
              information and demographic information (like zip code, age,
              gender, etc.). We use this data to customize your experience at
              MkGold, providing you with content that we think you might be
              interested in and to display content according to your
              preferences.
            </p>
            <h2 className="subheading">Cookies</h2>
            <p>
              A “cookie” is a small piece of information stored by a web server
              on a web browser so it can be later read back from that browser.
              MkGold uses cookie and tracking technology depending on the
              features offered. No personal information will be collected via
              cookies and other tracking technology; however, if you previously
              provided personally identifiable information, cookies may be tied
              to such information. Aggregate cookie and tracking information may
              be shared with third parties.
            </p>
            <h2 className="subheading">Links to Other Sites</h2>
            <p>
              Our site links to other websites that may collect personally
              identifiable information about you. MkGold is not responsible
              for the privacy practices or the content of those linked websites.
            </p>
            <h2 className="subheading">Security</h2>
            <p>
              MkGold has stringent security measures in place to protect the
              loss, misuse, and alteration of the information under our control.
              Whenever you change or access your account information, we offer
              the use of a secure server. Once your information is in our
              possession we adhere to strict security guidelines, protecting it
              against unauthorized access.
            </p>
            <h2 className="subheading">Consent</h2>
            <p>
              By using MkGold and/or by providing your information, you
              consent to the collection and use of the information you disclose
              on MkGold in accordance with this Privacy Policy, including but
              not limited to your consent for sharing your information as per
              this privacy policy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
