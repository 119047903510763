import React, { useState } from "react";
import "../assets/style/style.css";
import { Modal } from "react-bootstrap";
import Otpvarification from "./Otpvarification";

const Signin = ({show, setShow}) => {
  const handleClose = () => setShow(false);

  const [inputData, setInputData] = useState({
    mobile_no: ''
  });

  const [showOtp, setShowOtp] = useState(false);

  const handleChanges = (e) => {
    const result = e.target.value;
    setInputData({ ...inputData, [e.target.name]: result });
  };

  const handleCloseOtp = () => {
    setShowOtp(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(inputData.mobile_no.length == 10) {
      setShowOtp(true);
      // setShow(false);
    } else {
      alert('Enter correct mobile number');
    } 
  } 

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div className="modal-content">
          <div className="modal-header" style={{ borderBottom: "0px" }}>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
            >
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/images/ico/close-button.svg"
                alt
              />
            </button>
          </div>
          <div className="modal-body p-20">
            <div className="login-form" style={{ marginBottom: 20 }}>
              <h2>Login / Signup</h2>
              <div className="form-group" style={{ marginBottom: "11%" }}>
                <div className="form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="User"
                    name="loginRadio"
                    defaultValue="User"
                    checked
                  />
                  <label htmlFor="User" className="radio_lb">
                    <span />
                    Customer
                  </label>
                </div>
                <div className="form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="Partner"
                    name="loginRadio"
                    defaultValue="Partner"
                  />
                  <label htmlFor="Partner" className="radio_lb">
                    <span />
                    Partner
                  </label>
                </div>
              </div>

              <div ng-if="UserType=='User'" className="ng-scope">
                <form
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="csrf"
                    defaultValue="ov6jHvhltfA005hiwrFjt2RhoDgf7HaoUl5MgSSv"
                    autoComplete="off"
                  />
                  <div className="form-label-group">
                    <input
                      type="text"
                      id="inputMobile1"
                      name="mobile_no"
                      onChange={handleChanges}
                      className="form-control b-input"
                      placeholder="Enter Mobile Number"
                      required
                      autoComplete="off"
                    />
                    <label className="f-label" htmlFor="inputMobile">
                      Enter Mobile Number
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group" style={{ textAlign: "center" }}>
                    <button
                      className="btn btn-primary1 f-w-btn disabled-class"
                      style={{ marginTop: 24 }}
                      // disabled="disabled"
                    >
                      <span ng-if="!isProcessing" className="btn-txt ng-scope">
                        Proceed
                      </span>
                    </button>
                  </div>
                  <div className="checkbox_fld">
                    <label className="container_checkbox">
                      <input
                        type="checkbox"
                        // defaultChecked="checked"
                        required
                        style={{marginRight: '7px'}}
                      />
                      <span
                        className="checkmark"
                        style={{ paddingBottom: 15 }}
                      />
                    </label>
                    <span>
                      By proceeding you agree to our{" "}
                      <a
                        className="terms t-n-c"
                        style={{ color: "#BF9F41" }}
                        href="/terms-of-use"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>{" "}
                    </span>
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal style={{marginLeft: '-7px'}} show={showOtp} onHide={handleCloseOtp}>
        <div className="modal-content">
          {/* ngIf: otpVerify */}
          <div
            className="modal-header ng-scope"
            style={{ borderBottom: "0px" }}
            ng-if="otpVerify"
          >
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleCloseOtp}
            >
              <img src="https://www.safegold.com/assets/sg-ui-v2/images/ico/close-button.svg" alt />
            </button>
          </div>
          {/* end ngIf: otpVerify */}
          <div className="modal-body">
            {/* ngIf: otpVerify */}
            <div className="otp-form ng-scope" ng-if="otpVerify">
              <h2 style={{}}>Verify OTP</h2>
              <div>
                <p
                  style={{ margin: "8% 0%", marginBottom: "11%", fontSize: 18 }}
                >
                  <span style={{ color: "#B2B1B1" }}>
                    {" "}
                    Verify Mobile Number
                  </span>
                  <span className="user_number ng-binding" style={{margin: '0 6px'}}>8888888888</span>
                  {/* ngIf: userMobile */}
                  <a
                    type="button"
                    style={{color: '#00bbb4', textDecoration: 'underline'}}
                    onClick={handleCloseOtp}
                    className="change-number ng-scope"
                  >
                    Change
                  </a>
                  {/* end ngIf: userMobile */}
                </p>
              </div>
              <div className="form-group">{/* ngIf: otpResend */}</div>
              {/* ngIf: onPinForget */}
              {/* ngIf: !onPinForget */}
              <form
                id="main-contact-form2"
                ng-submit="userType=='User'?sendLoginOtp(otpObj,user_id):sendPartnerLoginOtp(otpObj,user_id,'https://partner-settings.safegold.com','SGDATAENCRYPTED')"
                name="otpForm"
                ng-if="!onPinForget"
                noValidate
                className="ng-pristine ng-scope ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength"
              >
                <div className="form-group">
                  <div className="form-label-group">
                    <input
                      type="tel"
                      ng-model="otpObj.otp"
                      id="inputOtp4"
                      name="user_otp"
                      numbers-only
                      maxLength={6}
                      minLength={6}
                      className="form-control b-input inputOtp ng-pristine ng-empty ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength ng-touched"
                      placeholder="Enter OTP"
                      required
                      autoComplete="off"
                    />
                    <label className="f-label" htmlFor="inputOtp4">
                      Enter One Time Password (OTP)
                    </label>
                  </div>
                </div>
                <div className="form-group">{/* ngIf: errorMessagebool */}</div>
                <div>
                  <p style={{ margin: "8% 0%" }}>
                    <span id="resendTimerId" className="resend-link">
                      Didn’t receive OTP?
                      <span
                        className="disabled-timer resend-link"
                        id="otpTimer"
                        style={{ display: "none" }}
                      >
                        Resend in
                        <span id="timer" style={{ marginTop: "-2%" }}>
                          <span
                            className="timer-box red"
                            style={{ color: "red" }}
                          >
                            <i className="fa fa-exclamation-triangle" /> OTP
                            expired
                          </span>
                        </span>
                      </span>
                      <span
                        className="resend_otp"
                        ng-click="resendLoginOtp(user_id,mobile_no,email,otpObj)"
                        id="resendOtp"
                        style={{margin: '0 6px' ,textDecoration: 'underline'}}
                      >
                        {/* <img
                          src="/assets/sg-ui-v2/images/ico/replay-arrow.svg"
                          style={{ marginTop: "-2px" }}
                          alt
                        />{" "} */}
                        Resend OTP
                      </span>
                    </span>
                  </p>
                </div>
                {/* ngIf: email */}
                <div className="form-group" style={{ textAlign: "center" }}>
                  <button
                    className="btn btn-primary1 f-w-btn disabled-class"
                    ng-disabled="otpForm.$invalid || IsDisabled || verifyOtpDisabled || isProcessing"
                    ng-class="{'disabled-class': otpForm.$invalid || verifyOtpDisabled || isProcessing }"
                    disabled="disabled"
                  >
                    {/* ngIf: !isProcessing */}
                    <span ng-if="!isProcessing" className="btn-txt ng-scope">
                      Verify{" "}
                    </span>
                    {/* end ngIf: !isProcessing */}
                    {/* ngIf: isProcessing */}
                  </button>
                </div>
              </form>
              {/* end ngIf: !onPinForget */}
              <div
                id="warning_box"
                style={{
                  display: "none",
                  borderRadius: 6,
                  border: "1px dashed #D3C79E",
                  background: "#FFF8E2",
                  margin: "10px 0",
                  padding: 10,
                }}
              >
                <p
                  id="warning_box_msg"
                  style={{ color: "#C4A646", lineHeight: "1.2", margin: 0 }}
                  className="ng-binding"
                ></p>
              </div>
              {/* ngIf: user_email &&!email && showOtpOnEmail && isUserRegisterd */}
            </div>
            {/* end ngIf: otpVerify */}
            {/* ngIf: otpVerified */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Signin;
