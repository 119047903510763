import React from "react";
import "../assets/style/style.css";

const Deliverysec = () => {
  return (
    <>
      <section className="delivery-section-1 delivery-section__2 m-t-5 mobile-t-15 row">
        <div className="delivery-container-1 col-sm-12 col-md-12">
          <div className="pincode-wrapper">
            <h4 className="p-h4">All Products</h4>
            <div className="pin-input">
              <div className="form-group">
                <img
                  src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/black-loc-pointer.svg"
                  alt
                />
                <input
                  type="tel"
                  placeholder="Enter your pincode"
                  numbers-only
                  required
                  ng-model="userdata.pincode"
                  maxLength={6}
                  minLength={6}
                  className="ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength"
                />
                {/* ngIf: loadInput */}
                <span
                  ng-click="getPincode(userdata.pincode)"
                  ng-class="{'disabled-pin':userdata.pincode.length != 6}"
                  className="disabled-pin"
                >
                  Check availability
                </span>
              </div>
              <p className="deliverable-span"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Deliverysec;
