import React from "react";
import "../assets/style/style.css";
import Footer from "./Footer";
import Faqquestion from "./Faqquestion";

const Jewellerysec3 = () => {
  return (
    <>
      <section className="row grid-section homepage-section-3 mb-2">
        <div
          className="col col-12 grid-section__heading"
          style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
        >
          Our Jewellery Partners
        </div>
        <div className="image-container">
          <div className="column-1">
            <a href="" target="_blank">
              <img srcSet="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/candere.svg" />
            </a>
          </div>
          <div className="column-2">
            <a className="tanishq" href="" target="_blank">
              <img srcSet="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/tanishq.png" />
            </a>
          </div>
          <div className="column-3">
            <a href="" target="_blank">
              <img srcSet="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/caratlane.svg" />
            </a>
          </div>
        </div>
        <div className="col-12 heading" style={{ marginTop: "2.4rem" }}>
          Get Jewellery in Exchange for your Digital Gold
        </div>
        {/* tab */}
        {/* <div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Home
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Profile
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Contact
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              ...
            </div>
          </div>
        </div> */}
        {/* tab */}
        <div className="tab-container">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li
              className="nav-item waves-effect waves-light"
              role="presentation"
            >
              <a
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                style={{ fontSize: "2.3rem" }}
              >
                <img
                  className="active"
                  src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/online-active.svg"
                  alt
                />
                <img
                  className="inactive"
                  src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/online-inactive.svg"
                  alt
                />
                Online
              </a>
            </li>
            <li className="nav-item waves-effect waves-light">
              <a
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                style={{ fontSize: "2.2rem" }}
              >
                <img
                  className="active"
                  src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/offline-active.svg"
                  alt
                />
                <img
                  className="inactive"
                  src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/offline-inactive.svg"
                  alt
                />
                Offline
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="onlineTab-container">
                <div className="online-exchange-steps">
                  <div className="steps step-1">
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/visit-website.svg"
                      alt="Step 1"
                    />
                    <h3>
                      <span>1.</span> Visit the Website
                    </h3>
                    <p>
                      Visit our partner’s website and select any jewellery of
                      your choice.
                    </p>
                  </div>
                  <div className="steps step-2">
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/select-safegold.svg"
                      alt="Step 1"
                    />
                    <h3>
                      <span>2.</span> Select MkGold
                    </h3>
                    <p>
                      Select MkGold from payment method section. Enter
                      registered mobile number and OTP for verification.
                    </p>
                  </div>
                  <div className="steps step-3">
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/pay-remaining.svg"
                      alt="Step 1"
                    />
                    <h3>
                      <span>3.</span> Pay Remaining
                    </h3>
                    <p>
                      Share the amount of gold you want to trade for the
                      jewellery and checkout by paying any remaining balance.
                    </p>
                  </div>
                </div>
                <a
                  href
                  className="button ng-scope"
                  data-toggle="modal"
                  data-target="#loginModal"
                  data-backdrop="static"
                  data-keyboard="false"
                  title="Exchange on Partner’s Website"
                  ng-click="isSignUpModal=true"
                  ng-controller="loaderCtrl"
                >
                  
                  <span
                    data-toggle="modal"
                    data-target="#loginModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    ng-if="!isSignUpModal"
                    className="ng-scope"
                  >
                    Exchange on Partner’s Website
                  </span>
                  
                </a>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="offlineTab-container">
                <div className="offline-exchange-steps">
                  <div className="steps step-1">
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/visit-store.svg"
                      alt="Step 1"
                    />
                    <h3>
                      <span>1.</span> Visit the Store
                    </h3>
                    <p>
                      Visit your nearest Caratlane store to pick out the
                      jewellery of your choice.
                    </p>
                  </div>
                  <div className="steps step-2">
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/share-number.svg"
                      alt="Step 1"
                    />
                    <h3>
                      <span>2.</span> Share Registered Number
                    </h3>
                    <p>
                      Share your registered mobile number with the cashier at
                      the store as well as the number of grams you’d like to
                      utilise of your MkGold balance.
                    </p>
                  </div>
                  <div className="steps step-3">
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/pages/jewellery-exchange/pay-offline.svg"
                      alt="Step 1"
                    />
                    <h3>
                      <span>3.</span> Pay Remaining
                    </h3>
                    <p>
                      Share the amount of gold you want to trade for the
                      jewellery and checkout by paying any remaining balance.
                    </p>
                  </div>
                </div>
                <a
                  href="/offline-stores"
                  className="button"
                  title="Find a store near you"
                  ng-click="isOfflineModal= true"
                >
                 
                  <span ng-if="!isOfflineModal" className="ng-scope">
                    Find a store near you
                  </span>
                  
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Faqquestion />
    </>
  );
};

export default Jewellerysec3;
