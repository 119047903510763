import React from "react";
import '../assets/style/style.css';

const Termofusepage = () => {
    
    return (
      <>
        <div className="info-page">
          <div className="content-container">
            <div className="title-container">
              <h1 className="title">Terms of Use</h1>
              <span className="divider" />
            </div>
            <div className="information-container">
              <ol className="terme_of_use_ol">
                {/* 1st */}
                <li>
                  <h2 className="subheading">INTRODUCTION</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      {" "}
                      <span>
                        THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE
                        INFORMATION TECHNOLOGY ACT, 2000 AND RULES THEREUNDER AS
                        APPLICABLE AND THE PROVISIONS PERTAINING TO ELECTRONIC
                        RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
                        INFORMATION TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD
                        IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE
                        ANY PHYSICAL OR DIGITAL SIGNATURES.
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span>
                        THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE
                        PROVISIONS OF RULE 3(1) OF THE INFORMATION TECHNOLOGY
                        (INTERMEDIARY GUIDELINES AND DIGITAL MEDIA ETHICS CODE)
                        RULES, 2021 THAT REQUIRES PUBLISHING THE RULES AND
                        REGULATIONS, PRIVACY POLICY AND TERMS OF USE FOR ACCESS
                        OR USAGE OF THE PLATFORM.
                      </span>
                    </li>
                  </ol>
                </li>
                {/* 2nd */}
                <li>
                  <h2 className="subheading">DEFINITIONS</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      For the purpose of these Terms, wherever the context so
                      requires, the term:
                      <ol className="terme_of_use_ol">
                        <li>
                          <strong>“Business Day”</strong> shall mean a day on
                          which scheduled commercial banks are open for normal
                          banking business in Mumbai but shall not include any
                          Saturday or Sunday or other days on which recognized
                          exchanges within the country are authorized or
                          obligated to remain closed.
                        </li>
                        <li>
                          <strong>“Customer”</strong> shall mean any person, who
                          as the counterparty transacts using the Platform for
                          buying Precious Metal, taking delivery of Precious
                          Metal and/or selling back the Precious Metal to DGIPL
                          or any other buyer as outlined in these Terms.
                        </li>
                        <li>
                          <strong>“Customer Account”</strong> shall mean the
                          account created whether by You or otherwise, in
                          accordance with these Terms.
                        </li>
                        <li>
                          <strong>“Customer Request”</strong> shall mean a
                          Delivery Request, Sale Request or Exchange Request
                          placed by You in relation to the Customer Precious
                          Metal.
                        </li>
                        <li>
                          <strong>“Currency Value”</strong> shall mean the value
                          of the Precious Metal as displayed on the Platform
                          from time to time by DGIPL.
                        </li>
                        <li>
                          <strong>“Force Majeure Event”</strong> shall mean any
                          event that is beyond the reasonable control of DGIPL
                          and shall include, without limitation, sabotage, fire,
                          flood, explosion, act of God, civil commotion,
                          strikes, lockouts or industrial action of any kind,
                          riots, insurrection, war, acts of government, computer
                          hacking, civil disturbances, unauthorised access to
                          computer data and storage device, computer crashes,
                          virus attacks, breach of security and encryption,
                          pandemic or any pandemic or disaster mitigation
                          related government responses such as lockdown, and any
                          other similar events not within the control of DGIPL
                          and which DGIPL is not able to overcome.
                        </li>
                        <li>
                          <strong>“Payment Instrument”</strong> shall mean any
                          electronic or written cheque, draft, money order or
                          other electronic or written instrument or order for
                          the transmission or payment of money, whether or not
                          the instrument is negotiable.
                        </li>
                        <li>
                          <strong>“Person”</strong> shall mean an individual, a
                          corporation, a partnership, a joint venture, a trust,
                          an unincorporated organization and any other legal
                          entity.
                        </li>
                        <li>
                          <strong>“Platform”</strong> shall mean, and include,
                          the mobile application and website by the name and
                          style of{" "}
                          <a href="https://www.MkGold.com/">
                            "www.MkGold.com"
                          </a>
                          , that the Customer accesses for the transactions,
                          including all contents, services and technology
                          offered through the Platform.
                        </li>
                        <li>
                          <strong>“MkGold Partner”</strong> shall mean any
                          entity facilitating collection of payment for Precious
                          Metal products or any other products and services
                          offered by DGIPL.
                        </li>
                        <li>
                          <strong>“Reward Program”</strong> means such reward
                          programs that may be made available to the Customers
                          from time to time at the sole discretion of DGIPL.
                        </li>
                        <li>
                          <strong>“Transfer”</strong> refers to a facility to
                          transfer Precious Metal from a Customer Account to
                          another Customer Account.
                        </li>
                        <li>
                          <strong>“Promotion”</strong> refers to any activity
                          that DGIPL MkGold undertakes to provide users that
                          meet certain requirements to avail certain rewards.
                        </li>
                        <li>
                          <strong>“Precious Metal”</strong> means physical gold,
                          silver and/or other precious metals in bullion form
                          (as the case may be), with purity as displayed on the
                          Platform or on a MkGold Partner Platform, in a
                          manner deemed fit by MkGold.
                        </li>
                      </ol>
                    </li>
                    <li>
                      In addition to the terms defined in Section 2.1,
                      additional terms used herein shall have the respective
                      meanings assigned thereto in the relevant sections
                      contained hereinafter.
                    </li>
                  </ol>
                </li>
                {/*3 rd*/}
                <li>
                  <h2 className="subheading">
                    TERMS AND CONDITIONS OF SERVICES BEING PROVIDED BY DGIPL
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Digital Gold India Private Limited, a company incorporated
                      under the Companies Act, 2013 with its registered office
                      at CoWrks, 2nd &amp; 3rd Floor, Birla Centurion, Century
                      Mills, P B Marg, Worli, Mumbai 400030, ("
                      <strong>DGIPL</strong>") will be selling Precious Metal
                      and providing services in relation to (a) purchase and
                      sale of Precious Metal; (b) safe keeping, vaulting,
                      delivery and fulfilment of Precious Metal; and (c) other
                      such other related services as may notified by MkGold
                      from time to time, to the Customers, on or through the
                      Platform (“<strong>Services</strong>”).
                    </li>
                    <li>
                      The Precious Metal is being offered for purchase and/or
                      sale by DGIPL under its brand name “
                      <strong>MkGold</strong>”. The Services are being
                      provided by DGIPL, however DGIPL may utilise any third
                      party external payment gateway service (“
                      <strong>Payment Gateway Partner</strong>”) for
                      facilitating the collection of payments for the Services
                      on its Platform. The Payment Gateway Partner assumes no
                      liability for the Services except for providing payment
                      services and reasonable customer support in addressing the
                      queries related to payments made. Any and all of the
                      transactions relating to the Services are being rendered
                      by DGIPL in association with the Intermediaries (namely
                      the Security Trustee and Vault Keeper) with whom separate
                      agreements have been entered into by DGIPL.
                    </li>
                    <li>
                      The Customers are advised to read and understand these
                      Terms carefully before using the Services.
                    </li>
                    <li>
                      DGIPL does not guarantee any returns, on any transactions
                      consummated vis-à-vis the Platform, to any person, either
                      directly or indirectly. The Customer (hereinafter referred
                      to as “You”, with the term “Your” being construed
                      accordingly) shall be solely responsible for undertaking
                      appropriate and effective due diligence and related
                      analysis before undertaking any transactions pursuant to
                      these Terms of Use. You further acknowledge and agree that
                      DGIPL and its officers, directors, employees, agents and
                      affiliates will have no liability for Your purchase or
                      other decisions using the Platform.
                    </li>
                    <li>
                      The Services by DGIPL shall be provided for a term
                      commencing from the date of creation of the Customer
                      Account.
                    </li>
                    <li>
                      You understand and acknowledge that the Services are being
                      provided and made available on an “AS IS” and “AS
                      AVAILABLE” basis. The Platform may contain errors or
                      inaccuracies that could cause failures, corruption or loss
                      of data and/or information from your device and/or any
                      device used by You to connect to the Platform as well as
                      from peripherals (including, without limitation, servers
                      and computers) connected to any of the above-mentioned
                      devices. You assume all risks and costs associated with
                      your use of the Services and the Platform, including
                      without limitation, any costs incurred for the use of your
                      device and any damage to any equipment, software or data.
                    </li>
                    <li>
                      You confirm that You are not registered under the Goods
                      and Services Tax Act, 2017 and that the responsibility for
                      any compliance or obligations associated with your status
                      under the Goods and Service Tax Act, 2017 lie solely with
                      You.
                    </li>
                    <li>
                      DGIPL, at any time and at its sole discretion, may change
                      the specifications of any functionality/feature utilized
                      by individuals on the Platform. DGIPL will not be liable
                      for any inconvenience caused due to such changes in or
                      discontinuation of any functionality/feature.
                    </li>
                    <li>
                      DGIPL reserves the right to start offering, pause,
                      terminate and/or discontinue any Reward Program which may
                      be offered via the Platform. Every Customer will be bound
                      by the terms and conditions for each Reward Program and
                      such programs will be subject to these Terms.
                    </li>
                    <li>
                      Only natural persons above the age of 18, being an Indian
                      resident and of sound mind (unless the specific Reward
                      Program rules or other promotional materials or
                      announcements specify a different geographic region), are
                      eligible to participate in any Reward Program and claim
                      rewards under it. Each natural person will be eligible to
                      claim a reward via any functionality/feature being offered
                      by DGIPL only once. Unless otherwise stated, an individual
                      may not win more than one reward per promotion and we
                      reserve the right to withhold or reclaim any subsequent
                      reward. Any person, found claiming rewards per promotion
                      more than once on the Platform in breach of the terms of
                      the Reward Program and these Terms, either in his/ her
                      sole capacity or in consortium with others, for such
                      instances, DGIPL will immediately suspend any activity on
                      the Customer Accounts (where created by the individuals)
                      and take legal action against such erring individuals, as
                      may be available to DGIPL under law. Any individual using
                      multiple accounts to claim multiple promotions and rewards
                      will lose their right to participate in the said Reward
                      Program or any future Reward Program that DGIPL may offer
                      to its Customers or potential patrons.
                    </li>
                    <li>
                      DGIPL will not be held liable, monetarily or otherwise,
                      where it decides to suspend, withdraw or discontinue any
                      Reward Program.
                    </li>
                    <li>
                      An individual who is prohibited by law to hold or purchase
                      any asset, including any Precious Metal will not be
                      entitled to participate in any Reward Program or receive
                      any reward under it.
                    </li>
                    <li>
                      DGIPL has the right to ask Customers and individuals for
                      additional KYC information, such as a PAN card before
                      processing any reward under a Reward Program.
                    </li>
                    <li>
                      The Customers and/or individuals participating in any
                      Reward Program agrees to indemnify, defend and hold DGIPL
                      and its representatives and agents harmless from and
                      against any and all third-party claims, demands,
                      liabilities, costs or expenses, including attorney’s fees
                      and costs, arising from, or related to any breach by the
                      Customer and/or any individual of any of the Reward
                      Program terms and conditions, these Terms or any violation
                      by them of any applicable law.
                    </li>
                    <li>
                      Except for any fraud by DGIPL proven in a court of law, by
                      participating in any Reward Program, Customers and/ or
                      individuals release DGIPL, its parent company,
                      subsidiaries, affiliates, suppliers, advertising and
                      promotions agencies and their respective directors,
                      officers, employees, and agents (collectively, “Released
                      Parties”) from any and all liability for any loss, harm,
                      damages, cost or expense, including, without limitation,
                      property damages, personal injury and/or death, arising
                      out of or in any way connected to the Reward Programs or
                      the use of any rewards claimed.
                    </li>
                    <li>
                      In the event of any dispute, between DGIPL and any
                      individual who has participated in any Reward Program,
                      relating to any Reward Program terms and conditions, the
                      decisions made by DGIPL shall be final binding on the
                      participant.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    TRUSTEE, INTERMEDIARIES AND SAFE KEEPING ARRANGEMENT
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Appointment of Intermediaries
                      <ol className="terme_of_use_ol">
                        <li>
                          DGIPL may from time to time appoint intermediaries who
                          shall assist DGIPL in providing the Services to You (“
                          <strong>Intermediaries</strong>”). The term, “
                          <strong>Intermediaries</strong>” shall mean the
                          Trustee Administrator, Vault Keeper and shall include
                          any and all Persons appointed by DGIPL or the Trustee
                          Administrator (as the case may be) upon placement of a
                          Customer Order (and successful payment of monies in
                          lieu thereof) till the consummation of the Customer
                          Requests placed by You in accordance with these Terms.
                          In the event that the Trustee Administrator is
                          required to discharge their obligations under any
                          agreement entered into with DGIPL (“
                          <strong>Trustee Administrator Agreement</strong>”),
                          You hereby consent to the appointment of such
                          Intermediaries, for and on your behalf, by DGIPL or
                          the Trustee Administrator (as the case may be).
                        </li>
                        <li>
                          You acknowledge and understand that these
                          Intermediaries have been appointed to ensure that your
                          Customer Orders/Customer Requests are duly complied
                          with in accordance with these Terms. You additionally
                          acknowledge that certain payments will have to be made
                          to these Intermediaries for their appointment and
                          services in relation thereto, which shall be borne by
                          DGIPL on your behalf unless otherwise indicated in
                          these Terms.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Appointment of Trustee Administrator
                      <ol className="terme_of_use_ol">
                        {/* <li>You hereby acknowledge and agree that in order to ensure that Your Customer Orders/Customer Requests are fulfilled in all circumstances, a first and exclusive charge on the Customer Gold by way of hypothecation will be created in favour of IDBI Trusteeship Services Limited or any successor Person (“<strong>Security Trustee</strong>”).</li> */}
                        <li>
                          You hereby acknowledge and agree that the Customer
                          Precious Metal will be monitored by the Trustee
                          Administrator while being held on your behalf with the
                          Vault Custodian.
                        </li>
                        <li>
                          By accepting these Terms, You further agree to accede
                          to the terms for such arrangement with the Trustee
                          Administrator (i.e., a Trustee Administrator
                          Agreement).
                        </li>
                        <li>
                          In the event of any expenses or charges remaining
                          payable to any Intermediaries or otherwise till actual
                          delivery or fulfilment of any of Your Customer
                          Orders/Customer Requests for any reason, including
                          where DGIPL is unable to service such requests as
                          enumerated in Clause 8 below (Events of Default),
                          thereby adversely effecting or jeopardizing the
                          fulfilment of Your Customer Orders/Customer Requests,
                          then the Trustee Administrator will be entitled to
                          sell part of the Customer Precious Metal and satisfy
                          such outstanding expenses or charges as required in
                          accordance with these Terms read with the Trustee
                          Administrator Agreements. The sums due to You and/or
                          Precious Metal to be delivered (as the case may be),
                          after settling the aforementioned charges, would be
                          dealt with in accordance with these Terms read with
                          the relevant Trustee Administrator Agreements.
                        </li>
                        <li>
                          By way of these Terms, You authorize the Trustee
                          Administrator to act on Your behalf to ensure that
                          Your interests are adequately protected.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Safe Keeping/Vaulting of Precious Metal
                      <ol className="terme_of_use_ol">
                        <li>
                          Customer Precious Metal purchased by You corresponding
                          to the Customer Order shall be stored with a custodian
                          in a vault on your behalf (“
                          <strong>Vault Keeper</strong>”).
                        </li>
                        <li>
                          The Precious Metal stored shall be in the form of
                          bullion bars which are at a minimum, equivalent to
                          99.5% purity, or higher. The total amount of gold
                          stored shall be based on calculation of 24 Karat gold
                          bars corresponding to the Customer Order, and the bars
                          stored may be in purities of 99.5%, 99.9% or 99.99% or
                          other terms.
                          <br />
                          <span style={{ fontStyle: "italic" }}>
                            Illustration
                          </span>{" "}
                          :&nbsp;if a customer purchases 1gm of 99.99% pure
                          gold, at least 1.0049 gms of 99.5% purity gold will be
                          stored for that customer in the vault.
                        </li>
                        <li>
                          While the Vault Keeper has taken the necessary
                          insurance policy/ies, in case of occurrence of an
                          event not covered in such insurance policy/ies, the
                          Customer Precious Metal may be at risk. The insurance
                          policy/ies obtained by the Vault Keeper are in line
                          with the global industry practices and cover losses
                          due to fire, lightning, theft, cyclone, earthquake,
                          flood, etc. but do not cover losses due to events such
                          as war, revolution, derelict weapons of war, nuclear
                          radiation, etc.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">STORAGE OF PRECIOUS METAL</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You will be provided with free storage for your Customer
                      Precious Metal for 5 (Five) years from the date of placing
                      of the Customer Order, or for such period as more
                      particularly stipulated by DGIPL in this regard from time
                      to time at its sole discretion and notified to Customers
                      on the Platform (“<strong>Free Storage Period</strong>”).
                    </li>
                    <li>
                      After the expiry of the Free Storage Period, DGIPL shall
                      be entitled to levy storage charges for such Customer
                      Precious Metal at such rate as would be specified on the
                      Platform, and which may be revised from time to time. The
                      charges would be levied by deducting the Precious Metal
                      balance at the end of each month by a percentage amount at
                      the specified rate.
                    </li>
                    <li>
                      You are advised to periodically check the Platform to
                      understand these storage charges. DGIPL shall be entitled
                      to sell such portion of your Customer Precious Metal
                      stored with the Vault Keeper necessary or required to
                      recover the unpaid storage charges in question.
                    </li>
                    <li>
                      You are required to take delivery of your Customer
                      Precious Metal within such maximum period as shall be
                      specified for this purpose by DGIPL from time to time on
                      the Platform (“<strong>Maximum Storage Period</strong>”).
                    </li>
                    <li>
                      For the purposes of making delivery to You, You are
                      required to provide a valid address and/or any other
                      documents/information/ biometric identification that may
                      be specified in this regard by DGIPL from time to time.
                      You may provide such address at any time during the
                      Maximum Storage Period.
                    </li>
                    <li>
                      In the event that no valid address has been provided by
                      You during the Maximum Storage Period, then DGIPL shall,
                      for a period 1 (One) year commencing from the date of
                      expiry of Maximum Storage Period (such period being the “
                      <strong>Grace Period</strong>”), attempt at least once to
                      contact You using the contact information provided by You
                      to obtain either (i) an address to which You would require
                      the Precious Metal in question to be delivered or (ii)
                      your bank account details into which sale proceeds of the
                      Customer Precious Metal shall be deposited.
                    </li>
                    <li>
                      In the event that DGIPL has not been able to contact You
                      during the applicable Grace Period using the contact
                      information provided by You or where You shall during the
                      Grace Period either fail to:
                      <ol className="terme_of_use_ol">
                        <li>
                          take delivery of the Customer Precious Metal in
                          question for any reason whosoever (including where You
                          shall not have provided any address to take delivery
                          of such gold); or
                        </li>
                        <li>
                          provide the details for a valid bank account into
                          which the proceeds of any sale of such Customer
                          Precious Metal are to be deposited;
                        </li>
                        then upon expiry of the applicable Grace Period for the
                        Customer Precious Metal in question, DGIPL shall
                        purchase such Customer Precious Metal with the purchase
                        price being the applicable prevailing price displayed on
                        the Platform for purchase of Precious Metal from
                        Customers.
                        <li>
                          The purchase proceeds realized from such sale (“
                          <strong>Final Sale Proceeds</strong>”) after deducting
                          any amounts payable to DGIPL as storage charges for
                          storage of such Precious Metal after the Free Storage
                          Period, shall be deposited into a bank account
                          operated by the Trustee Administrator who shall be the
                          sole signatory to such bank account.
                        </li>
                        <li>
                          In the event that You shall during a period of 3
                          (Three) years commencing from the date of expiry of
                          the applicable Grace Period (such period being the “
                          <strong>Final Claim Period</strong>”) notify either
                          DGIPL or the Trustee that You are claiming the
                          applicable Final Sale Proceeds, the Trustee shall
                          issue suitable instructions to transfer the Final Sale
                          Proceeds to such bank account as You shall notify for
                          this purpose. Please note that to claim the Final Sale
                          Proceeds You will be required to provide details of a
                          valid bank account and that the Final Sale Proceeds
                          will not be transferred in the absence of such
                          details. At no time will the Final Sale Proceeds be
                          paid to You in cash.
                        </li>
                        <li>
                          In the event that You shall not claim your Final Sale
                          Proceeds within the Final Claim Period, then the Final
                          Sale Proceeds shall be transferred to the Prime
                          Minister’s Relief Fund or such other fund as You may
                          designate for this purpose at any time prior to the
                          expiry of the Grace Period.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">FORCE MAJEURE</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      If performance under these Terms is prevented, restricted,
                      delayed or interfered with by reason of labour disputes,
                      strikes, acts of God, floods, lightning, severe weather,
                      shortages of materials, rationing, inducement of any
                      virus, Trojan or other disruptive mechanisms, any event of
                      hacking or illegal usage of the Platform, utility or
                      communication failures, earthquakes, pandemics, epidemics,
                      war, revolution, acts of terrorism, civil commotion, acts
                      of public enemies, blockade, embargo or any law, order,
                      proclamation, regulation, ordinance, demand or requirement
                      having legal effect of any government or any judicial
                      authority or representative of any such government, or
                      failure of any device used to access the Platform, or any
                      other act whatsoever, whether similar or dissimilar to
                      those referred to in this section, which are beyond the
                      reasonable control of DGIPL and could not have been
                      prevented by reasonable precautions then DGIPL shall be
                      discharged from such performance to the extent of and
                      during the period of such force majeure event. Such
                      non-performance by DGIPL shall, in no manner whosoever,
                      amount to a breach of its obligations.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    TERMINATION OF SERVICES BY DGIPL
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      DGIPL, in its sole discretion, may modify, suspend, or
                      terminate access to, all or any portion of the Platform,
                      or your ability to access any Services through the
                      Platform at any time for any reason including termination
                      for breach of any of these Terms or the Privacy Policy.
                    </li>
                    <li>
                      These Terms shall further stand terminated:
                      <ol className="terme_of_use_ol">
                        <li>
                          if DGIPL is adjudged bankrupt or declared insolvent;
                        </li>
                        <li>
                          if DGIPL ceases to carry on its business or has
                          communicated to the Trustee Administrator any
                          intention to cease to carry on its business with a
                          notice of less than 90 (Ninety) days in writing
                          acknowledged by the Trustee;
                        </li>
                        <li>
                          if DGIPL breaches any of the terms and conditions
                          under the Trustee Administrator Appointment Agreement
                          and/or these Terms (as the case may be) and DGIPL does
                          not remedy such breach within 90 (Ninety) days of
                          being called upon to do so by the Trustee;
                        </li>
                        <li>
                          Upon DGIPL commencing a voluntary proceeding under any
                          applicable bankruptcy, insolvency, winding up or other
                          similar applicable law now or hereafter in effect, or
                          consenting to the entry of an order for relief in an
                          involuntary proceeding under any such Applicable Law,
                          or consenting to the appointment or taking possession
                          by a receiver, liquidator, assignee (or similar
                          official) for the whole or a substantial part of its
                          property or takes any action towards its
                          re-organization, liquidation or dissolution;
                        </li>
                        <li>
                          upon an order being made for the winding up,
                          bankruptcy or dissolution of DGIPL, or an application
                          is admitted for initiating any corporate insolvency
                          resolution process against DGIPL in accordance with
                          Applicable Law;
                        </li>
                        <li>
                          upon any encumbrancer lawfully taking possession, or a
                          liquidator, judicial custodian, receiver,
                          administrative receiver or trustee or any analogous
                          officer having been appointed in respect of the whole
                          or a substantial part of the property of DGIPL, or an
                          attachment, sequestration, distress or execution (or
                          analogous process) being levied or enforced upon or
                          issued against whole or a substantial part of the
                          assets or property of DGIPL, or any action has been
                          taken or suffered against DGIPL towards liquidation or
                          dissolution or similar reorganization; or
                        </li>
                        <li>
                          upon a liquidator or provisional liquidator being
                          appointed to DGIPL or a receiver, receiver and
                          manager, trustee or similar official being appointed
                          in respect of DGIPL or any of its assets, or an event
                          analogous.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Upon the happening of any of the events referred to in
                      Clause 7.2 (“Events of Default” or “EOD”), and where there
                      is any insufficiency of DGIPL funds required to pay any
                      costs and expenses to be incurred in relation to providing
                      delivery of your Precious Metal to you, then in such an
                      event, you do hereby authorise the Trustee Administrator
                      to sell any part of the Customer Precious Metal that is
                      necessary or required to defray such costs and expenses.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    CONSEQUENCES OF TERMINATION OF SERVICES BY DGIPL MkGold
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Upon such termination for any reason whatsoever, subject
                      to these Terms and the agreement executed with the Trustee
                      Administrator:
                      <ol className="terme_of_use_ol">
                        <li>
                          The Trustee Administrator shall prepare a list of all
                          Customers holding a Precious Metals balance at the
                          time of the EOD (“<strong>EOD Customers</strong>”).
                        </li>
                        <li>
                          The Trustee Administrator shall be required to inform
                          each EOD Customer directly or through the MkGold
                          Partner from whom the EOD Customer purchased the
                          Precious Metal that an EOD has occurred, as well as
                          the process to be followed with respect to the
                          Customer Precious Metal (“<strong>EOD Notice</strong>
                          ”).
                        </li>
                        <li>
                          With respect to Customers who hold less than 1 (One)
                          gram of Customer Precious Metal as on such date, the
                          Customer Precious Metal of such Customers shall be
                          sold at prevailing market rates on a specified date,
                          with the money being remitted to the account
                          maintained by such Customers with the relevant
                          MkGold Partner (in case the Customer Precious Metal
                          is purchased from a distribution partner) or their
                          bank accounts directly, as may be the case; and
                        </li>
                        <li>
                          With respect to Customers who hold more than 1 (One)
                          gram of Customer Precious Metal as on such date, such
                          Customers shall have 30 (Thirty) days to pay the
                          requisite minting and delivery charges to the bank
                          account identified for this purpose and to request for
                          delivery of their Customer Precious Metal. In the
                          event the requisite minting and delivery charges as
                          well as request for delivery are not received from
                          such Customers, their Customer Precious Metal holding
                          shall be sold at prevailing market rates on the
                          specified date, with the money being remitted to the
                          account maintained with the relevant MkGold Partner
                          (in case the Customer Precious Metal is purchased from
                          a distribution partner) or their bank account.
                        </li>
                        The Trustee Administrator shall take the applicable
                        measures detailed above no more than 45 (Forty-five)
                        days from the EOD Notice.
                      </ol>
                    </li>
                    <li>
                      The Trustee Administrator shall be entitled to take such
                      other applicable measures as deemed fit by the Trustee
                      whilst ensuring compliance with the agreement executed
                      with MkGold.
                    </li>
                    <li>
                      Where the Trustee Administrator has not received
                      sufficient payment or delivery information from a Customer
                      in accordance these Terms by the specified date, the
                      Trustee Administrator shall sell such Customer’s Customer
                      Precious Metal at the prevailing market price on the
                      specified date and shall keep such money in an escrow
                      account for 1 (One) year for and on behalf of such
                      Customer (“<strong>EOD Sale Proceeds</strong>”) and
                      attempt to contact such Customer at least twice over the
                      said period of 12 (Twelve) months. In the event that such
                      EOD Customer does not claim his/her/its EOD Sale Proceeds
                      upon expiry of the aforementioned 1 (One) year period, the
                      Trustee shall transfer the monies held in escrow account
                      for the Customer in question to the Prime Minister’s
                      Relief Fund or such other fund as shall have been
                      designated for this purpose by the Customer in question
                      pursuant to these Terms.
                    </li>
                    <li>
                      You acknowledge that the termination of your access to the
                      Platform and Services may be affected without any prior
                      notice, and the Customer Account may be immediately
                      deactivated or deleted and all related information and/or
                      bar any further access to the Customer Account, the
                      Platform or the Services. Further, You agree that DGIPL
                      shall not be liable for any discontinuation or termination
                      of Services by any third party.
                    </li>
                    <li>
                      None of your content shall remain accessible on the
                      Platform upon termination, except as may be required under
                      Applicable Laws. This information cannot be recovered by
                      You, once the account is terminated.
                    </li>
                    <li>
                      The disclaimer of warranties, the limitation of liability,
                      and the governing law provisions shall survive any
                      termination of these Terms.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    GOVERNING LAW AND DISPUTE RESOLUTION
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      These Terms shall be governed by and interpreted and
                      construed in accordance with the laws of India. The courts
                      of Mumbai shall have exclusive jurisdiction over any
                      disputes arising under these Terms. In the event of any
                      dispute arising out of these Terms the same shall be
                      settled by a binding arbitration conducted by a sole
                      arbitrator, appointed jointly by both parties and governed
                      by the Arbitration and Conciliation Act, 1996. The venue
                      of arbitration shall be Mumbai, Maharashtra, India.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    CREATION OF CUSTOMER ACCOUNT AND REGISTRATION OBLIGATIONS
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Before availing the Services, the Customer shall complete
                      registration process as may be prescribed from time to
                      time. The Customer shall follow the instructions given in
                      the Platform for opening a Customer Account. DGIPL shall
                      be entitled to collect and store relevant information and
                      documents provided by the Customer to the Platform for KYC
                      purposes. As and when required by DGIPL, the Customer will
                      be required to provide additional documents for fulfilling
                      the KYC requirements. You authorize DGIPL to make such
                      enquiries as may be necessary to satisfy about the
                      validity of your identity. You shall be responsible for
                      the correctness of information provided to DGIPL. If You
                      have reasons to believe that there is an error in the
                      information furnished by You, You shall immediately
                      provide correct / updated information.
                    </li>
                    <li>
                      DGIPL reserves the right to terminate any Customer
                      Account, with or without notice to You, in the event the
                      KYC documents / information are found to be incorrect or
                      authenticity of the documents / information is found to be
                      doubtful. You hereby undertake to indemnify and keep
                      indemnified DGIPL against any and all losses, claims,
                      liabilities costs etc. which arise out of or relating to
                      Your failure to identify Yourself and validate Your
                      account promptly and / or due to incorrect KYC documents /
                      information.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">PLACING CUSTOMER ORDER </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Prior to placing an order, You may be required to provide
                      certain KYC documentation and other information as may be
                      required by DGIPL in such form and manner as it may deem
                      fit. This information may be requested from you at the
                      time of registration, or at a later stage.
                    </li>
                    <li>
                      Once such documentation and other information is provided
                      by You, You shall be entitled to place an order on the
                      Platform (“<strong>Customer Order</strong>”).
                    </li>
                    <li>
                      You agree that your continued use of the Platform,
                      consequent upon the creation of the Customer Account, is
                      subject to verification by DGIPL of the information and
                      documentation provided by You. You hereby grant DGIPL
                      permission to conduct such verification, in such form and
                      manner as it may deem fit.
                    </li>
                    <li>
                      You further acknowledge that DGIPL reserves the right for
                      such verification either on registration of the Customer
                      Account or at any time thereafter. You hereby authorise
                      DGIPL to engage with any third-party service provider for
                      verification of your KYC documentation to ascertain your
                      eligibility. Any processing undertaken by DGIPL to process
                      KYC documents shall be in accordance with its privacy
                      policy and these Terms. It is hereby clarified that as far
                      as the permission to access the KYC documents by a third
                      party service provider is concerned, the same shall be
                      governed by the privacy policy of such third party service
                      provider.
                    </li>
                    <li>
                      Customer’s Obligations:
                      <ol className="terme_of_use_ol">
                        <li>
                          You will be responsible for maintaining the
                          confidentiality of the Customer Account Information,
                          and shall be fully responsible for all activities that
                          occur under the Customer Account. You agree to
                          immediately notify DGIPL of any unauthorised use of
                          the Customer Account Information or any other breach
                          of security. DGIPL cannot and will not be liable for
                          any loss or damage arising from Your failure to comply
                          with this section. You may be held liable for losses
                          incurred by DGIPL or any user or visitor of the
                          Platform due to authorised or unauthorised use of the
                          Customer Account, as a result of your failure in
                          keeping the Customer Account Information confidential.
                        </li>
                        <li>
                          You shall ensure that the Customer Account Information
                          provided by You in the registration form is complete,
                          accurate and up-to-date. Use of another Customer’s
                          account information for availing of any of the
                          Services and/or for use of the Platform is expressly
                          prohibited.
                        </li>
                        <li>
                          You agree that if You provide any information that is
                          untrue, inaccurate, not current or incomplete, (or
                          becomes untrue, inaccurate, not current or incomplete)
                          or if DGIPL has reasonable grounds to suspect that
                          such information is untrue, inaccurate, not current,
                          incomplete, or not in accordance with these Terms,
                          DGIPL shall have the right to indefinitely suspend or
                          terminate or block access to the Customer Account on
                          the Platform and refuse to provide You with access to
                          the Platform.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">PURCHASE OF PRECIOUS METAL </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You can offer to buy Precious Metal worth Rs.1.00 (Rupee
                      One Only) and above incremental value thereof at the
                      Currency Value of Precious Metal shown on the Platform. It
                      is clarified however, that DGIPL or any MkGold Partner
                      may define a higher threshold for minimum purchase
                      quantity at their discretion.
                    </li>
                    <li>
                      It is hereby clarified that display of such Currency Value
                      of Precious Metal shall constitute fully binding offers
                      and would be an invitation to offer to purchase Precious
                      Metal at the said Currency Value to all Customers.
                      Notwithstanding the foregoing, You understand that the
                      Currency Value may vary multiple times within a day, and
                      accordingly your payment obligations for any Customer
                      Order will depend on the Currency Value then prevailing.
                      While reasonable efforts will be made to offer You a
                      competitive price for the Precious Metal, there is no
                      guarantee that the Currency Value offered to You will be
                      close to or comparable with other prices available in the
                      market.
                    </li>
                    <li>
                      Payment pursuant to the Customer Order, will be accepted
                      through the payment options made available on the
                      Platform, which may include redirection to a payment
                      gateway hosted by other third party Payment Gateway
                      Partner, including by DGIPL. At the time of purchase /
                      fulfilment / sale-back / transfer of Precious Metal, the
                      relevant taxes will be chargeable as applicable as per the
                      government regulations. It is hereby clarified that once a
                      Customer Order is placed, You are not entitled to cancel a
                      Customer Order, provided however that a Customer Order
                      shall stand cancelled if the payment fails for any reason
                      whatsoever.
                    </li>
                    <li>
                      DGIPL reserves the right to cancel a Customer Order, at
                      its sole discretion, if the information, provided by You
                      prior to placing the Customer Order, is not found
                      acceptable and MkGold is of the opinion that You are not
                      eligible to purchase the relevant Precious Metal. DGIPL
                      shall have the right to freeze the Customer Account until
                      it receives KYC and other documentation in a form and
                      manner satisfactory it.
                    </li>
                    <li>
                      Once the payments are received by DGIPL and the KYC
                      information, if requested, is found acceptable, DGIPL
                      shall issue an invoice to You confirming the Customer
                      Order placed within a period of 3 (Three) Business Days of
                      placing such Customer Order, in a manner it may deem fit.
                    </li>
                    <li>
                      Upon placing a valid Customer Order in accordance with
                      these Terms and the same having been confirmed in
                      accordance with these Terms, the relevant Precious Metal
                      will have been purchased by You and shall be displayed as
                      transferred to your Customer Account (“Customer Precious
                      Metal”). You become the owner of such Customer Precious
                      Metal.
                    </li>
                    <li>
                      Notwithstanding anything to the contrary contained in
                      these Terms, DGIPL shall be entitled to accept or reject a
                      Customer Order, for any reason whatsoever, at its sole
                      discretion.
                    </li>
                    <li>
                      In case of rejection of a Customer Order in accordance
                      with these Terms, where payments have been received by
                      DGIPL, such payments shall be returned to You to the bank
                      account linked to your Customer Account, subject to the
                      terms and conditions indicated on the Platform.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">DELIVERY OF PRECIOUS METAL</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      This Platform offers Services for Customers who wish to
                      procure delivery of the Customer Precious Metal in
                      accordance with these Terms.
                    </li>
                    <li>
                      You shall be entitled to procure delivery of the Customer
                      Precious Metal using the Platform (“Delivery Request”).
                    </li>
                    <li>
                      Upon placing the Delivery Request, You shall be required
                      to pay for the applicable charges and confirm the Delivery
                      Request. Your Customer Account shall be provisionally
                      debited corresponding to the quantity of the Customer
                      Precious Metal sought to be delivered (“Delivered Customer
                      Precious Metal”).
                    </li>
                    <li>
                      Within a period of 7 (Seven) Business Days of the Delivery
                      Request being confirmed or such further period as may be
                      required by DGIPL, DGIPL shall arrange for delivery of the
                      Delivered Customer Precious Metal at the shipping address
                      indicated by You. You shall be solely responsible for
                      ensuring that the correct address is furnished by You on
                      the Platform for processing such Delivery Request. You
                      shall not be entitled to change the shipping address after
                      the Delivery Request is processed by DGIPL.
                    </li>
                    <li>
                      You should carefully examine the package delivered and
                      shall not accept deliveries where the packaging has been
                      tampered with. If, however, You are of the view that the
                      package delivered has been tampered with, You shall be
                      required to intimate DGIPL immediately of the same, and
                      provide such other information as may be required by DGIPL
                      in this regard (“<strong>Return Request</strong>”). In the
                      event that the Return Request is accepted by DGIPL, then
                      within a period of 14 (Fourteen) Business Days of the
                      original package of Delivered Customer Precious Metal
                      being delivered back to DGIPL, in a manner indicated by
                      DGIPL, and the Return Request being approved by DGIPL,
                      DGIPL shall arrange for re-delivery of the Delivered
                      Customer Precious Metal at the shipping address indicated
                      by You. The costs for such shipping shall be borne by
                      DGIPL. However, in the event of frivolous and unjustified
                      Return Requests made by You, DGIPL reserves the right to
                      take all action available to it, including black-listing
                      or blocking You from using the Services on the Platform.
                    </li>
                    <li>
                      You, upon accepting the delivery, acknowledge the receipt
                      of the Delivered Customer Precious Metal in terms of the
                      Delivery Request placed with DGIPL. DGIPL shall not be
                      liable to You for any refund/replacement, under any
                      circumstance, for any subsequent complaints with respect
                      to such deliveries and/or any failure on your part to
                      comply with these Terms (as the case may be).
                    </li>
                    <li>
                      Upon receipt of the Delivery Request by DGIPL, the
                      Customer Account shall be permanently debited for the
                      Delivered Customer Precious Metal from the Customer
                      Account.
                    </li>
                    <li>
                      It shall be your responsibility to ensure that You are
                      available to receive the Delivered Customer Precious Metal
                      at the time of delivery. If You are not available at the
                      time of delivery, DGIPL courier agent may try and deliver
                      the item again before returning the same to DGIPL. In case
                      of return of the Delivered Customer Precious Metal to
                      DGIPL, your Customer Account shall be credited for the
                      Delivered Customer Precious Metal, after deducting the
                      charges (if any) due from You, provided however that DGIPL
                      is of the opinion that the packaging has not been tampered
                      with. It is hereby clarified that should a request for
                      re-delivery be made by You, You shall be solely liable to
                      incur the applicable charges for delivering the Delivered
                      Customer Precious Metal.
                    </li>
                    <li>
                      In case of DGIPL’s inability to make deliveries of
                      Delivered Customer Precious Metal due to a Force Majeure
                      Event, DGIPL shall intimate You of the same and may
                      require that the deliveries be effectuated through
                      specific modes. In such a case, You hereby agree to bear
                      any additional costs and fees necessary for the delivery
                      to be complete.
                    </li>
                    <li>
                      DGIPL will not be able to deliver a fractional quantity of
                      Precious Metal below such threshold as DGIPL shall notify
                      for this purpose even if a Customer Request is made for
                      such fractional quantity (“
                      <strong>Threshold Quantity</strong>”). The Threshold
                      Quantity for different Precious Metals may vary, shall be
                      notified on the Platform through the catalogue display of
                      products available for Delivery or Purchase. You are
                      advised to check the Platform periodically to determine
                      the Threshold Quantity as the same may be revised from
                      time to time. In the event that any Precious Metal below
                      the Threshold Quantity is to be delivered to You, then You
                      agree and acknowledge that such Customer Precious Metal
                      will instead be sold by DGIPL based on the sale prices
                      displayed on the Platform and You will instead receive the
                      applicable sale proceeds in your bank account, details of
                      which are provided by You. If there is any mistake in the
                      account number provided by You, DGIPL would not be held
                      responsible for the same.
                    </li>
                    <li>
                      Notwithstanding anything to the contrary contained in
                      these Terms, DGIPL shall be entitled to reject a Customer
                      Request which is not in compliance with the Terms hereof,
                      and shall intimate the Customer of the reasons for the
                      same.
                    </li>
                    <li>
                      Based on the changes made to the Customer Account (in lieu
                      of the Customer Orders and/or Customer Requests), if You
                      are of the opinion that the changes made thereto do not
                      tally with the orders and/or Customer Requests placed by
                      You, You may contact DGIPL at{" "}
                      <a href="mailto:care@MkGold.in">care@MkGold.in</a>,
                      who shall accordingly take all necessary actions for
                      taking care of any such identified discrepancies.
                    </li>
                    <li>
                      It is hereby clarified that the Customer Precious Metal
                      cannot be pledged or transferred by You to any other user,
                      and the Customer Account is non-transferrable, unless
                      specifically allowed by DGIPL. In the event of your death,
                      if specifically allowed by DGIPL, the title to such
                      Customer Precious Metal lying in the vault and the
                      Customer Account shall transfer to your legal heirs only
                      after the required due diligence has been conducted.
                      Subsequent to this, your legal heir(s) shall be regarded
                      as the Customer for the purpose of the Customer Precious
                      Metal and Customer Account thereafter and the Terms shall
                      be applicable to your legal heir(s).
                    </li>
                    <li>
                      It is hereby clarified that the Platform displays articles
                      that are available for purchase. Some items may appear
                      slightly larger or smaller than actual size due to screen
                      defaults and photography techniques. DGIPL shall not be
                      liable for any legal action on this account. It shall be
                      DGIPL’s endeavour to ensure that all details regarding the
                      products are clearly displayed on the Platform.
                    </li>
                    <li>
                      Data may be inaccurately displayed on the Platform due to
                      reasons not directly attributable to DGIPL, such as some
                      system errors on the Platform or any device used to access
                      the Platform. DGIPL reserves the right to correct any and
                      all errors when they do occur, at its sole discretion, and
                      DGIPL shall be entitled to not honour any requests/orders
                      placed by You based on any inaccurate or erroneous prices.
                    </li>
                    <li>
                      The Currency Value indicated on the Platform is fixed and
                      not negotiable. The Currency Value is also subject to
                      change without any notice to You.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    SALE OF CUSTOMER PRECIOUS METAL
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You may be provided an option to sell the Customer
                      Precious Metal during market hours based on the Sell Price
                      on the Platform. If the prices are found acceptable to
                      You, You shall confirm the sale request, in a form and
                      manner acceptable to DGIPL (“<strong>Sale Request</strong>
                      ”). Your Customer Account shall be debited corresponding
                      to the quantity of the Customer Precious Metal sought to
                      be sold vide the Sale Request (“
                      <strong>Sold Customer Precious Metal</strong>”).
                    </li>
                    <li>
                      Within a period of 2 (Two) Business Days of the Sale
                      Request being confirmed or such further period as may be
                      required, the payment, pursuant to the Sale Request, shall
                      be disbursed by DGIPL at the sale prices indicated at the
                      time of placing such Sale Request. DGIPL shall arrange for
                      such payments to be made to your bank account, details of
                      which are provided by You. If there is any mistake in the
                      account number, IFSC code etc. provided by You, DGIPL
                      would not be held responsible for the same.
                    </li>
                    <li>
                      It is hereby clarified that DGIPL will provide this
                      service on a best efforts’ basis and only when the
                      commercial bullion market is in operation. DGIPL does not
                      in any way guarantee that this option will be available to
                      You at all times. Further, the buyer of the Sold Customer
                      Precious Metal may be either DGIPL or another party (being
                      interested in buying the Sold Customer Precious Metal).
                      DGIPL shall not be held liable for any actions of such
                      third-party purchaser, apart from ensuring that funds are
                      received on account of the sale of your gold.
                    </li>
                    <li>
                      While reasonable efforts will be made to offer You a
                      competitive price for the Customer Precious Metal, there
                      is no guarantee that the price offered to You will be
                      close to or comparable with other prices available in the
                      market.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">TRANSFER OF PRECIOUS METAL </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Gifting Precious Metal:
                      <ol className="terme_of_use_ol">
                        <li>
                          DGIPL may offer Customers the ability to gift Precious
                          Metal purchased by the Customer, to a recipient of
                          their choice. This will be offered as the “gift gold”
                          feature.
                        </li>
                        <li>
                          DGIPL shall not be responsible for the acceptance or
                          rejection of the “gift” by the intended recipient, or
                          for verifying the correctness of the mobile number or
                          other identification information provided for the
                          intended recipient by the Customer.
                        </li>
                        <li>
                          Precious Metal gifts shall be final, and DGIPL shall
                          not be responsible for amending, altering or otherwise
                          reversing the transaction in case the gift is
                          delivered to an unintended recipient due to any error
                          on the part of the Customer.
                        </li>
                        <li>
                          However, in case the intended recipient does not
                          accept the “gift” within the prescribed time, the
                          Precious Metal balance shall revert to the Customer
                          Account.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Exchange for Jewellery:
                      <ol className="terme_of_use_ol">
                        <li>
                          DGIPL may also offer Customers the ability to exchange
                          their Customer Precious Metal for jewellery through a
                          partnership with reputed jewellery chains (“
                          <strong>Jeweller Partner</strong>”). This will be
                          offered as part of the “exchange for jewellery”
                          feature.
                        </li>
                        <li>
                          Customers may opt for this functionality by placing a
                          request for exchange (“
                          <strong>Exchange Request</strong>”) on the Platform
                          and following the procedure described by DGIPL. The
                          transaction shall only be initiated upon the express
                          instructions of the Customer to the Jeweller Partner.
                        </li>
                        <li>
                          Upon placing of the Exchange Request, the Customers
                          will be sent an OTP by DGIPL to verify the transaction
                          and confirm that they wish to proceed with the
                          transfer of Customer Precious Metal balance to the
                          selected Jeweller Partner. By communicating the OTP to
                          the Jeweller Partner, the Customer agrees to allow
                          DGIPL to debit the Customer Precious Metal account
                          balance to the extent required for the purchase of the
                          chosen jewellery product.
                        </li>
                        <li>
                          Any additional charges for the jewellery product
                          selected shall be borne by the Customer.
                        </li>
                        <li>
                          Once a transaction has been deemed to be final and an
                          invoice generated for the transaction, a Customer
                          shall not have the ability to cancel or request for a
                          refund, unless specifically allowed by DGIPL or the
                          Jeweller Partner.
                        </li>
                        <li>
                          Notwithstanding anything to the contrary contained in
                          these Terms, DGIPL shall be entitled to reject an
                          Exchange Request which is not in compliance with the
                          Terms hereof, and shall intimate the Customer of the
                          reasons for the same.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Lease of Precious Metal:
                      <ol className="terme_of_use_ol">
                        <li>
                          MkGold may also facilitate Customers providing their
                          Customer Precious Metal on a bailment basis
                          (“Bailment”) to parties that have been registered with
                          DGIPL and entered into a valid agreement (Master
                          Facilitation Agreement) as Bailees.
                        </li>
                        <li>
                          The bailment of Customer Precious Metal shall be
                          governed by the Bailor Terms as well as the terms
                          shown to the Customer prior to confirmation of their
                          bailment of their Precious Metal (“Bailment Terms”),
                          which shall be binding on the Customer with respect to
                          the Bailment of the Customer Precious Metal.
                        </li>
                      </ol>
                    </li>
                    <li>
                      DGIPL, at any time and its sole discretion, may change the
                      specifications of any functionality that pertains to
                      transfer of Precious Metal which include but are not
                      limited to the number of maximum and minimum transferors
                      and receivers, the minimum and maximum amount transferable
                      per transactions, the minimum and maximum gift amount
                      transferable by an individual per day as well as (but not
                      limited to) the minimum and maximum amount that can be
                      transferred by individuals overall using the Platform.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">FRAUDULENT TRANSACTIONS</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      The Customer should take care not to share their login
                      credentials, mobile wallet details, personal UPI pin or
                      OTP (“<strong>Payment Information</strong>”) with any
                      third party intentionally or unintentionally. DGIPL never
                      solicits Payment Information over a call or otherwise.
                      DGIPL and/or the relevant Payment Gateway Partner(s) or
                      MkGold Partners shall not be liable for any fraud due to
                      the sharing of such details.
                    </li>
                    <li>
                      In the event that an individual’s Payment Information
                      and/or Payment Instrument is fraudulently utilised to
                      purchase Precious Metal on the Platform (“
                      <strong>Fraudulent Transaction</strong>”) from DGIPL, then
                      DGIPL may share relevant information of such transaction
                      if the victim approaches DGIPL via proper channels
                      including DGIPL’s customer support number and email (
                      <a href="mailto:care@MkGold.in">care@MkGold.in</a>),
                      along with supporting documentation from the relevant
                      public authority or Cyber Cell.
                    </li>
                    <li>
                      In the event that DGIPL and/or the MkGold Partner or
                      Payment Gateway Partner(s) have flagged any transaction or
                      Customer Account as suspicious as per their internal
                      guidelines, or where it is found that a Customer has
                      utilised Payment Information or a Payment Instrument in an
                      unauthorised or fraudulent manner to purchase Precious
                      Metal or undertake any other transaction on the Platform
                      (“<strong>Fraudulent User</strong>”), DGIPL and/or the
                      Payment Gateway Partner(s) shall be entitled to:
                      <ol className="terme_of_use_ol">
                        <li>
                          request further KYC information or other documentation
                          from the Fraudulent User to verify the flagged
                          transaction;
                        </li>
                        <li>
                          pending further verification, block such Fraudulent
                          User, and/or freeze any accounts associated with them;
                        </li>
                        <li>
                          reverse any such Fraudulent Transaction, to the extent
                          possible, including the sale of any Precious Metal
                          purchased by such Fraudulent User, at the prevailing
                          rate;
                        </li>
                        <li>
                          provide information on such Fraudulent User, along
                          with any other transaction details, to the relevant
                          authorities.
                        </li>
                      </ol>
                    </li>
                    <li>
                      In the event of a Fraudulent Transaction as per Clause
                      16.2, DGIPL shall make a reasonable effort to assist the
                      victim in recovering their funds, subject to supporting
                      evidence and documentation being provided upon request.
                      DGIPL shall be entitled to transfer the victim’s funds,
                      less any payment gateway charges that are not refunded the
                      relevant Payment Gateway Partner(s).
                    </li>
                    <li>
                      However, it is clarified that DGIPL shall not obligated to
                      reverse any transaction where the Fraudulent User has
                      already sold the Precious Metal purchased through the
                      Fraudulent Transaction, and received the funds against
                      such sale; or where the Fraudulent User has chosen to take
                      delivery of the Precious Metal so purchased. In this
                      event, DGIPL shall, to the best of its ability, provide
                      any information on the Fraudulent User, including the bank
                      account or mobile wallet account to which funds have been
                      settled, or the physical address to which the Precious
                      Metal has been delivered, to the victim or the relevant
                      authorities.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    USE OF THE PLATFORM AND SERVICES{" "}
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You acknowledge that the Services are for Your personal
                      use and agree not to publish the Precious Metal prices or
                      descriptions of Precious Metal and/or any other
                      information displayed on the Platform (in lieu of Your
                      access to the Platform) on any other medium. You shall not
                      modify, copy, distribute, transmit, display, perform,
                      reproduce, publish, license, create derivative works from,
                      transfer, or sell any information, software, products or
                      services obtained from the Services.
                    </li>
                    <li>
                      Subject to these Terms and the Privacy Policy, You hereby
                      grant to DGIPL a non-exclusive, worldwide, royalty-free
                      right to (a) collect, store and transmit Your data, in
                      each case solely to the extent necessary to provide the
                      Services to You, and (b) share Your data or interact with
                      other people, to distribute and publicly perform and
                      display Your data as You direct or enable through the
                      Services. You will provide Your consent to DGIPL as may be
                      required under applicable law for the use and/or sharing
                      of any data generated by You when purchasing Precious
                      Metal or otherwise using the Platform for any services
                      related to the purchasing of the Precious Metal in such
                      manner as DGIPL may stipulate in this regard. DGIPL may
                      share Your data with the Trustee Administrator, as
                      required, to fulfil their obligations as a Trustee
                      Administrator. Your data will continue to be governed by
                      confidentiality obligations outlined in Section 24. It is
                      hereby clarified that DGIPL would be the owner of any data
                      generated by You when purchasing the Precious Metal on the
                      Platform.
                    </li>
                    <li>
                      You represent and warrant that: (i) You have obtained all
                      necessary rights, releases and permissions to provide all
                      Your data to DGIPL and to grant the rights granted to
                      DGIPL in these Terms and (ii) Your data and its transfer
                      to and use by DGIPL as authorized by You under these Terms
                      do not violate any laws or rights of any third party,
                      including without limitation any intellectual property
                      rights, rights of privacy, or rights of publicity, and any
                      use, collection and disclosure authorized herein is not
                      inconsistent with the terms of any applicable privacy
                      policies. Other than its security obligations under these
                      Terms and the Privacy Policy, DGIPL assume no
                      responsibility or liability for Your data, and You shall
                      be solely responsible for Your data and the consequences
                      of using, disclosing, storing, or transmitting it.
                    </li>
                    <li>
                      DGIPL shall not be held liable for any loss of data,
                      technical or otherwise, information, or for particulars
                      supplied by You, due to the reasons beyond its control
                      like corruption of data or delay or failure to perform as
                      a result of a Force Majeure Event.
                    </li>
                    <li>
                      DGIPL shall have the sole discretion to determine the
                      locations and pin codes it may want to serve.
                    </li>
                    <li>
                      The provision of Services may be interrupted, including
                      for maintenance, repairs, upgrades, or network or
                      equipment failures. DGIPL strives to keep the Services up
                      and running; however, all online services suffer
                      occasional disruptions and outages. DGIPL is not liable
                      for any disruption or loss You may suffer as a result.
                    </li>
                    <li>
                      DGIPL may discontinue some or all of the Services,
                      including certain features and the support for certain
                      devices and platforms, at any time.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    SUSPENSION / CLOSURE OF CUSTOMER ACCOUNT
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      DGIPL may, at its discretion, suspend Customer Account, if
                      there appears to be a fraudulent or suspicious activity in
                      the Customer Account. If DGIPL is of the opinion that You
                      are involved in any unlawful activity or the Customer
                      Account is used for any unlawful purpose, DGIPL shall have
                      the right to take all actions available to it, including
                      black-listing or blocking You from using the Services on
                      its Platform or blocking your access to the Services
                      through the Platform or intimating the relevant
                      authorities of such unlawful activities.
                    </li>
                    <li>
                      DGIPL shall not be held liable / responsible, in any
                      manner whatsoever, for any loss / liability arising out of
                      or relating to any technical failure / issue in Platform
                      and / or acts / omission not attributable to it.
                    </li>
                    <li>
                      You shall inform immediately, in any case no later than 10
                      (Ten) Business Days of the transaction, of any
                      irregularities or discrepancies that may exist in your
                      Customer Account, failing which it shall be deemed that
                      there is no error or discrepancy in the account. All
                      records maintained by DGIPL, in electronic or documentary
                      form of the instructions of the Customer and such other
                      details (including, but not limited to payments made or
                      received) pursuant to the Terms, shall as against the
                      Customer, be deemed to be conclusive evidence of such
                      instructions.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">FEES</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You hereby agree that You shall be liable for all fees and
                      charges associated with the use of the Platform and
                      Services. Further, details of the fees payable (including,
                      but not limited to the terms pertaining to such fees and
                      the quantum thereof), have been set out on the Platform.
                      Please note that the fees and charges may be revised from
                      time to time and it would be your responsibility to view
                      the Platform to check the then current fees and charges
                      payable.
                    </li>
                    <li>
                      It is hereby clarified that fees and charges, once paid,
                      are non-refundable.
                    </li>
                    <li>
                      All payments made for the use of the Platform and/or
                      purchase of Customer Precious Metal by You shall be
                      compulsorily in Indian Rupees.
                    </li>
                    <li>
                      While availing any of the payment method/s available on
                      the Platform for availing the Services, DGIPL shall not be
                      responsible for or assume any liability, whatsoever in
                      respect of any loss or damage arising directly or
                      indirectly to You due to:
                      <ol className="terme_of_use_ol">
                        <li>
                          Lack of authorization for any transaction(s), or
                        </li>
                        <li>
                          Exceeding the preset limit mutually agreed by You and
                          between bank/s and/or other institutions used by You
                          in making payment, or
                        </li>
                        <li>
                          Any payment issues arising out of the transaction, or
                        </li>
                        <li>
                          Rejection of transaction for any other reason(s)
                          whatsoever.
                        </li>
                      </ol>
                    </li>
                    <li>
                      MkGold may temporarily/permanently suspend/terminate the
                      Customer Account or refuse access in case of non-payment
                      of fees due by You. Without limitation to the other rights
                      and remedies available to DGIPL, it also reserves the
                      right to take legal action for the same.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">MEMBER ELIGIBILITY</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Use of the Platform and/or the Services is available only
                      to Persons who can enter into legally binding contracts
                      under the Indian Contract Act, 1872 and to Persons
                      resident in India. Persons who are "incompetent to
                      contract" within the meaning of the Indian Contract Act,
                      1872 including minors, un-discharged insolvents and
                      persons of unsound mind are not eligible to use the
                      Platform or the Services. Any person under the age of 18
                      shall not register on the Platform and shall not transact
                      on or use the Platform in relation to or for any Services.
                      If a minor wishes to use or transact on the Platform, such
                      use or transaction may be made by the minor’s legal
                      guardian or parents on the Platform. DGIPL reserves the
                      right to terminate any Person’s membership and/or refuse
                      to provide such Person with access to the Platform and/or
                      any Services if it is brought to DGIPL’s notice or if it
                      is discovered that such Person is not eligible to use the
                      Platform and/or any Service.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">ABSENCE OF RELATIONSHIP</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You represent and warrant to DGIPL that You have
                      sufficient experience and knowledge to make informed
                      decisions to purchase and/or sell Customer Precious Metal
                      and/or avail other Services made available on the
                      Platform. You acknowledge that You have not relied on any
                      information made available by DGIPL and that DGIPL is not
                      making any recommendation with respect to such purchases
                      and/or sale of Customer Precious Metal and/or availing
                      other Services made available on the Platform. No
                      relationship other than seller-purchaser if such a request
                      is made by You on the Platform in compliance with these
                      Terms, including, without limitation, any agent-principal
                      relationship, any advisor-advisee relationship, any
                      employee-employer relationship, any franchisee-franchisor
                      relationship, any joint venture relationship or any
                      partnership relationship, exists between You and DGIPL.
                    </li>
                    <li>
                      You acknowledge that DGIPL is not providing, dealing in,
                      offering any investment product and does not offer any
                      guarantee and/or assured returns. You further acknowledge
                      that value of Precious Metal may vary depending on various
                      factors and forces.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">ELECTRONIC ORDER RISKS</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Commercial internet service providers are not 100%
                      reliable and a failure by one or more of these providers
                      may affect internet-based order entry. You acknowledge
                      that the order entry system is an electronic mechanical
                      system and as such may be subject to failure beyond the
                      control of DGIPL. Therefore, DGIPL shall not be
                      responsible for errors, negligence, inability to execute
                      orders, delays in transmission, delivery or execution of
                      order due to breakdown or failure of transmission or
                      communication facilities (including in any device used to
                      connect to the Platform), or to any other cause beyond
                      DGIPL’s control or anticipation.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">FEEDBACK </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      The Platform may allow You to post Your review and
                      experience of using the Platform ("
                      <strong>Reviews</strong>") in order to improve the
                      Platform and the user experience.
                    </li>
                    <li>
                      You, being the originator of the Reviews, are responsible
                      for the Reviews that You upload, post, publish, transmit
                      or otherwise makes available on the Platform. You
                      represent that all such Reviews will be in accordance with
                      applicable law. You acknowledge that DGIPL does not
                      endorse any Reviews on the Platform and is not responsible
                      or liable for any Reviews. DGIPL reserves the right to
                      disable access to the Reviews on the Platform.
                    </li>
                    <li>
                      You hereby grant DGIPL a perpetual, non-revocable,
                      worldwide, royalty-free and sub-licensable right and
                      license to use, copy, distribute, display, publish,
                      transmit, make available, reproduce, modify, adapt the
                      Reviews in any manner as deemed appropriate by DGIPL in
                      any form including but not limited to print, broadcast,
                      online and across any and all websites and platforms owned
                      by DGIPL.
                    </li>
                    <li>
                      You further represent and warrant that while posting any
                      Reviews on the Platform You shall not use any offensive,
                      libelous, derogatory, hateful or racially or ethnically
                      objectionable language. Further, You shall not post any
                      content on any part of the Platform that is obscene,
                      pornographic, constitutes an “indecent representation of
                      women” as provided in the Indecent Representation of Women
                      (Prohibition) Act, 1986.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">CONFIDENTIALITY</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      As elaborated under the Privacy Policy, DGIPL will keep
                      all confidential information confidential, including Your
                      personal information, and shall not disclose it to anyone
                      except as required by law, and shall ensure that such
                      confidential information is protected with security
                      measures and a degree of care that it would apply to its
                      own confidential information. DGIPL acknowledges that its
                      employees, directors, agents and contractors shall use the
                      confidential information only for the intended purpose for
                      which it is provided. DGIPL shall use all reasonable
                      endeavours to ensure that its employees, directors, agents
                      and contractors acknowledge and comply with the provisions
                      of these Terms of confidentiality as if such person was
                      subject to these Terms of confidentiality.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    CONTENT AND INTELLECTUAL PROPERTY RIGHTS{" "}
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      DGIPL solely and exclusively owns respective copyrights,
                      trademarks, service marks, logos, trade names, and other
                      intellectual and proprietary rights associated with the
                      Services provided by DGIPL and displayed on/accessed on
                      the Platform and is protected under Indian law.
                    </li>
                    <li>
                      You hereby acknowledge that the Services constitute
                      original works and have been developed, compiled,
                      prepared, revised, selected, and arranged by DGIPL
                      respectively through the application of methods and
                      standards of judgment developed and applied through the
                      expenditure of substantial time, effort, and money and
                      constitutes valuable intellectual property of DGIPL and
                      such others. You thereby agree to protect the proprietary
                      rights of DGIPL during and after the term of these Terms.
                      You may not selectively download portions of the Platform
                      without retaining the copyright notices. You may download
                      material from the Platform only for the purpose intended
                      by these Terms.
                    </li>
                    <li>
                      Any infringement shall lead to appropriate legal
                      proceedings against You at appropriate forum for seeking
                      all available remedies under applicable laws of the
                      country.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    LINKS TO/FROM THIRD-PARTIES' WEBSITES/APPLICATIONS
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      The Platform may contain links and interactive
                      functionality interacting with the websites of third
                      parties. DGIPL is not responsible for and does not have
                      any liability for the functionality, actions, inactions,
                      privacy settings, privacy policies, terms, or content of
                      any such websites. Before enabling any sharing functions
                      to communicate with any such website or otherwise visiting
                      any such website, DGIPL strongly recommends that You
                      review and understand the terms and conditions, privacy
                      policies, settings, and information-sharing functions of
                      each such third-party website.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">INDEMNIFICATION</h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You hereby agree to indemnify and keep the DGIPL
                      indemnified from and against all actions, claims, demands,
                      proceedings, losses, damages, costs, charges and expenses,
                      directly or indirectly, whatsoever (“
                      <strong>Losses</strong>”) which DGIPL and/or its
                      employees, agents, workers or representative may at any
                      time incur, sustain, suffer or be put to as a consequence
                      of or by reason of or arising out of: (i) the usage of the
                      Platform and/or any device used by the Customer to access
                      the Platform; (ii) by reason of DGIPL acting in good faith
                      and taking or refusing to take or omitting to take action
                      on the Customer's instructions, and in particular arising
                      directly or indirectly out of the negligence, mistake or
                      misconduct of the Customer; (iii) breach or non-compliance
                      of the Terms and relating to the Customer Account; and/or
                      (iv) fraud or dishonesty relating to any transaction by
                      the Customer.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">PRIVACY POLICY </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Please review the Privacy Policy, which also governs your
                      visit to the Platform, to understand DGIPL’s privacy
                      practices. The Personal Information (defined in the
                      Privacy Policy) provided to DGIPL by You during the course
                      of usage of the Platform will be treated as strictly
                      confidential and in accordance with the Privacy Policy and
                      Applicable Laws and regulations. If You object to your
                      personal information being transferred or used, please do
                      not use the Platform.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">USE OF THE PLATFORM </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You agree, undertake and covenant that, during the use of
                      the Platform, You shall not host, display, upload, modify,
                      publish, transmit, store, update or share any information
                      that:
                      <ol className="terme_of_use_ol">
                        <li>
                          belongs to another person and to which You do not have
                          any right.
                        </li>
                        <li>
                          is grossly harmful, harassing, blasphemous,
                          defamatory, obscene, pornographic, pedophilic,
                          libelous, invasive of another person's privacy,
                          insulting or harassing on the basis of gender, hateful
                          or racially or ethnically objectionable, relating or
                          encouraging money laundering or gambling, or otherwise
                          inconsistent or contrary to the Applicable Laws in
                          force in any manner whatsoever.
                        </li>
                        <li>is misleading in any way.</li>
                        <li>is harmful to minors.</li>
                        <li>
                          involves the transmission of "junk mail", "chain
                          letters", or unsolicited mass mailing or "spamming".
                        </li>
                        <li>
                          infringes upon or violates any third party's rights
                          including, but not limited to, any patent, trademark,
                          copyright or other proprietary rights or intellectual
                          property rights, rights of privacy (including without
                          limitation unauthorized disclosure of a person's name,
                          email address, physical address or phone number).
                        </li>
                        <li>
                          provides instructional information about illegal
                          activities such as violating someone's privacy, or
                          providing or creating computer viruses.
                        </li>
                        <li>
                          tries to gain unauthorized access or exceeds the scope
                          of authorized access to the Platform or to profiles,
                          communities, account information, bulletins, or other
                          areas of the Platform or solicits passwords or
                          personally identifying information for commercial or
                          unlawful purposes from other users of the Platform.
                        </li>
                        <li>
                          engages in commercial activities without DGIPL’s prior
                          written consent such as engages in contests,
                          sweepstakes, barter, advertising etc.
                        </li>
                        <li>
                          interferes with another person’s use of the Platform.
                        </li>
                        <li>impersonates another person.</li>
                        <li>
                          threatens the unity, integrity, defence, security or
                          sovereignty of India, friendly relations with foreign
                          States, or public order, or causes incitement to the
                          commission of any cognizable offence or prevents
                          investigation of any offence or is insulting any
                          foreign States.
                        </li>
                        <li>
                          refers to any website or URL that, in DGIPL’s sole
                          discretion, contains material that is inappropriate
                          for the Platform or any other website, contains
                          content that would be prohibited or violates the
                          letter or spirit of these Terms.
                        </li>
                        <li>
                          deceives or misleads the addressee/ users about the
                          origin of the messages or knowingly and intentionally
                          communicates any information which is patently false
                          or misleading or grossly offensive or menacing in
                          nature but may reasonably be perceived as a fact.
                        </li>
                        <li>
                          is patently false and untrue, and is written or
                          published in any form, with the intent to mislead or
                          harass a person, entity or agency for financial gain
                          or to cause any injury to any person.
                        </li>
                        <li>
                          contains software viruses or any other computer codes,
                          files or programs designed to interrupt, destroy or
                          limit the functionality of any computer resource; or
                          contains any trojan horses, worms or other computer
                          programming routines that may damage, detrimentally
                          interfere with, diminish value of, covertly intercept
                          or steal any system, data or personal information.
                        </li>
                        <li>
                          violates any Applicable Law for the time being in
                          force.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You hereby accept full responsibility for any consequences
                      that may arise from your use of the Platform, and
                      expressly agree and acknowledge than in no event and under
                      no circumstances shall DGIPL be held liable to You for any
                      liabilities or damages resulting from or arising out of
                      your use of the Platform. You shall not use anyone else’s
                      account at any time.
                    </li>
                    <li>
                      You agree and acknowledge that You shall not use the
                      Platform for any fraudulent, malicious, illegal or
                      unauthorized purpose/activities. You agree to comply with
                      all Applicable Law pertaining to your use of the Platform.
                      You further agree and acknowledge that You shall use the
                      Services provided on the Platform only for your business
                      purposes.&nbsp;
                    </li>
                    <li>
                      You agree, undertake and covenant that, during the use of
                      the Platform you shall not attempt to alter, mimic, modify
                      or in any way license or offer the APIs utilised by DGIPL
                      on the Platform for sale or resale to a third party.
                    </li>
                    <li>
                      DGIPL shall have the right, but not the obligation, to
                      monitor your access to or use of the Platform to ensure
                      your compliance with these Terms or Applicable Law, at its
                      sole discretion.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">DISCLAIMER OF WARRANTIES </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      DGIPL DOES NOT MAKE ANY REPRESENTATIONS WHATSOEVER,
                      EXPRESSED OR IMPLIED, IN CONNECTION WITH THE PRECIOUS
                      METAL SOLD OR OFFERED TO BE SOLD TO YOU, THE QUALITY
                      THEREOF OR THE FITNESS OF USE OR THE MERCHANTABILITY OF
                      SUCH PRECIOUS METAL OR ANY OTHER MATTER, AND DGIPL HEREBY
                      DISCLAIMS ALL SUCH WARRANTIES. DGIPL SHALL NOT BE
                      RESPONSIBLE IN ANY WAY FOR ANY DEFECT/ IMPURITY ETC. IN
                      SUCH PRECIOUS METAL.
                      <br />
                      <br />
                      ALL INFORMATION, CONTENT, MATERIALS AND SERVICES INCLUDED
                      ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLATFORM
                      (COLLECTIVELY, THE "CONTENTS") ARE PROVIDED BY DGIPL ON AN
                      "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR
                      WARRANTIES OF ANY KIND. DGIPL MAKES NO REPRESENTATIONS OR
                      WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                      OPERATION OF THE PLATFORM, THE ACCURACY OR COMPLETENESS OF
                      THE CONTENTS AND THE ACCURACY OF THE INFORMATION. DGIPL
                      SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR
                      COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
                      DOWNLOAD OF ANY CONTENT, MATERIALS, DOCUMENT OR
                      INFORMATION OR ANY OTHER LOSSES INCURRED BY YOU FOR USE OF
                      THE PLATFORM. YOU EXPRESSLY AGREE THAT THE USE OF THE
                      PLATFORM IS AT YOUR SOLE RISK. DGIPL WILL NOT BE LIABLE
                      FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE
                      PLATFORM OR THE SERVICES OR THE CONTENTS INCLUDING,
                      WITHOUT LIMITATION, DIRECT, INDIRECT, CONSEQUENTIAL,
                      PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE
                      SPECIFIED IN WRITING. TO THE FULL EXTENT PERMITTED BY LAW,
                      DGIPL DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES
                      WITH RESPECT TO THE PLATFORM (OR ANY PART THEREOF) AND ITS
                      CONTENTS, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                      LIMITATION, WARRANTIES OF TITLE, MERCHANTABILITY, AND
                      FITNESS FOR A PARTICULAR PURPOSE OR USE.
                      <br />
                      <br />
                      DGIPL DOES NOT GUARANTEE ANY RETURNS, ON ANY TRANSACTIONS
                      CONSUMMATED VIS-À-VIS THE PLATFORM, TO ANY PERSON, EITHER
                      DIRECTLY OR INDIRECTLY. YOU SHALL BE SOLELY RESPONSIBLE
                      FOR UNDERTAKING APPROPRIATE AND EFFECTIVE DUE DILIGENCE
                      AND RELATED ANALYSIS BEFORE UNDERTAKING ANY TRANSACTIONS
                      PURSUANT TO THESE TERMS OF USE. YOU FURTHER ACKNOWLEDGE
                      AND AGREE THAT DGIPL AND ITS OFFICERS, DIRECTORS,
                      EMPLOYEES, AGENTS AND AFFILIATES WILL HAVE NO LIABILITY
                      FOR YOUR PURCHASE OR OTHER DECISIONS USING THE PLATFORM.
                      <br />
                      <br />
                      DGIPL SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR
                      DEVICES OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
                      ANY CONTENT, MATERIALS, DOCUMENT OR INFORMATION. YOU
                      EXPRESSLY AGREE THAT THE USE OF THE PLATFORM IS AT YOUR
                      SOLE RISK.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">RIGHTS &amp; RESERVATIONS </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      <strong>DGIPL</strong> reserves the right to:
                      <ol className="terme_of_use_ol">
                        <li>
                          Make all decisions in relation to,&nbsp;inter alia,
                          your registration with MkGold;
                        </li>
                        <li>
                          Request additional documents and additional
                          information from You for the purpose of your
                          registration as a user with the Platform; and
                        </li>
                        <li>
                          Deny access to the Platform or any portion thereof
                          without notice for the following reasons (a) any
                          unauthorized access or use by You; (b) attempt(s) to
                          assign or transfer any rights granted to You under
                          these Terms; (c) violation of any of the provisions of
                          the Terms or any Applicable Laws.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">LIMITATION OF LIABILITY </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      You hereby acknowledge that DGIPL (including but not
                      limited to its, directors, employees, agents or partners)
                      shall not be held liable to You for any special,
                      consequential, incidental, and exemplary or punitive
                      damages, or loss of profit or revenues. DGIPL shall also
                      not be liable under any circumstances for damages arising
                      out or related in any way to Your inability to access, or
                      Your difficulty in accessing the Platform to avail of any
                      Services, any bugs, viruses, trojan horses, or the like,
                      which may be transmitted to or through the Platform by any
                      third party, any loss of Your data, any claim relating to
                      Your data or content from the Services and/or Your failure
                      to keep the Gold Account Information secure and
                      confidential. You further agree that DGIPL shall not be
                      held responsible in any manner whatsoever for any and all
                      acts or omissions of any Intermediaries or any other third
                      party whosoever including (but not limited to) any person
                      whose device has been used by You to access the Platform
                      and/or any person appointed/nominated for the purposes of
                      accepting/collecting any payments from You for the
                      purchase of Precious Metal.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">
                    AMENDMENTS, ACCEPTANCE OF TERMS{" "}
                  </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      In order to use the Platform, You must first agree to
                      these Terms. You can accept the Terms by (1) Signing up
                      with DGIPL and logging onto MkGold by using the Login
                      Credentials; (2) Actual usage of the Platform. In this
                      case, You understand and agree that DGIPL will treat your
                      use of the Platform as acceptance of the Terms from that
                      point onwards; or (3) By clicking to accept these Terms,
                      if and when prompted on the Platform.
                    </li>
                    <li>
                      DGIPL reserves the right, at its sole discretion, to
                      change, modify, add or remove portions of these Terms at
                      any time. Such changes shall be posted on the Platform and
                      shall be notified to the Customer prior to making such
                      changes by SMS, email or by posting a conspicuous notice
                      on the Platform. Notwithstanding anything to the contrary,
                      Customer shall be responsible for regularly reviewing the
                      Terms, including amendments thereto as may be posted on
                      the Platform and shall be deemed to have accepted the
                      amended Terms by continuing the use of Platform. As long
                      as You comply with these Terms, DGIPL grants You a
                      personal, revocable, non-exclusive, non-transferable,
                      limited privilege to access and use the Platform.
                    </li>
                    <li>
                      By impliedly or expressly accepting these Terms, You also
                      accept and agree to be bound by all policies, including
                      but not limited to DGIPL’s privacy policy (“
                      <strong>Privacy Policy</strong>”), as amended from time to
                      time. You can view and read DGIPL’s Privacy Policy at{" "}
                      <a href="https://www.MkGold.com/">www.MkGold.com</a>.
                    </li>
                    <li>
                      Please do not access or use the Platform or otherwise
                      click to accept these Terms, if and when prompted on the
                      Platform, if You do not accept the Terms or are unable to
                      be bound by the Terms. As a condition of Your access to
                      and use of the Platform or the provision of any Services
                      to You using the Platform, You agree that You will comply
                      with all applicable laws and regulations when using the
                      Platform. If DGIPL is of the opinion that the Customer
                      Account is being used by You for any unlawful purpose,
                      DGIPL shall have the right to take all actions available
                      to it, including black-listing or blocking You from using
                      the Services through the Platform or intimating the
                      relevant authorities of such unlawful activities.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">ASSIGNMENT </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      Except as expressly provided herein, You shall not
                      license, sell, transfer or assign your rights,
                      obligations, or covenants under these Terms in any manner
                      without DGIPL’s prior written consent. DGIPL reserves the
                      right, at its own discretion, to freely assign and
                      transfer the rights and obligations under these Terms to
                      any third party.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">SEVERABILITY AND WAIVER </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      These Terms, the Privacy Policy and other referenced
                      material herein or on the Platform, are the entire
                      agreement between You and DGIPL with respect to the
                      products/Services offered on the Platform and supersede
                      all prior communications and proposals (whether oral,
                      written or electronic) between You and DGIPL with respect
                      hereto. If any provision of these Terms is found to be
                      unenforceable or invalid, that provision will be limited
                      or eliminated to the minimum extent necessary so that
                      these Terms will otherwise remain in full force and effect
                      and enforceable. The failure of either party to exercise
                      in any respect any right provided for herein shall not be
                      deemed a waiver of any further rights hereunder.
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="subheading">GRIEVANCE REDRESSAL MECHANISM </h2>
                  <ol className="terme_of_use_ol">
                    <li>
                      In accordance with the Information Technology Act, 2000
                      and Rules made thereunder, the name and contact details of
                      the Grievance Officer are provided below:
                      <ol className="terme_of_use_ol">
                        <li>
                          the contact details of Grievance Officer for the
                          purposes of DGIPL are:
                          <br />
                          Name: Rukhsar Khan
                          <br />
                          Email:{" "}
                          <a href="mailto:Rukhsar.khan@MkGold.in">
                            Rukhsar.khan@MkGold.in
                          </a>{" "}
                          <br />
                          Phone No: 8881000800
                        </li>
                      </ol>
                    </li>
                    <li>
                      For registering your complaint, please contact the
                      Grievance Officer at the above mentioned details in
                      relation to any violation of these Terms or the
                      Information Technology (Intermediary Guidelines and
                      Digital Media Ethics Code) Rules, 2021. The Grievance
                      Officer shall redress the complaint in accordance with the
                      provisions of the Information Technology Act, 2000 and
                      Rules made thereunder.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </>
    );
}

export default Termofusepage;