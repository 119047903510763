import React from "react";
// import "../assets/style/style.css";
import "./Productdetail.css";
import Header from "./Header";
import Slider from "react-slick";
import img1 from "../assets/images/img1.jpg";
import img2 from "../assets/images/img2.jpg";
import img3 from "../assets/images/img3.jpg";
import img4 from "../assets/images/fine-gold5g.webp";
import Safegoldg from "./Safegoldg";
import Faqquestion from "./Faqquestion";
import Footer from "./Footer";
import Review from "./Review";

const Productdetail = () => {
  // const settings = {
  //     customPaging: function(i) {
  //       return (
  //         <a>
  //           <img src={img1} />
  //         </a>
  //       );
  //     },
  //     dots: true,
  //     dotsClass: "slick-dots slick-thumb",
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1
  //   };
  return (
    <>
      <div className="delivery-bg">
        <div className="container-fluid">
          {/*header*/}
          <Header />

          <section className="delivery-section-1 delivery-section-2 m-t-5 row main-section">
            <h4 className="m-header p-h">
              <a href ng-click="back()">
                <span className="arrow_white">
                  <img src="/assets/sg-ui-v2/images/ico/Path_118.svg" alt />
                </span>
              </a>
              Product Details
              <span id="share-btn" style={{ float: "right", display: "block" }}>
                <img
                  src={
                    "/assets/sg-ui-v2/sg-homepage/assets/images/icons/share-icon.svg"
                  }
                  alt
                />
              </span>
            </h4>
            <div className="delivery-container-1 col-sm-12 col-md-6">
              <div className="container">
                <div className="local-nav">
                  <a href ng-click="back()">
                    Delivery
                  </a>{" "}
                  &gt; <a href>Product Details</a>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <img
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "40px auto",
                      }}
                      src={img4}
                      width={"60%"}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="delivery-container-1 m-top col-sm-12 col-md-6">
              <div className="sub-2">
                <div
                  className="offer-div ng-scope"
                  ng-if="selectedProduct.discount > 0"
                >
                  <span>
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/promo-offer.svg"
                      alt="Promo-Offer"
                    />
                    Akshaya Tritiya Offer
                  </span>

                  <em
                    ng-if="selectedProduct.discount_type == 2"
                    className="desktop-only ng-binding ng-scope"
                  >
                    Save ₹300
                  </em>
                </div>

                <h3 className="ng-binding">
                  0.5 Gram MkGold Coin (99.99%)
                  <br />
                </h3>
                <div className="amounts">
                  <p
                    className="product-amount offer-amount ng-binding"
                    ng-show="selectedProduct.discount > 0"
                  >
                    ₹ 3,494.31
                    <span className="ng-binding">₹ 3,794.31</span>
                  </p>

                  <div
                    className="offer-div ng-scope"
                    ng-if="selectedProduct.discount > 0"
                  >
                    <em
                      ng-if="selectedProduct.discount_type == 2"
                      className="mobile-only ng-binding ng-scope"
                      style={{ display: "none" }}
                    >
                      Save ₹300 OFF
                    </em>
                  </div>

                  <button
                    className="btn btn-primary1 desktop ng-scope"
                    ng-disabled="isLoading"
                    ng-click="productCheckout(selectedProduct)"
                    ng-if="selectedProduct.in_stock == 'Y'"
                  >
                    <span ng-if="!isLoading" className="ng-scope">
                      Buy Now &nbsp; <i className="fa fa-long-arrow-right" />
                    </span>
                  </button>
                </div>
              </div>
              <div className="moblie-deliery-btn" style={{ display: "none" }}>
                <button
                  className="btn btn-primary1 ng-scope"
                  ng-click="productCheckout(selectedProduct)"
                  ng-if="selectedProduct.in_stock == 'Y'"
                  ng-disabled="isLoading"
                >
                  <span ng-if="!isLoading" className="ng-scope">
                    Buy Now &nbsp; <i className="fa fa-long-arrow-right" />
                  </span>
                </button>
              </div>
              <div className="pin-input mobile" style={{ display: "none" }}>
                <div className="form-group">
                  <img
                    src="/assets/sg-ui-v2/sg-homepage/assets/images/icons/black-loc-pointer.svg"
                    alt
                  />
                  <input
                    type="tel"
                    placeholder="Enter your pincode"
                    numbers-only
                    required
                    ng-model="userdata.pincode"
                    maxLength={6}
                    minLength={6}
                    className="ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength"
                  />
                  <span
                    ng-click="getPincode(userdata.pincode)"
                    ng-class="{'disabled-pin':userdata.pincode.length != 6}"
                    ng-disabled="userdata.pincode.length<6"
                    className="disabled-pin"
                  >
                    Check availability
                  </span>
                </div>

                <p className="deliverable-span del-span"></p>
              </div>
              <div className="scroll-wrapper">
                <div className="sub-1">
                  <div>
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/certificate.png"
                      alt
                    />
                    24K Pure Gold Quality Guaranteed
                  </div>
                  <div>
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/truck.png"
                      alt
                    />
                    100% Secure with Delivery Insurance
                  </div>
                  <div>
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/price-tag.png"
                      alt
                      className="price-img"
                    />
                    Best Prices in the market
                  </div>
                </div>
                <div className="pin-input desktop">
                  <div className="form-group">
                    <img
                      src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/black-loc-pointer.svg"
                      alt
                    />
                    <input
                      type="tel"
                      placeholder="Enter your pincode"
                      numbers-only
                      required
                      ng-model="userdata.pincode"
                      maxLength={6}
                      minLength={6}
                      className="ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength"
                    />
                    <span
                      ng-click="getPincode(userdata.pincode)"
                      ng-class="{'disabled-pin':userdata.pincode.length != 6}"
                      ng-disabled="userdata.pincode.length<6"
                      className="disabled-pin"
                    >
                      Check availability
                    </span>
                  </div>

                  <p className="deliverable-span del-span"></p>
                </div>
                <div className="product-description p-l mt-5">
                  <h3>Description</h3>
                  <ul>
                    <li
                      ng-repeat="highlight in selectedProduct.product_highlights"
                      className="ng-binding ng-scope"
                    >
                      MkGold coins are set in 24 Karat Yellow Gold
                    </li>

                    <li
                      ng-repeat="highlight in selectedProduct.product_highlights"
                      className="ng-binding ng-scope"
                    >
                      Guaranteed weight and purity: Specially manufactured in
                      state of the art facilities, with zero negative tolerance
                      for weight and purity
                    </li>

                    <li
                      ng-repeat="highlight in selectedProduct.product_highlights"
                      className="ng-binding ng-scope"
                    >
                      Sealed in international quality packaging with a unique
                      serial number, ensures foolproof quality and complete
                      traceability for each coin
                    </li>
                  </ul>
                  <div className="product-description-1">
                    <div className="row">
                      <div className="col-sm-3 col-xs-2 col-3"> Material</div>
                      <div className="col-sm-1 col-xs-1 col-1"> :</div>
                      <div className="col-sm-8 col-xs-7 col-7">
                        <span>Fine gold </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-xs-2 col-3">Gold Purity</div>
                      <div className="col-md-1 col-xs-1 col-1"> :</div>

                      <div className="col-md-8 col-xs-7 col-7">
                        <span className="ng-binding">
                          24 Karat, 99.99% pure gold
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-xs-2 col-3">Weight</div>
                      <div className="col-md-1 col-xs-1 col-1">:</div>
                      <div className="col-md-8 col-xs-7 col-7">
                        <span className="ng-binding">0.5 gm</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-xs-2 col-3">Quality</div>
                      <div className="col-md-1 col-xs-1 col-1">:</div>
                      <div className="col-md-8 col-xs-7 col-7">
                        <span>
                          Independently assay certified &amp; zero negative
                          weight tolerance.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Safegoldg />
          </section>
          <Review />
          <Faqquestion />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Productdetail;
