import React from "react";
import "../assets/style/style.css";

const Goldsafety = () => {
  return (
    <>
      <section className="safety-of-gold">
        <h1 className="safety-animation-1">
          How we ensure the safety of your gold
        </h1>
        <ul className="row">
          <li className="col col-12 col-lg-4 safety-animation-2">
            <div className="image-container">
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/images/trustee-logos/vistra.svg"
                alt="Vistra"
                className="vistra"
              />
            </div>
            <p>
              Vistra Corporate Services, a globally recognised trusteeship
              company
            </p>
          </li>
          <li className="col col-12 col-lg-4 safety-animation-2">
            <div className="image-container">
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/about-us/footer-2.png"
                alt="BRINKS"
                className="m-b"
              />
            </div>
            <p>Stored securely at world-class Brinks vaults</p>
          </li>
          <li className="col col-12 col-lg-4 safety-animation-2">
            <div className="pure-gold">
              <div className="image-container">
                <img
                  src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/about-us/parker_logo.png"
                  alt="Parker"
                  className="m-b"
                />
              </div>
              <div className="image-container">
                <img
                  src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/about-us/nabl-logo.png"
                  alt="NABL"
                  className="m-b"
                />
              </div>
            </div>
            <p>
              We get all our coins and bars independently assay certified to
              ensure highest purity 99.99% 24K gold
            </p>
          </li>
          <li className="col col-12 col-lg-2 spacer" />
          <li className="col col-12 col-lg-4 safety-animation-2">
            <div className="image-container">
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/about-us/fraud-alert.png"
                alt="Fraud Alert"
              />
            </div>
            <p>Strict intolerance for fraud and checks to prevent it</p>
          </li>
          <li className="col col-12 col-lg-4 safety-animation-2">
            <div className="image-container">
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/about-us/shield.svg"
                alt="Shield"
              />
            </div>
            <p>
              Information is always private and all transactions are secured
            </p>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Goldsafety;
