
// import '.../assets/style/style.css';
import { Route, Routes } from 'react-router-dom';
import Home from './routes/Home';
import Buycoin from './routes/Buycoin';
import Productdetail from './components/Productdetail';
import About from './routes/About';
import Privacypolicy from './routes/Privarypolicy';
import Jewelleryex from './routes/Jewelleryex';
import Partnerpage from './routes/Partnerpage';
import Lease from './routes/Lease';
import Refundpolicy from './routes/Refundpolicy';
import Termofuse from './routes/Termofuse';
import Grievance from './routes/Grievance';
import Faq from './routes/Faq';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/gold-delivery' element={<Buycoin/>}/>
      <Route path='/about-us' element={<About/>}/>
      <Route path='/gold-product-details' element={<Productdetail/>}/>
      <Route path='/privacy-policy' element={<Privacypolicy/>}/>
      <Route path='/jewellery-ex' element={<Jewelleryex/>}/>
      <Route path='/partner-with-us' element={<Partnerpage/>}/>
      <Route path='/lease' element={<Lease/>}/>
      <Route path='/refund-policy' element={<Refundpolicy/>}/>
      <Route path='/term-of-use' element={<Termofuse/>}/>
      <Route path='/grievance' element={<Grievance/>}/>
      <Route path='/faq' element={<Faq/>}/>
    </Routes>
  );
}

export default App;
