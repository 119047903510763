import React from "react";
import "../assets/style/style.css";

const Supportpartner = () => {
  return (
    <>
      <section className="about-us-partners-section">
        <h1 className="sp-animation-1">Our Supporters &amp; Partners</h1>
        <div>
          <p className="subtitle sp-animation-2">Our Investors</p>
          <ul className="grid-1 row">
            <li className="col col-12 col-lg-2 spacer" />
            <li className="image-container col col-6 col-lg-2 sp-animation-3">
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/logo/invent-capital-logo.svg"
                alt="Invent Capital"
              />
            </li>
            <li className="image-container col col-6 col-lg-2 sp-animation-3">
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/logo/pravega-ventures-logo.svg"
                alt="Pravega Ventures"
              />
            </li>
            <li className="image-container col col-6 col-lg-2 sp-animation-3">
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/logo/world-gold-council-logo-new.svg"
                alt="World Gold Council"
              />
            </li>
            <li className="image-container col col-6 col-lg-2 sp-animation-3">
              <img
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/logo/beenext_logo-new.svg"
                alt="Beenext"
              />
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Supportpartner;
