import React from "react";
import "../assets/style/style.css";

const Gotodigital = () => {
  return (
    <>
      <section className="row digital-section homepage-section-2">
        <h1 className="digital-animation-1">Why go digital?</h1>
        <ul className="row cards-container">
          <li className="card-item col col-12 col-lg-4 digital-animation-2">
            <div className="image-container">
              <img
                src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/certificate.svg"
                className=" ls-is-cached lazyloaded"
                alt="Certificate"
              />
            </div>
            <div className="content">
              <h2>Guaranteed 24K Gold</h2>
              <p>
                Unlike local vendors, with MkGold, you directly buy from the
                manufacturer
              </p>
            </div>
          </li>
          <li className="card-item col col-12 col-lg-4 digital-animation-2">
            <div className="image-container">
              <img
                src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/mobile-wallet.svg"
                className=" ls-is-cached lazyloaded"
                alt="Vault"
              />
            </div>
            <div className="content">
              <h2>Sell anytime from home</h2>
              <p>
                Sell anytime, without going anywhere and receive money direct in
                your account.
              </p>
            </div>
          </li>
          <li className="card-item col col-12 col-lg-4 digital-animation-2">
            <div className="image-container">
              <img
                src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/savings.svg"
                className=" ls-is-cached lazyloaded"
                alt="Savings"
              />
            </div>
            <div className="content">
              <h2>Earn income on gold</h2>
              <p>
                You can lend digital gold to MkGold-verified borrowers and
                earn monthly rental income in the form of gold.
              </p>
            </div>
          </li>
          <li className="card-item col col-12 col-lg-4 digital-animation-2">
            <div className="image-container">
              <img
                src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/vault.svg"
                className=" ls-is-cached lazyloaded"
                alt="Savings"
              />
            </div>
            <div className="content">
              <h2>Safety guaranteed</h2>
              <p>
                Unlike physical gold, you don’t have to worry about theft or
                expensive locker fees. You gold is stored in bank-grade lockers
                free of cost.
              </p>
            </div>
          </li>
          <li className="card-item col col-12 col-lg-4 digital-animation-2">
            <div className="image-container">
              <img
                src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/ingot.svg"
                className=" ls-is-cached lazyloaded"
                alt="Savings"
              />
            </div>
            <div className="content">
              <h2>Convert to physical gold</h2>
              <p>
                You can convert your digital gold to physical gold anytime in
                the form of coins or jewellery through our partners.
              </p>
            </div>
          </li>
          <li className="card-item col col-12 col-lg-4 digital-animation-2">
            <div className="image-container">
              <img
                src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/savings.svg"
                alt="Savings"
              />
            </div>
            <div className="content">
              <h2>Buy as low as ₹10</h2>
              <p>
                Digital does not require to invest a large sum of money. You can
                invest based on your budget.
              </p>
            </div>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Gotodigital;
