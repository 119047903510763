import React from "react";
import "../assets/style/style.css";

const Redeemgold = () => {
  return (
    <>
      <section className="redeem-gold-section">
        <h1 className="d2p-animation-1">Digital to physical in minutes</h1>
        <ul className="d2p-animation-2 redeem-option-tabs">
          <li className="coins active-tab" id="coins-button">
            Coins
          </li>
          <li className="jewellery" id="jewellery-button">
            Jewellery
          </li>
        </ul>
        <div className="row coins-tab-content tab-option d2p-animation-3 active-tab-content">
          <div className="col col-12 col-lg-7 column-1">
            <div className="image-container">
              <img
                // data-src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/coins-tab-img.png"
                alt
                className=" ls-is-cached lazyloaded"
                // srcSet="/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/coins-tab-img.png, /assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/coins-tab-img.webp"
                src="https://www.safegold.com/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/coins-tab-img.png"
              />
            </div>
          </div>
          <div className="col col-12 col-lg-5 column-2">
            <div className="content-container">
              <div className="main">
                24K Gold Coins &amp; Bars delivered to your doorstep
              </div>
              <p>
                Convert your digital gold to physical gold by paying a nominal
                minting charge. Your delivery comes with free insurance, to
                ensure your coins and bars reach you safely.
              </p>
            </div>
          </div>
        </div>
        <ul className="row jewellery-tab-content tab-option d2p-animation-4">
          <div className="col col-12 col-lg-6 column-1">
            <li>
              <div className="image-container">
                <img
                  data-src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/candere-logo.svg"
                  className=" ls-is-cached lazyloaded"
                  alt="Candere"
                  src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/candere-logo.svg"
                />
              </div>
              <div className="info">
                <p className="heading">Redeem Online</p>
                <p className="content">
                  You can shop online with Candere.com. Use your MkGold number
                  &amp; checkout with your MkGold balance.
                </p>
              </div>
            </li>
          </div>
          <div className="col col-12 col-lg-6 column-2">
            <li>
              <div className="image-container">
                <img
                  data-src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/caratlane-logo.svg"
                  alt="CaratLane"
                  className=" ls-is-cached lazyloaded"
                  src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/caratlane-logo.svg"
                />
              </div>
              <div className="info">
                <p className="heading">Redeem Offline</p>
                <p className="content">
                  Redeem by sharing your MkGold number at the CaratLane store.
                  Verify using an OTP and pay using your MkGold balance.
                </p>
              </div>
            </li>
          </div>
        </ul>
      </section>
    </>
  );
};

export default Redeemgold;
