import React, { useState } from "react";
import "../assets/style/style.css";
import Header from "./Header";
import logo from "../assets/images/logo/mk-gold.jpg";
import screen from "../assets/images/screen1.jpg";
import { FiChevronDown } from "react-icons/fi";
import Faqquestion from "./Faqquestion";
import Footer from "./Footer";
import Trustedpartner from "./Trustedpartner";
import { Alert } from "react-bootstrap";

const Leasepage = () => {
  const [show, setShow] = useState(true);
  return (
    <>
      <main
        className="main iog-landing-page ng-scope"
        ng-controller="IOGLandingPageCtrl"
        ng-init="fetchMaxYield()"
      >
        <div>
          <Alert
            style={{
              background: "#DCEEE4",
              borderRadius: "0",
              border: "0px",
              display: "flex",
              flexDirection: "row",
              color: 'rgb(0, 137, 150',
              justifyContent: 'center'
            }}
            variant="danger"
            onClose={() => setShow(false)}
            dismissible
          >
            <Alert.Heading>
              Got Queries? We understand!
              <button className="alert-btn ms-3 me-3">Book a Call</button>
              to resolve them all
            </Alert.Heading>
          </Alert>
        </div>

        <div className="loader close">
          <div className="loader__item" />
        </div>
        {/*01*/}
        <section
          className="position-relative sectiod-gold-bnr sec-pd ov-hidden"
          style={{ paddingTop: "0px" }}
        >
          
          <div className="container-fluid">
            <Header />
            
          </div>

          <div className="container1" style={{ marginTop: "20px" }}>
            <div className="row mb-md-135">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="banner-content">
                  <h1 className="fw-500 mb-2 mb-md-3 pb-lg-1">
                    <span className="c-F00 f-5rem fw-400">Grow your gold</span>
                    <span className="d-block f-4rem">like you grow your money.</span>
                  </h1>
                  <p className="col-md-10 col-lg-9 px-0 c-41F f-sarabun space-m ng-binding">
                    Lease your gold to{" "}
                    <span className="c-F00">India’s most trusted jeweller</span>{" "}
                    and earn 5% p.a. in the form of gold
                  </p>
                </div>
                <div className="earn-upto-section">
                  <div className="left-section">
                    <img
                      src="https://www.safegold.com/iog-landing-page/img/earn-upto-icon.svg"
                      alt="Earn Upto"
                    />
                    <div className="text">
                      <p className="head">Earn upto</p>
                      <p className="desc">
                        <span className="highlight ng-binding">16%</span>
                        <span>p.a.</span>
                      </p>
                    </div>
                  </div>
                  <div className="right-section">
                    <div className="sub-section-1 sub-section">
                      <span>Get upto</span>
                      <span className="green-text ng-binding">
                        {" "}
                        5% p.a. in gold
                      </span>
                    </div>
                    <div className="sub-section-2 sub-section">
                      <span>Above</span>
                      <span className="green-text">
                        {" "}
                        11% average Market Appreciation
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-md-block mt-4 web-btn-wrapper">
                  <a
                    style={{ color: "#f6f6f6" }}
                    href
                    className="web-btn"
                    ng-click="redirectToLeaseSection()"
                  >
                    Lease your gold
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 text-center">
                <img
                  src="https://img.freepik.com/premium-vector/3d-vector-illustration-gold-rupee-coin-indian-currency-symbol_228967-91.jpg?w=740"
                  width={"80%"}
                />
              </div>
            </div>
            <div className="row row-box-shadow">
              <div className="col-12 col-md-12 user-info-wrapper">
                <div className="sg-users-info">
                  <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                    <div className="col-3 col-md-3 sg-users-info-list__item d-flex">
                      <div>
                        <img src="https://www.safegold.com/iog-landing-page/img/currentUser-icon.svg" />
                      </div>
                      <div className="ms-2 ps-1">
                        <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                          <span className="f-sarabun span-1">33</span>
                          <span className="fw-400 f-16 f-sarabun">M</span>
                        </h4>
                        <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                          USERS
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-md-3 sg-users-info-list__item d-flex">
                      <div>
                        {" "}
                        <img src="https://www.safegold.com/iog-landing-page/img/Transactions-icon.svg" />
                      </div>
                      <div className="ms-2 ps-1">
                        <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                          <span className="f-sarabun span-1">0.00</span>
                          <span className="fw-400 f-16 f-sarabun">%</span>
                        </h4>
                        <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                          DEFAULTS{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-md-3 sg-users-info-list__item d-flex">
                      <div>
                        {" "}
                        <img src="https://www.safegold.com/iog-landing-page/img/Newusers-icon.svg" />
                      </div>
                      <div className="ms-2 ps-1">
                        <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                          <span className="f-sarabun span-1">50</span>
                          <span className="fw-400 f-16 f-sarabun">+</span>
                        </h4>
                        <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                          leases completed
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-md-3 sg-users-info-list__item d-flex">
                      <div>
                        {" "}
                        <img src="https://www.safegold.com/iog-landing-page/img/Transactions-icon.svg" />
                      </div>
                      <div className="ms-2 ps-1">
                        <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                          <span className="f-sarabun span-1">0.1</span>
                          <span className="fw-400 f-16 f-sarabun">grams</span>
                        </h4>
                        <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                          minimum investment
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 stats-main-wrapper">
                <div className="stats-wrapper">
                  <div className="swiper mySwiper mobileswiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode">
                    <div
                      className="swiper-wrapper"
                      id="swiper-wrapper-b13adae86128552e"
                      aria-live="off"
                      style={{ transitionDuration: "0ms" }}
                    >
                      <div
                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                        data-swiper-slide-index={0}
                      >
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/currentUser-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">33</span>
                                <span className="fw-400 f-16 f-sarabun">M</span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                USERS{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate"
                        data-swiper-slide-index={1}
                      >
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Transactions-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">0.00</span>
                                <span className="fw-400 f-16 f-sarabun">
                                  %{" "}
                                </span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                DEFAULTS{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate"
                        data-swiper-slide-index={2}
                      >
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Newusers-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">50</span>
                                <span className="fw-400 f-16 f-sarabun">+</span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                leases completed
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate"
                        data-swiper-slide-index={3}
                      >
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Transactions-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">0.1</span>
                                <span className="fw-400 f-16 f-sarabun">
                                  grams
                                </span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                minimum investment{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide" data-swiper-slide-index={0}>
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/currentUser-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">33</span>
                                <span className="fw-400 f-16 f-sarabun">M</span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                USERS{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide" data-swiper-slide-index={1}>
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Transactions-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">0.00</span>
                                <span className="fw-400 f-16 f-sarabun">
                                  %{" "}
                                </span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                DEFAULTS{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide" data-swiper-slide-index={2}>
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Newusers-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">50</span>
                                <span className="fw-400 f-16 f-sarabun">+</span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                leases completed
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide" data-swiper-slide-index={3}>
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Transactions-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">0.1</span>
                                <span className="fw-400 f-16 f-sarabun">
                                  grams
                                </span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                minimum investment{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                        data-swiper-slide-index={0}
                      >
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/currentUser-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">33</span>
                                <span className="fw-400 f-16 f-sarabun">M</span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                USERS{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate"
                        data-swiper-slide-index={1}
                      >
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Transactions-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">0.00</span>
                                <span className="fw-400 f-16 f-sarabun">
                                  %{" "}
                                </span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                DEFAULTS{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate"
                        data-swiper-slide-index={2}
                      >
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Newusers-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">50</span>
                                <span className="fw-400 f-16 f-sarabun">+</span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                leases completed
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate"
                        data-swiper-slide-index={3}
                      >
                        <div className="sg-users-info-list row flex-wrap justify-content-md-between align-items-md-center">
                          <div className="g-users-info-list__item d-flex">
                            {" "}
                            <img
                              className="bannericon"
                              src="/iog-landing-page/img/Transactions-icon.svg"
                            />
                            <div className="ms-2 ps-1">
                              <h4 className="c-F00 fw-600 mb-0 f-sarabun">
                                <span className="f-sarabun">0.1</span>
                                <span className="fw-400 f-16 f-sarabun">
                                  grams
                                </span>
                              </h4>
                              <p className="f-14 fw-500 text-uppercase mb-0 f-sarabun">
                                minimum investment{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="swiper-button-next"
                      tabIndex={0}
                      role="button"
                      aria-label="Next slide"
                      aria-controls="swiper-wrapper-b13adae86128552e"
                    />
                    <div
                      className="swiper-button-prev"
                      tabIndex={0}
                      role="button"
                      aria-label="Previous slide"
                      aria-controls="swiper-wrapper-b13adae86128552e"
                    />
                    <span
                      className="swiper-notification"
                      aria-live="assertive"
                      aria-atomic="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*02*/}
        <section className="sec-commit-pad sec-form ov-hidden bg-F7F">
         
          <div className="container1">
            <div className="row">
              <div className="col-lg-6 col-12 mb-md-5">
                <h2 className="fw-400 mb-3" style={{ lineHeight: "5rem" }}>
                  <span className="d-block f-5rem" style={{ fontSize: "5rem" }}>
                    Tiny Commitment.
                  </span>
                  <span className="c-F00 fw-400 f-5rem">Big Gains.</span>
                </h2>
                <p
                  className="px-0 f-sarabun f-400 f-24 content-pad"
                  style={{ fontSize: "2.3rem" }}
                >
                  Move the slider to see how Gains yields better growth than FDs
                  and SGBs
                </p>
                <div className="deposit-chart">
                  <div className="div">
                    <div className="deposit-chart-list">
                      <div className="deposit-val d-flex justify-content-between mb-4 pb-2">
                        <div className="deposit-val__text me-5">
                          <p className="mb-0 f-16 f-sarabun f-400">
                            Monthly Deposit
                          </p>
                        </div>
                        <div className="deposit-val__amount chart-deposit">
                          <span className="f-20 fw-700 c-41F f-sarabun">
                            ₹&nbsp;
                          </span>
                          <span className="gfs-amount f-20 fw-700 c-41F f-sarabun">
                            10k
                          </span>
                        </div>
                      </div>

                      <div className="deposit-card content-pad desktop-view-only">
                        <p className="f-24">Profits at the end of 3 years</p>
                        <div className="deposit-chart-list__item mb-md-4">
                          <span
                            className="c-dot"
                            style={{ background: "#277A87" }}
                          />
                          <div className="ms-1 ms-md-2 d-amount">
                            {" "}
                            <span className="d-block f-14">FD </span>
                            <div className="pad-return">
                              {" "}
                              <span className="fw-600">₹</span>
                              <span className="fw-600 FD-amount">27.5k</span>
                            </div>
                          </div>
                        </div>
                        <div className="deposit-chart-list__item mb-md-4">
                          <span
                            className="c-dot"
                            style={{ background: "#4CE2FC" }}
                          />
                          <div className="ms-1 ms-md-2 d-amount">
                            {" "}
                            <span className="d-block f-14">SGB </span>
                            <div className="pad-return">
                              {" "}
                              <span className="fw-600">₹</span>
                              <span className="fw-600 SGB-amount">52.9k</span>
                            </div>
                          </div>
                        </div>
                        <div className="deposit-chart-list__item mb-md-4">
                          <span
                            className="c-dot"
                            style={{ background: "#FFBF00" }}
                          />
                          <div className="ms-1 ms-md-2 d-amount">
                            {" "}
                            <span className="d-block f-14">Gains </span>
                            <div className="pad-return">
                              <span>₹</span>
                              <span className="fw-600 gold-amount">88.1k</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 mb-4 pb-2">
                <img src={screen} height={"100%"} width={"115%"} />
              </div>
            </div>
            {/* Profit Calculation details starts */}
            <div className="row mb-5">
              <p
                className="mb-4"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                id="profit_calc"
                style={{ fontSize: "2rem" }}
              >
                <u>Profit calculation</u> <FiChevronDown />
                <i
                  className="fa fa-angle-down"
                  aria-hidden="true"
                  id="showmore_arrow"
                />
              </p>
              <div className="collapse" id="collapseExample">
                <div className="profit_calc_wrapper">
                  <div className="profit_calc_item">
                    <p className="first_elem">Gold growth</p>
                    <p className="second_elem">11.7% in YOY value of gold</p>
                  </div>
                  <div className="profit_calc_item">
                    <p className="first_elem">Gains yield</p>
                    <p className="second_elem ng-binding">5% p.a.</p>
                  </div>
                  <div className="profit_calc_item">
                    <p className="first_elem">GST charged on digital gold</p>
                    <p className="second_elem">3% p.a.</p>
                  </div>
                  <div className="profit_calc_item">
                    <p className="first_elem">FD rates from ICICI Bank</p>
                    <p className="second_elem">4.75% p.a.</p>
                  </div>
                  <div className="profit_calc_item">
                    <p className="first_elem">SGB value calculated from</p>
                    <p className="second_elem">IBJA price</p>
                  </div>
                  <div className="profit_calc_item">
                    <p className="first_elem">Interest on SGB</p>
                    <p className="second_elem">2.5% p.a.</p>
                  </div>
                </div>
                <p className="as_of">As of 1 Jan, 2023</p>
              </div>
            </div>
            {/* Profit Calculation details ends */}
          </div>
        </section>

        <section className="sec-pd ov-x-hidden">
          <div className="container1">
            <h2 className="fw-400 f-5rem frst-pd">How It Works</h2>
            <div className="row">
              <div className="col-lg-3 col-12 text-center gain-content-wrapper">
                <img
                  className="img-fluid img-height"
                  src="https://www.safegold.com/iog-landing-page/img/opt-icon.svg"
                />
                <p className="f-20 f-2rem fw-400 f-sarabun gain-content-wrapper__item align-center gain-pad-left">
                  Lease your gold to{" "}
                  <span className="fw-600">
                    {" "}
                    screened and verified jewellers
                  </span>{" "}
                  via MkGold
                </p>
              </div>
              <div className="col-lg-3 col-12 text-center gain-content-wrapper2">
                <img
                  className="img-fluid img-height"
                  src="https://www.safegold.com/iog-landing-page/img/gain-icon2.svg"
                />
                <p className="f-20 f-2rem fw-400 f-sarabun gain-content-wrapper__item align-center">
                  <span className="fw-600">Yield accrues every month</span>, as
                  the jeweller starts paying back the lease
                </p>
              </div>
              <div className="col-lg-3 col-12 text-center gain-content-wrapper">
                <img
                  className="img-fluid img-height"
                  src="https://www.safegold.com/iog-landing-page/img/gain-icon3.svg"
                />
                <p className="f-20 fw-400 f-2rem f-sarabun gain-content-wrapper__item align-center gain-pad-left">
                  Your leased gold is returned in full at the{" "}
                  <span className="fw-600">end of the sixth month </span>
                </p>
              </div>
              <div className="col-lg-3 col-12 text-center gain-content-wrapper2 mb-0">
                <img
                  className="img-fluid img-height"
                  src="https://www.safegold.com/iog-landing-page/img/gain-icon4.svg"
                />
                <p className="f-20 fw-400 f-2rem f-sarabun gain-content-wrapper__item align-center">
                  All yield is{" "}
                  <span className="fw-600">
                    credited to your MkGold account{" "}
                  </span>
                  <span>in the form of gold</span>
                </p>
              </div>
              <div className="text-center fourth-pd m-none how-it-works-link">
                <a
                  className="f-20 fw-600 f-sarabun c-F00 learn-more-link"
                  href="https://safegoldstatic.s3.ap-south-1.amazonaws.com/Gains-by-SafeGold-Product-Background.pdf"
                  ng-click="moengageLearnMoreEvent()"
                  target="_blank"
                >
                  Learn more &nbsp;
                  <img
                    className="img-fluid"
                    src="https://www.safegold.com/iog-landing-page/img/Vector.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-pd sec-form ov-hidden investment-container bg-F7F">
          <div className="container1">
            <div className="row">
              <div className="col-lg-3 col-12 investment-content-wrapper">
                <div className="investmentImg-wrapper">
                  <img
                    className="img-investment h-120"
                    src="https://www.safegold.com/iog-landing-page/img/invest-icon4.svg"
                  />
                </div>
                <p className="f-20 f-2rem fw-500 f-sarabun investment-text-wrapper text-center c-41F">
                  Backed by{" "}
                  <span className="c-F78">
                    bank
                    <span className="d-block-investment f-2rem d-block c-F78">
                      {" "}
                      guarantees
                    </span>
                  </span>
                </p>
              </div>
              <div className="col-lg-3 col-12 investment-content-wrapper">
                <div className="investmentImg-wrapper">
                  <img
                    className="img-investment h-120"
                    src="https://www.safegold.com/iog-landing-page/img/invest-icon1.svg"
                  />
                </div>
                <p className="f-20 f-2rem fw-500 f-sarabun investment-text-wrapper text-center">
                  Start leasing with{" "}
                  <span className="c-F78">
                    just
                    <span className="c-F78 f-2rem d-block-investment d-block">
                      {" "}
                      0.1 gm<span className="c-41F"> of gold</span>
                    </span>
                  </span>
                </p>
              </div>
              <div className="col-lg-3 col-12 investment-content-wrapper">
                <div className="investmentImg-wrapper">
                  <img
                    className="img-investment h-120"
                    src="https://www.safegold.com/iog-landing-page/img/invest-icon2.svg"
                  />
                </div>
                <p className="f-20 fw-500 f-2rem f-sarabun investment-text-wrapper text-center">
                  Maturity period of just
                  <span className="d-block-investment f-2rem d-block c-F78">
                    {" "}
                    6 months
                  </span>
                </p>
              </div>
              <div className="col-lg-3 col-12 investment-content-wrapper">
                <div className="investmentImg-wrapper">
                  <img
                    className="img-investment h-120"
                    src="https://www.safegold.com/iog-landing-page/img/invest-icon3.svg"
                  />
                </div>
                <p className="f-20 fw-500 f-2rem f-sarabun investment-text-wrapper text-center">
                  Earn{" "}
                  <span className="c-F78 f-2rem d-block-investment ng-binding">
                    {" "}
                    upto 5% p.a.{" "}
                  </span>
                  in gold
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-pd ov-x-hidden">
          <div className="container1">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h2 className="fw-400 f-5rem" style={{ lineHeight: "1.2" }}>
                  Give your portfolio
                  <span className="c-F00 d-block fw-400">a sidekick</span>
                </h2>
                <p
                  className="fw-400 f-24 f-sarabun content-pad"
                  style={{ fontSize: "2.2rem" }}
                >
                  Thanks to its legendary stability, gold protects your wealth
                  during all market conditions and helps your portfolio retain
                  its value.
                </p>
              </div>
              <div className="col-lg-6 col-12 text-center">
                {" "}
                <img
                  className="img-fluid"
                  src="https://www.safegold.com/iog-landing-page/img/portfolio-icon.svg"
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="sec-pd c-E37"
          id="leasing-block"
          style={{ paddingTop: "80px", paddingBottom: "80px" }}
        >
          <div>
            {" "}
            <img
              className="optstep-icon img-width"
              src="https://www.safegold.com/iog-landing-page/img/optStep-icon.svg"
            />
          </div>
          <div className="container1">
            <h2 className="fw-400 f-5rem frst-pd">
              How to opt into <span className="c-F00">Gains</span>
            </h2>
            <div className="row text-center">
              <div className="col-lg-4 col-4 col-md-4">
                <img
                  className="img-fluid img-height-opt"
                  src="https://www.safegold.com/iog-landing-page/img/opt-icon1.svg"
                />
                <div className="opt-stepline">
                  <div className="opt-step-wrapper">
                    {" "}
                    <span className="opt-step-wrapper__step-number">1</span>
                  </div>
                </div>
                <p className="f-20 fw-400 f-sarabun opt-content-width text-center">
                  Verify your PAN
                </p>
              </div>
              <div className="col-lg-4 col-4 col-md-4">
                <img
                  className="img-fluid img-height-opt"
                  src="https://www.safegold.com/iog-landing-page/img/opt-icon2.svg"
                />
                <div className="opt-stepline">
                  <div className="opt-step-wrapper">
                    {" "}
                    <span className="opt-step-wrapper__step-number">2</span>
                  </div>
                </div>
                <p className="f-20 fw-400 f-sarabun opt-content-width text-center">
                  Start with just 0.1 grams
                </p>
              </div>
              <div className="col-lg-4 col-4 col-md-4">
                <img
                  className="img-fluid img-height-opt"
                  src="https://www.safegold.com/iog-landing-page/img/opt-icon3.svg"
                />
                <div className="opt-stepline">
                  <div className="opt-step-wrapper">
                    {" "}
                    <span className="opt-step-wrapper__step-number">3</span>
                  </div>
                </div>
                <p className="f-20 fw-400 f-sarabun opt-content-width text-center">
                  Buy the gold and confirm your lease
                </p>
              </div>
            </div>
            <div
              className="web-btn-wrapper text-center leasing-button"
              style={{ color: "#f6f6f6" }}
            >
              <a href className="web-btn" ng-click="OpenLoginModal()">
                View Leases
              </a>
            </div>
          </div>
        </section>

        <section
          className="sec-pd ov-x-hidden"
          style={{ paddingBottom: "50px" }}
        >
          <div className="container1">
            <h2 className="fw-400 f-5rem third-pd">
              Cashing out is just <span className="c-F00">one option</span>
            </h2>
            <p className="fw-400 f-2rem f-sarabun cashing-subheading">
              Here’s everything else you can do with your digital gold on MkGold
            </p>
            <div className="row">
              
              <div className="col-lg-3 col-6 text-center img-pd">
                {" "}
                <img
                  className="img-fluid third-pd justify-img-center cashin-icon-height"
                  src="https://www.safegold.com/iog-landing-page/img/Convert-jewellery.svg"
                />
                <div>
                  <a
                    style={{ color: "#414a4f" }}
                    className="d-flex justify-content-center a-hover"
                    href="/jewellery-exchange"
                    ng-click="moengageJewelleryEvent()"
                    target="_blank"
                  >
                    <p className="f-24 fw-600 f-sarabun w-max-content">
                      Convert
                      <span className="d-block mob-w-100">
                        to jewellery&nbsp;{" "}
                      </span>
                    </p>
                    <img
                      className="img-fluid justify-icon-center"
                      src="https://www.safegold.com/iog-landing-page/img/Vector.svg"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 text-center img-pd">
                {" "}
                <img
                  className="img-fluid third-pd justify-img-center cashin-icon-height"
                  src="https://www.safegold.com/iog-landing-page/img/Buy-coins.svg"
                />
                <div>
                  <a
                    style={{ color: "#414a4f" }}
                    className="d-flex justify-content-center a-hover"
                    href="/gold-delivery"
                    ng-click="moengageCoinsEvent()"
                    target="_blank"
                  >
                    <p className="f-24 fw-600 f-sarabun w-max-content">
                      Buy coins{" "}
                      <span className="d-block bars-pad">and bars&nbsp; </span>
                    </p>
                    <img
                      className="img-fluid justify-icon-center"
                      src="https://www.safegold.com/iog-landing-page/img/Vector.svg"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 text-center img-pd">
                {" "}
                <img
                  className="img-fluid third-pd cashin-icon-height"
                  src="https://www.safegold.com/iog-landing-page/img/Reinvest-gold.svg"
                />
                <p className="f-24 fw-600 f-sarabun">
                  Opt back into Gains for even more gains
                </p>
              </div>
            </div>
            <div className="text-center fourth-pd m-none">
              <a
                className="f-20 fw-600 f-sarabun c-F00 learn-more-link"
                href="https://bit.ly/3INa8Ht"
                ng-click="moengageLearnMoreDigitalGoldEvent()"
                target="_blank"
              >
                Learn more about digital gold &nbsp;
                <img
                  className="img-fluid"
                  src="https://www.safegold.com/iog-landing-page/img/Vector.svg"
                />
              </a>
            </div>
          </div>
        </section>
      </main>
      <div style={{ backgroundColor: "#f7f7f7", paddingTop: "1px" }}>
        <Trustedpartner />
      </div>

      <Faqquestion />
      <Footer />
    </>
  );
};

export default Leasepage;
