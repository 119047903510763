import React from "react";
import "../assets/style/style.css";

const Insights = () => {
  return (
    <>
      <section className="insights-section">
        <h1 className="insights-animation-1">Our insights</h1>
        <div className="news-container row">
          {/* Card 1 */}
          <div className="news-item-outer col col-12 col-lg-3 insights-animation-2">
            <div className="news-item">
              <div className="image-container">
                <img
                  data-src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/news-1-1.png"
                  className=" lazyloaded"
                  alt
                  src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/news-1-1.png"
                />
              </div>
              <div className="content">
                <h4
                  className="news-content-heading"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                  }}
                >
                  Digital Gold Explained- All your need to know!
                </h4>
                <p
                  className="news-content-body"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                  }}
                >
                  When technology has changed how we shop, why should gold be
                  left behind?
                </p>
                <div>
                  <a
                    href="https://blog.safegold.com/digital-gold-explained/"
                    className="nav-links link-button"
                    target="_blank"
                  >
                    Continue reading
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Card 2 */}
          <div className="news-item-outer col col-12 col-lg-3 insights-animation-2">
            <div className="news-item">
              <div className="image-container">
                <img
                  data-src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/news-2-1.png"
                  className=" lazyloaded"
                  alt
                  src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/news-2-1.png"
                />
              </div>
              <div className="content">
                <h4
                  className="news-content-heading"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                  }}
                >
                  Everything You Need To Know About Gold Saving Products
                </h4>
                <p
                  className="news-content-body"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                  }}
                >
                  Gold is not just used to make jewellery, it has changed the
                  investing game too...
                </p>
                <div>
                  <a
                    href="https://blog.safegold.com/everything-you-need-to-know-about-gold-saving-products/"
                    className="nav-links link-button"
                    target="_blank"
                  >
                    Continue reading
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Card 3 */}
          <div className="news-item-outer col col-12 col-lg-3 insights-animation-2">
            <div className="news-item">
              <div className="image-container">
                <img
                  data-src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/news-3-1.png"
                  className=" lazyloaded"
                  alt
                  src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/news-3-1.png"
                />
              </div>
              <div className="content">
                <h4
                  className="news-content-heading"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                  }}
                >
                  Busting Some Myths About Gold Investments
                </h4>
                <p
                  className="news-content-body"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                  }}
                >
                  You don’t need to be super wealthy to buy gold, you can buy
                  for ₹10 too!
                </p>
                <div>
                  <a
                    href="https://blog.safegold.com/busting-myths-about-gold-investments/"
                    className="nav-links link-button"
                    target="_blank"
                  >
                    Continue reading
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Card 4 */}
          <div className="news-item-outer col col-12 col-lg-3 insights-animation-2">
            <div className="news-item">
              <div className="image-container">
                <img
                  data-src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/news-4-1.png"
                  className=" lazyloaded"
                  alt
                  src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/news-4-1.png"
                />
              </div>
              <div className="content">
                <h4
                  className="news-content-heading"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                  }}
                >
                  Tracking Gold – 2020 and <br />
                  Beyond
                </h4>
                <p
                  className="news-content-body"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                  }}
                >
                  Gold has had a remarkable performance, significantly
                  outperforming other...
                </p>
                <div>
                  <a
                    href="https://blog.safegold.com/tracking-gold-2020-and-beyond/"
                    className="nav-links link-button"
                    target="_blank"
                  >
                    Continue reading
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Insights;
