import React, { useEffect } from "react";
import Homepage from "../components/Homepage";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])
    return <>
        <Homepage/>
    </>
}

export default Home;