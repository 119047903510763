import React from "react";
import "../assets/style/style.css";
import Header from "./Header";
import Frontpage from "./Frontpage";
import Trustedpartner from "./Trustedpartner";
import Gotodigital from "./Gotodigital";
import Betterthanshaving from "./Betterthanshaving";
import Redeemgold from "./Redeemgold";
import News from "./News";
import Insights from "./Insights";
import Ourteammedia from "./Ourteammedia";
import Investing from "./Investing";
import Footer from "./Footer";

const Homepage = () => {
  return (
    <>
      <div className="homepage">
        <Header />
        <Frontpage />
        <Trustedpartner />
        <Gotodigital />
        <Betterthanshaving />
        <Redeemgold />
        <News />
        <Insights />
        <Ourteammedia />
        <Investing />
        <Footer />
      </div>
    </>
  );
};

export default Homepage;
