import React from "react";
import "../assets/style/style.css";

const Frontpage = () => {
  return (
    <>
      <section className="banner-section row">
        {/* <div class="sparkle1 sparkle">
				<img src="/assets/sg-ui-v2/new-landingpage/images/icons/sparkle.png" alt="Sparkle">
			</div>
			<div class="sparkle2 sparkle">
				<img src="/assets/sg-ui-v2/new-landingpage/images/icons/sparkle.png" alt="Sparkle">
			</div>
			<div class="sparkle3 sparkle">
				<img src="/assets/sg-ui-v2/new-landingpage/images/icons/sparkle.png" alt="Sparkle">
			</div> */}
        <div className="col col-12 col-lg-7 m-t">
          {/* <div class="banner-content banner-content-mobile"> */}
          {/* <h1 class="header-animation-1">
						The easiest way to buy 24K pure gold online mobile
					</h1>
					<p class="header-animation-2">
						Start investing in gold starting as low as ₹10
					</p> */}
          {/* <div class="banner-content-mobile" id="nudgeBar" style="display: none">
						<div class="nudge-wrapper">
							<img src="/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/sg-nudge-logo.svg" alt="Safegold" />
							<h6>Quick access to your account profile anytime</h6>
							<button type="button" class="nudge-btn" id="nudge-button">Add to Home Screen</button>
							<button type="button" class="close-btn" ng-click="closeNudge()">
								<img src="https://safegoldstatic.s3.ap-south-1.amazonaws.com/images/buy-coin-page/close-button-green.svg" alt="Close Button"  />
							</button>
						</div>
					</div> */}
          {/* <div class="banner-content-mobile">
						<div class="user-trend-container">
							<h1 class="header-animation-1">Buy 24K gold in 24 seconds.</h1>
							<h4>{{ userTrends }}</h4>
							<h6>Transactions in the last 1 hour</h6>
						</div>
					</div>
				</div> */}
          <div className="banner-content banner-content-mobile mb-3">
            <section className="row home-banner-slider-section homepage-section-6 mt-0">
              <ul
                className="owl-carousel owl-custom-navigation owl-one customers-animation-2 owl-loaded owl-drag"
                id="banner_slider"
              >
                {/* <li class="item">
								<div class="banner-content-mobile mobile-banner-container">
									<div class="user-trend-container banner-for-mobile">
										<h1 class="header-animation-1">99.99% purity, <br> 100% prosperity</h1>
										<h4><span>{{ userTrends }}</span></h4>
										<h6>Transactions in the last 1 hour</h6>
									</div>
								</div>
							</li> */}
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                      transform: "translate3d(0px, 0px, 0px)",
                      transition: "all 0s ease 0s",
                    }}
                  >
                    <div className="owl-item">
                      <li className="item">
                        <div className="banner-content-mobile">
                          <div className="user-trend-container">
                            <h1 className="header-animation-1">
                              Buy 24K gold in 24 seconds.
                            </h1>
                            <h4 className="ng-binding">14,900</h4>
                            <h6>Transactions in the last 1 hour</h6>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="owl-item">
                      <li className="item">
                        <div className="banner-content-mobile slider-item analysts_banner">
                          <div className="user-trend-container">
                            <div className="banner-content-mobile-left">
                              <h4 className="header-animation-1">
                                Analysts forecast{" "}
                                <span>2X Rise in gold price</span> for 2023
                                amidst recession fear!
                              </h4>
                              <a
                                href="https://bit.ly/3Wv3qf2"
                                target="_blank"
                                className="learn_more_btn"
                              >
                                Learn More{" "}
                                <img
                                  src="/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/angle-right-white.svg"
                                  className="lazyload right_angle"
                                  alt
                                />
                              </a>
                            </div>
                            <div className="banner-content-mobile-right" />
                          </div>
                          <img
                            src="/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/Homepage_banner2_bg_img.svg"
                            className="lazyload banner_bg_img"
                            alt
                          />
                          <img
                            src="/assets/sg-ui-v2/new-landingpage/images/pages/home-page-new/Homepage_banner2_img.svg"
                            className="lazyload banner_front_img"
                            alt
                          />
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="owl-nav disabled">
                  <button
                    type="button"
                    role="presentation"
                    className="owl-prev"
                  >
                    <img
                      className="carousel-control-left carousel-control"
                      src="/assets/sg-ui-v2/new-landingpage/images/icons/green-left-small.svg"
                      alt
                    />
                  </button>
                  <button
                    type="button"
                    role="presentation"
                    className="owl-next"
                  >
                    <img
                      className="carousel-control-right carousel-control"
                      src="/assets/sg-ui-v2/new-landingpage/images/icons/green-right-small.svg"
                      alt
                    />
                  </button>
                </div>
                <div className="owl-dots disabled">
                  <button role="button" className="owl-dot active">
                    <span />
                  </button>
                </div>
              </ul>
            </section>
          </div>
          <div className="banner-content banner-content-desktop m-t">
            <div div="header-title-container">
              <h1 className="header-animation-1">
                Buy 24K Gold in 24 Seconds.
              </h1>
              <div className="header-animation-2">
                <p>
                  <span className="bold_text ng-binding">14,900</span>{" "}
                  transactions in the last 1 hour.
                </p>
                <p>
                  Start investing in gold starting as low as{" "}
                  <span className="bold_text">₹10</span>
                </p>
                {/* <span class="buy-now-button" data-toggle="modal" data-target="#loginModal" data-backdrop="static" data-keyboard="false">
								Buy Now
								<img src="/assets/sg-ui-v2/new-landingpage/images/icons/white-arrow.svg" class="lazyload" alt="" />
							</span> */}
              </div>
            </div>
            <div className="banner-storage-partner header-animation-3">
              <h1>Secured by</h1>
              <div className="secured-by-images">
                <div className="image-container">
                  <img
                    src="https://d2swkjwe31rb4i.cloudfront.net/images/logo/brinks-logo.svg"
                    className=" ls-is-cached lazyloaded"
                    alt
                  />
                </div>
                <div className="image-container">
                  <img
                    src="https://www.safegold.com/assets/sg-ui-v2/images/trustee-logos/vistra.svg"
                    alt="Vistra"
                    className="vistra"
                  />
                </div>
              </div>
              <p className="corresponding-text">
                Gold corresponding to every purchase you make is stored in
                world-class vaults.
              </p>
            </div>
          </div>
        </div>
        <div className="col col-12 col-lg-5 column-2 header-animation-4">
          <div className="buy-gold-wrapper">
            <div className="buy-gold">
              <ul
                className="option-tabs nav nav-tabs border-0"
                id="myTab"
                role="tablist"
              >
                <li
                  className="buy nav-item active"
                  id="buy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#buy"
                  type="button"
                >
                  <p>Buy</p>
                </li>
                <li
                  className="sell nav-item"
                  id="sell-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#sell"
                  type="button"
                  
                >
                  <p>Sell</p>
                </li>
                <li
                  className="sip nav-item"
                  id="jew"
                  data-bs-toggle="tab"
                  data-bs-target="#jewellery"
                  type="button"
                >
                  <p>Jewellery</p>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="buy-gold-container active" id="buy">
                  <div className="gold-info">
                    <ul>
                      <li className="live-price">
                        <div className="image-container">
                          <img
                            src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/buy-gold.svg"
                            className=" ls-is-cached lazyloaded"
                            alt
                          />
                        </div>
                        <div>
                          <p>Live Buy Price</p>
                          <p
                            className="bold live-price-converions ng-binding"
                            id="live-price"
                            style={{ display: "block" }}
                          >
                            ₹ 6133.26 /gm <span className="p-gst">+ GST</span>
                          </p>
                        </div>
                      </li>
                      <li>
                        <p>Purity</p>
                        <p className="bold" style={{ color: "#DFAF10" }}>
                          24K 99.99%
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="gold-calculator">
                    <form
                      name="buyGoldForm"
                      noValidate
                      className="ng-pristine ng-valid-maxlength ng-valid ng-valid-required"
                    >
                      <div className="form-group timer-div">
                        <span
                          className="time"
                          style={{ float: "right", opacity: "0.7" }}
                        >
                          3:16
                        </span>
                      </div>
                      <div className="form-group gold-radio-container">
                        <div className="form-check-inline gold-radio">
                          <input
                            type="radio"
                            className="form-check-input ng-untouched ng-valid ng-not-empty ng-dirty ng-valid-parse ng-pristine"
                            id="rs-radio"
                            ng-model="buyIn"
                            name="goldRadio"
                            ng-value="'rupee'"
                            defaultValue="rupee"
                            ng-click="resetValue()"
                          />
                          <label
                            htmlFor="rs-radio"
                            className="radio_lb gold-radio"
                          >
                            <span />
                            Buy in rupees
                          </label>
                        </div>
                        <div className="form-check-inline gold-radio">
                          <input
                            type="radio"
                            className="form-check-input ng-untouched ng-valid ng-not-empty ng-dirty ng-pristine"
                            id="gm-radio"
                            ng-model="buyIn"
                            name="goldRadio"
                            ng-value="'grams'"
                            defaultValue="grams"
                            ng-click="resetValue()"
                          />
                          <label
                            htmlFor="gm-radio"
                            className="radio_lb gold-radio"
                          >
                            <span />
                            Buy in grams
                          </label>
                        </div>
                      </div>

                      <div
                        className="measurement_rupee ng-scope"
                        data-domain="₹"
                        ng-if="buyIn=='rupee'"
                      >
                        <div className="input-group">
                          <input
                            type="tel"
                            className="form-control input-lg suffix-input ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                            id="buyByrupee"
                            name="amountRs"
                            ng-model="amountRs"
                            ng-change="setRs(amountRs)"
                            placeholder={2100}
                            numbers-only
                            inputMode="numeric"
                            required
                            autoComplete="off"
                            maxLength={9}
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text ng-binding ng-scope"
                              ng-if="amountGm"
                            >
                              {" "}
                              = 0.3324 gm
                            </span>
                          </div>
                        </div>
                      </div>

                      <span className="maxErrMsg ng-binding" />
                      <button
                        ng-click="postBuyData('buy-gold'); isSignUpModal = true;"
                        ng-class='{"no-arrow":isSignUpModal==true}'
                        className="close-float-bar"
                      >
                        <span ng-if="!isSignUpModal" className="ng-scope">
                          Buy Gold
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
                <div
                  className="sell-gold-container"
                  id="sell"
                >
                  <div className="image-container">
                    <img
                      src="https://d2swkjwe31rb4i.cloudfront.net/images/pages/home-page-new/sell-ingot.svg"
                      alt
                      className="lazyload"
                    />
                  </div>
                  <h2 className="section-title">
                    Create an account to buy and sell 24K gold!
                  </h2>
                  <button
                    className="primary-button"
                    ng-click="postBuyData('sell-gold'); isSignUpModal = true;"
                  >
                    <span ng-if="!isSignUpModal" className="ng-scope">
                      Sell Now
                    </span>
                  </button>
                </div>
                <div
                  className="sip-gold-container"
                  id="jewellery"
                  
                >
                  <div className="image-container">
                    <img
                      src="https://safegoldstatic.s3.ap-south-1.amazonaws.com/images/home/bangles.svg"
                      alt="Jewellery"
                      className="lazyload"
                    />
                  </div>
                  <h2 className="section-title">
                    Create an account and buy digital gold for jewellery
                  </h2>
                  <button
                    className="primary-button"
                    ng-click="postBuyData('jewellery-exchange'); isSignUpModal = true;"
                  >
                    <span ng-if="!isSignUpModal" className="ng-scope">
                      Sign up
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Frontpage;
