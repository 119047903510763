import React from "react";
import "../assets/style/style.css";
import { Link } from "react-router-dom";
import Safegoldg from "./Safegoldg";

const Productlist = () => {
  return (
    <>
      <section>
        <div className="delivery-section-1">
          <div className="custom-container">
            <div
              className="product-container ng-scope"
              ng-if="windowWidth > 768"
            >
              <div
                className="product-wrapper text-center ng-scope"
                ng-repeat="product in products track by $index"
                ng-if="$index<3"
              >
                <div className="c-b">
                  <div>
                    <div className="promo-offer" ng-show="product.discount > 0">
                      <img
                        src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/promo-offer.svg"
                        alt="Promo-Offer"
                      />
                      <span>Akshaya Tritiya Offers</span>
                    </div>
                    <div
                      className="coin-img"
                      ng-click="openProductDetailsPage(product)"
                    >
                      <img
                        ng-src="https://safegoldstatic.s3.ap-south-1.amazonaws.com/images/inventory-images/05gm_coin_9999_front.jpg"
                        className="product_img"
                        alt="05gm_coin_9999_front.jpg"
                        ng-class="{'o-f-s': product.in_stock == 'N'}"
                        src="https://safegoldstatic.s3.ap-south-1.amazonaws.com/images/inventory-images/05gm_coin_9999_front.jpg"
                      />
                    </div>
                    <p className="product-name ng-binding">
                      0.5 Gram MkGold Coin (99.99%)
                    </p>
                    <p className="product-price ng-binding"> ₹ 3,497.84</p>

                    <p
                      className="product-price offer-price ng-scope"
                      ng-if="product.discount > 0"
                    >
                      <span className="ng-binding">₹ 3,797.84</span>
                      <em
                        ng-show="product.discount_type == 1"
                        className="ng-binding ng-hide"
                        style={{ display: "none" }}
                      >
                        300% OFF
                      </em>
                      <em
                        ng-show="product.discount_type == 2"
                        className="ng-binding"
                      >
                        Save ₹300
                      </em>
                    </p>
                  </div>

                  <div ng-if="product.in_stock == 'Y'" className="ng-scope">
                    <button
                      className="btn btn-primary1 e-pd"
                      ng-disabled="isLoading"
                      ng-click="openProductDetailsPage(product)"
                    >
                      <span ng-if="!product.isClicked" className="ng-scope">
                        <Link
                          to={"/gold-product-details"}
                          style={{ textDecoration: "none" }}
                        >
                          View Details
                        </Link>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="product-wrapper text-center ng-scope"
                ng-repeat="product in products track by $index"
                ng-if="$index<3"
              >
                <div className="c-b">
                  <div>
                    <div className="promo-offer" ng-show="product.discount > 0">
                      <img
                        src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/promo-offer.svg"
                        alt="Promo-Offer"
                      />
                      <span>Akshaya Tritiya Offers</span>
                    </div>
                    <div
                      className="coin-img"
                      ng-click="openProductDetailsPage(product)"
                    >
                      <img
                        ng-src="https://safegoldstatic.s3.ap-south-1.amazonaws.com/images/inventory-images/05gm_ganesh_coin_9999_front.jpg"
                        className="product_img"
                        alt="05gm_ganesh_coin_9999_front.jpg"
                        ng-class="{'o-f-s': product.in_stock == 'N'}"
                        src="https://safegoldstatic.s3.ap-south-1.amazonaws.com/images/inventory-images/05gm_ganesh_coin_9999_front.jpg"
                      />
                    </div>
                    <p className="product-name ng-binding">
                      0.5 gram Ganesh Coin (99.99%)
                    </p>
                    <p className="product-price ng-binding"> ₹ 3,497.84</p>

                    <p
                      className="product-price offer-price ng-scope"
                      ng-if="product.discount > 0"
                    >
                      <span className="ng-binding">₹ 3,797.84</span>
                      <em
                        ng-show="product.discount_type == 1"
                        className="ng-binding ng-hide"
                        style={{ display: "none" }}
                      >
                        300% OFF
                      </em>
                      <em
                        ng-show="product.discount_type == 2"
                        className="ng-binding"
                      >
                        Save ₹300
                      </em>
                    </p>
                  </div>

                  <div ng-if="product.in_stock == 'Y'" className="ng-scope">
                    <button
                      className="btn btn-primary1 e-pd"
                      ng-disabled="isLoading"
                      ng-click="openProductDetailsPage(product)"
                    >
                      <span ng-if="!product.isClicked" className="ng-scope">
                        View Details
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="product-wrapper text-center ng-scope"
                ng-repeat="product in products track by $index"
                ng-if="$index<3"
              >
                <div className="c-b">
                  <div>
                    <div className="promo-offer" ng-show="product.discount > 0">
                      <img
                        src="https://www.safegold.com/assets/sg-ui-v2/sg-homepage/assets/images/icons/promo-offer.svg"
                        alt="Promo-Offer"
                      />
                      <span>Akshaya Tritiya Offers</span>
                    </div>
                    <div
                      className="coin-img"
                      ng-click="openProductDetailsPage(product)"
                    >
                      <img
                        ng-src="https://safegoldstatic.s3.ap-south-1.amazonaws.com/images/inventory-images/05gm_lakshmi_coin_9999_front.jpg"
                        className="product_img"
                        alt="05gm_lakshmi_coin_9999_front.jpg"
                        ng-class="{'o-f-s': product.in_stock == 'N'}"
                        src="https://safegoldstatic.s3.ap-south-1.amazonaws.com/images/inventory-images/05gm_lakshmi_coin_9999_front.jpg"
                      />
                    </div>
                    <p className="product-name ng-binding">
                      0.5 gram Laxmi Coin (99.99%)
                    </p>
                    <p className="product-price ng-binding"> ₹ 3,497.84</p>

                    <p
                      className="product-price offer-price ng-scope"
                      ng-if="product.discount > 0"
                    >
                      <span className="ng-binding">₹ 3,797.84</span>
                      <em
                        ng-show="product.discount_type == 1"
                        className="ng-binding ng-hide"
                        style={{ display: "none" }}
                      >
                        300% OFF
                      </em>
                      <em
                        ng-show="product.discount_type == 2"
                        className="ng-binding"
                      >
                        Save ₹300
                      </em>
                    </p>
                  </div>

                  <div ng-if="product.in_stock == 'Y'" className="ng-scope">
                    <button
                      className="btn btn-primary1 e-pd"
                      ng-disabled="isLoading"
                      ng-click="openProductDetailsPage(product)"
                    >
                      <span ng-if="!product.isClicked" className="ng-scope">
                        View Details
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <Safegoldg />
        </div>
      </section>
    </>
  );
};

export default Productlist;
